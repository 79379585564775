<template>
  <div class="logo" :class="{ 'logo--white': white, 'logo--full': full }">
    <svg
      id="magnitude-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="454.443"
      height="34.463"
      viewBox="0 0 454.443 94.463"
    >
      <g id="Group_1" data-name="Group 1" transform="translate(178.199 18.712)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M201.59,52.778H195.2V21.1h7.212l9.22,19.262,9.4-19.262h7.212V52.778h-6.39V33.059L211.632,53.691,201.59,33.059Z"
          transform="translate(-195.2 -20.55)"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M247.781,47.309l-1.826,5.477H239.2L249.881,21.2h7.942l10.59,31.586h-7.029l-1.734-5.477Zm5.934-18.349-4.382,13.055h8.581Z"
          transform="translate(-199.032 -20.558)"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M308.143,35.015v1.735A16.036,16.036,0,0,1,276.1,38.119v-1.1c0-9.4,6.847-16.524,15.976-16.524a15.7,15.7,0,0,1,12.6,6.025l-4.838,3.834a9.306,9.306,0,0,0-7.668-3.925c-5.477,0-9.586,4.565-9.586,10.5a10.022,10.022,0,0,0,9.586,10.5h.182a9.229,9.229,0,0,0,6.938-3.2,9.635,9.635,0,0,0,1.917-4.017h-8.673V34.924Z"
          transform="translate(-202.246 -20.497)"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M326.79,52.778H320.4V21.1h6.3l12.963,19.81V21.1h6.39V52.778h-6.208L326.79,32.785Z"
          transform="translate(-206.104 -20.55)"
        />
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="6.39"
          height="31.586"
          transform="translate(150.173 0.55)"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M388.185,52.778h-6.39V26.486H374.4V21.1h21.179v5.386h-7.395Z"
          transform="translate(-210.808 -20.55)"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M411.473,39.723c0,2.921.365,4.565,1.369,5.751a5.685,5.685,0,0,0,8.125.456,5.5,5.5,0,0,0,.73-.73c.913-1.278,1.187-2.647,1.187-5.569V21.1h6.39V39.358c0,4.656-1,7.851-3.1,10.225a11.992,11.992,0,0,1-9.038,3.743,12.476,12.476,0,0,1-9.4-3.925c-2.008-2.282-2.83-5.112-2.83-10.042V21.1h6.39Z"
          transform="translate(-213.464 -20.55)"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M442.391,21.1h8.764c5.021,0,8.4,1,11.137,3.469a15.656,15.656,0,0,1,4.93,11.959,16.852,16.852,0,0,1-4.93,12.507,15.48,15.48,0,0,1-10.772,3.652H442.3Zm6.39,26.292h1.187a9.972,9.972,0,0,0,7.3-2.191,10.66,10.66,0,0,0,3.469-8.4c0-6.3-3.743-10.407-9.677-10.407H448.69Z"
          transform="translate(-216.722 -20.55)"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M485.29,34.246h10.864v5.295H485.29v7.942h10.864v5.295H478.9V21.1h17.254v5.386H485.29Z"
          transform="translate(-219.909 -20.55)"
        />
      </g>
      <path
        id="Path_9"
        data-name="Path 9"
        d="M68.924,94.12,50.575,1.917a93.2,93.2,0,0,1,37.612,0L69.837,94.12A.475.475,0,0,1,68.924,94.12ZM47.38,41.811,44,25.014A69.242,69.242,0,0,0,0,89.556v.091H16.8v-.091A52.5,52.5,0,0,1,47.38,41.811Zm47.38-16.889-3.378,16.8a52.5,52.5,0,0,1,30.582,47.745v.091h16.8v-.091A69.427,69.427,0,0,0,94.759,24.922Z"
        fill="#cb8161"
      />
      <g id="Group_2" data-name="Group 2" transform="translate(178.199 69.837)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M198.486,96.262H195.2V77h12.6v3.013h-9.312v4.93h8.855v3.013h-8.855Z"
          transform="translate(-195.2 -76.544)"
          fill="#cb8161"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M217.686,77V96.354H214.4V77Z"
          transform="translate(-196.872 -76.544)"
          fill="#cb8161"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M228.286,82.386V96.262H225V77h3.286l9.494,13.967V77h3.2V96.354H237.6Z"
          transform="translate(-197.795 -76.544)"
          fill="#cb8161"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M250.569,96.254H247.1L254.586,76.9h3.469l7.486,19.354h-3.56l-1.369-3.743h-8.673Zm5.751-15.245h0l-3.2,8.49h6.39Z"
          transform="translate(-199.72 -76.535)"
          fill="#cb8161"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M275.1,82.386V96.262h-3.2V77h3.286l9.4,13.967V77h3.2V96.354h-3.378Z"
          transform="translate(-201.88 -76.544)"
          fill="#cb8161"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M295.3,86.542a9.847,9.847,0,0,1,2.739-7.212,9.425,9.425,0,0,1,6.938-2.83,9.315,9.315,0,0,1,6.117,2.008,8.749,8.749,0,0,1,3.1,5.477h-3.469a5.855,5.855,0,0,0-2.008-3.2,6.308,6.308,0,0,0-3.743-1.187,5.977,5.977,0,0,0-4.564,1.826,8.492,8.492,0,0,0,0,10.042,6.437,6.437,0,0,0,8.307.73,5.129,5.129,0,0,0,2.008-3.2H314.2a8.749,8.749,0,0,1-3.1,5.477,9.315,9.315,0,0,1-6.117,2.008,9.088,9.088,0,0,1-6.938-2.83A9.352,9.352,0,0,1,295.3,86.542Z"
          transform="translate(-203.918 -76.5)"
          fill="#cb8161"
        />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M335.3,93.25v3.013H321.7V77h13.6v3.013H324.986v4.93h9.859v3.013h-9.859v5.2H335.3Z"
          transform="translate(-206.218 -76.544)"
          fill="#cb8161"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    white: {
      default: false,
      type: Boolean,
    },
    teal: {
      default: false,
      type: Boolean,
    },
    blue: {
      default: false,
      type: Boolean,
    },
    full: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 115px;

  @media screen and (max-width: 350px) {
    width: 130px;
  }

  @media screen and (min-width: 480px) {
    width: 190px;
  }

  &--full {
    width: 100%;
  }

  svg {
    max-width: 100%;
  }

  &--white {
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}
</style>
