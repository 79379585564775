<template>
  <section class="quote-section">
    <canvas class="confetti" ref="confetti_canvas" />

    <slot :apply="apply" />

    <!-- Success modal-->
    <modal
      name="success"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="z-10 p-8 text-white leading-5"
      :adaptive="true"
      height="auto"
      :scrollable="true"
    >
      <div class="h-10 block w-full text-right">
        <span class="float-right flex items-center cursor-pointer" @click="$modal.hide('success')">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
          <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>
      <h2 class="text-white">All done! Your application has been submitted!</h2>
      <p>
        We'll review your application and update your dashboard. Any questions? Use the live chat or
        call us on <strong><a href="tel:0161 406 3994">0161 406 3994</a></strong>
      </p>
      <button
        @click="$router.push({ name: 'home' })"
        class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary mt-4"
      >
        My dashboard
      </button>
      <button
        @click="$modal.hide('success')"
        class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary ml-0 md:ml-1 mt-4"
      >
        Close
      </button>
    </modal>

    <!-- Error modal-->
    <modal
      name="error"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="z-10 p-8 text-white leading-5"
      :adaptive="true"
      height="auto"
      :scrollable="true"
    >
      <div class="h-10 block w-full text-right">
        <span class="float-right flex items-center cursor-pointer" @click="$modal.hide('error')">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
          <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>
      <h2 class="text-white">We're sorry...</h2>
      <p>
        It looks like something went wrong while appying. If this problem persists, please get in
        touch with us using the live chat or call us on
        <strong><a href="tel:0161 406 3994">0161 406 3994</a></strong>
      </p>
      <button
        @click="$modal.hide('error')"
        class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary mt-4"
      >
        Close
      </button>
    </modal>

    <!-- Already submitted modal-->
    <modal
      name="alreadySubmitted"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="z-10 p-8 text-white leading-5"
      :adaptive="true"
      height="auto"
      :scrollable="true"
    >
      <div class="h-10 block w-full text-right">
        <span
          class="float-right flex items-center cursor-pointer"
          @click="$modal.hide('alreadySubmitted')"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            class="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
          <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>
      <h2 class="text-white">Already submitted</h2>
      <p>
        It looks like you have already applied for finance. To find out more, please get in touch
        with us using the live chat or call us on
        <strong><a href="tel:0161 406 3994">0161 406 3994</a></strong>
      </p>
      <button
        @click="$modal.hide('alreadySubmitted')"
        class="btn-secondary dealer-bg-secondary hover:dealer-bg-primary hover:dealer-text-secondary mt-4"
      >
        Close
      </button>
    </modal>
  </section>
</template>

<script>
import confetti from 'canvas-confetti';
import Api from '@/lib/Api';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedQuoteResult: null,
      confetti: null,
    };
  },

  computed: mapState(['dashboard']),

  methods: {
    ...Api,

    async apply(quoteResult, allowMultipleSubmissions = false) {
      this.$emit('loading', true, 'Applying...');
      this.selectedQuoteResult = quoteResult;

      if (!this.dashboard.application.completed || allowMultipleSubmissions || this.allowApply()) {
        try {
          // Submit the application and quote
          const completeApplication = await this.apiCompleteApplication(
            this.dashboard.application.reference,
            quoteResult.id
          );

          if (!completeApplication) {
            throw new Error('Failed to apply for finance - server error');
          }

          // Done, refresh the application status so the dashboard updates immediately
          await this.refreshApplication();
          this.$modal.show('success');
          this.fireConfetti();
        } catch (e) {
          this.$modal.show('error');
          // TODO: Bugsnag
          console.error(e);
        }
      } else {
        this.$modal.show('alreadySubmitted');
      }

      this.$emit('loading', false);
    },

    initConfetti(ref) {
      this.confetti = confetti.create(ref, {
        resize: true,
        useWorker: true,
      });
    },

    fireConfetti() {
      this.confetti({
        particleCount: 400,
        spread: 150,
      });
    },
  },

  mounted() {
    this.initConfetti(this.$refs.confetti_canvas);
    // this.$modal.show('error')
  },
};
</script>

<style lang="scss">
canvas.confetti {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 50;
}
</style>
