<template>
  <section class="step address-history flex-1">
    <h2>A little more about you</h2>

    <section class="form mt-8">
      <ValidationObserver ref="observer" tag="div" v-slot="{ invalid }">
        <!-- Date of birth field -->
        <div class="field">
          <ValidationProvider
            :rules="
              'required|date|min_years_old:18|date_min_year:' +
              form.date_of_birth.options.minusHundredYears
            "
            v-slot="{ errors }"
            tag="div"
          >
            <FormLabel for-input="field-date-of-birth">What's your date of birth?</FormLabel>
            <input
              @input="isValid"
              @keyup="isValid"
              type="date"
              ref="dob"
              id="field-date-of-birth"
              name="field-date-of-birth"
              v-model="form.date_of_birth.value"
              class="uppercase mt-1 form-input block w-full py-2 pr-3 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out text-xl sm:leading-5"
            />
            <div v-if="errors && errors.length" class="error-message">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>

        <!-- Address finder -->
        <div class="field" v-if="!isAddressHistoryEnough(journey.applicant.address.addresses)">
          <AddressFinder
            postcode-label="What's your current postcode?"
            address-label="Select your address"
            :min-history-months="36"
            @addressAdded="addressAdded"
            placeholder="Search address"
            :saved-addresses-count="journey.applicant.address.addresses.length"
          />
        </div>

        <!-- If address history is enough -->
        <div
          v-show="isAddressHistoryEnough(journey.applicant.address.addresses)"
          class="bg-green-500 text-white p-6 mb-5 mt-8"
        >
          <div class="grid grid-cols-3 gap-4 null">
            <div class="col-span-6">
              <p class="font-bold text-lg mb-0 text-center">
                You've provided enough address history, thanks!
              </p>
            </div>
          </div>
        </div>

        <!-- Previous addresses -->
        <div
          v-if="journey.applicant.address.addresses && journey.applicant.address.addresses.length"
          class="previous-addresses mt-8"
        >
          <FormLabel>Your addresses</FormLabel>
          <div
            v-for="(address, i) in journey.applicant.address.addresses"
            :key="i"
            class="mt-2 text-left sm:py-3 sm:whitespace-no-wrap focus:outline-none active:outline-none inline-flex w-full items-center py-2 text-md leading-normal font-medium transition ease-in-out duration-150 pl-12 pr-5 relative mb-2"
          >
            <span
              @click="removeAddress(address.id)"
              class="cursor-pointer w-10 h-10 flex justify-center items-center absolute left-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="remove-address w-8 h-8"
                viewBox="0 0 384 512"
              >
                <path
                  d="M192 233.4L59.5 100.9 36.9 123.5 169.4 256 36.9 388.5l22.6 22.6L192 278.6 324.5 411.1l22.6-22.6L214.6 256 347.1 123.5l-22.6-22.6L192 233.4z"
                />
              </svg>
            </span>
            <span class="truncate" :title="getAddressDisplayString(address)"
              ><span class="text-sm font-bold mr-2"
                >{{ formatDate(address.moved_in, 'MMMM Y') }},</span
              >{{ getAddressDisplayString(address) }}</span
            >
          </div>
        </div>

        <!-- Buttons -->
        <div class="form-actions flex mt-8">
          <button
            :disabled="!valid"
            :class="{ 'opacity-50': !valid }"
            class="dealer-btn-primary ml-auto"
            @click="submit"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import moment from 'moment';
import AddressFinder from '@/components/Form/AddressFinder.vue';
import FormLabel from '@/components/Form/FormLabel';
import Api from '@/lib/Api';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    AddressFinder,
    FormLabel,
  },

  data() {
    return {
      form: {
        date_of_birth: {
          value: null,
          options: {
            minusHundredYears: moment().year() - 100,
          },
        },
        addresses: {
          value: [],
          options: {
            minHistoryMonths: 36,
          },
        },
      },
      valid: false,
    };
  },

  computed: mapState(['dealer', 'journey']),

  methods: {
    ...Api,

    /**
     * Validates all fields on this step (DOB and address history)
     */
    async isValid() {
      const isValid = await this.$refs.observer.validate();
      this.valid = isValid && this.isAddressHistoryEnough(this.journey.applicant.address.addresses);
    },

    /**
     * Fired when an address is added from the address finder
     * Saves the newly added address and updates whether we have enough history
     * @param {Object} data 'all': All addresses so far, 'current': Newly added address, 'hasEnoughHistory': Whether we have enough history
     */
    async addressAdded(data) {
      // Add the address to the database and Vuex store
      await this.apiStoreAddress(
        this.journey.applicant.id,
        this.prepareApiAddress(data.current, this.journey.applicant.id)
      );

      // Validate
      await this.isValid();
    },

    /**
     * Fired when an address is removed from the address finder
     * Replaces our array of addresses with the updated one
     * @param id
     */
    async removeAddress(id) {
      // Remove the address from the database and Vuex store
      await this.apiRemoveAddress(this.journey.applicant.id, id);

      // Validate
      await this.isValid();
    },

    /**
     * Adds all addresses and updates the applicant's DOB and progresses
     * to the next step
     */
    async submit() {
      if (this.isValid) {
        this.$emit('loading', true, 'Saving...');

        // Update the applicant's DOB
        await this.apiUpdateApplicant(
          this.journey.application.reference,
          this.journey.applicant.id,
          {
            date_of_birth: this.form.date_of_birth.value,
          }
        );

        // Start the Fibre Evaluation
        this.apiGetCreditReport(this.journey.application.reference);

        let dealerName = this.dealer.name;

        if (this.dealer.name) {
          // Attach dealer name to push event
          await this.apiPushEvent(
            this.journey.application.reference,
            dealerName + '_step_address_history',
            dealerName + '_feed_journey',
            dealerName + '_step',
            dealerName + '_address_history'
          );
        } else {
          // Standard push event
          await this.apiPushEvent(
            this.journey.application.reference,
            'step_address_history',
            'feed_journey',
            'step',
            'address_history'
          );
        }

        // Stop loading
        this.$emit('loading', false);

        // Move to the next step
        await this.next();
      }
    },

    /**
     * Gets a credit report from the API
     * @param {String} applicationRef Application reference
     */
    apiGetCreditReport(applicationRef) {
      axios.get(this.getApiBaseUrl() + '/credit-report/get/' + applicationRef);
    },

    /**
     * Proceeds to the next step
     */
    async next() {
      // Move to next step
      return await this.$router.push({ name: 'employment-history' });
    },

    /**
     * Navigates to the previous step
     */
    back() {
      this.$router.push({
        name: 'app_medium__aboutyou',
      });
    },

    /**
     * Returns true if we have enough address history
     * @param {Array} addresses
     */
    isAddressHistoryEnough(addresses = []) {
      const now = moment().startOf('month');
      return (
        addresses.filter((address) => {
          return (
            now.diff(moment(address.moved_in), 'months') >=
            this.form.addresses.options.minHistoryMonths
          );
        }).length > 0
      );
    },

    /**
     * Stores an address against an applicant (API & Store)
     * @param {Number} applicantId
     * @param {Object} address
     */
    apiStoreAddress(applicantId, address) {
      this.$emit('loading', true, 'Saving address');
      return axios
        .post(this.getApiBaseUrl() + '/applicants/' + applicantId + '/addresses', {
          address: address,
        })
        .then((r) => {
          if (r.data.success && r.data.data?.address?.id) {
            // Store the new address in Vuex
            const addressToStore = {
              ...address,
              id: r.data.data.address.id,
            };
            this.$store.commit('addApplicantAddress', addressToStore);
          } else {
            // TODO: Bugsnag breadcrumb
            throw new Error('Failed saving address');
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Removes an address
     * @param applicantId
     * @param addressId
     * @returns {Promise<unknown>}
     */
    apiRemoveAddress(applicantId, addressId) {
      this.$emit('loading', true, 'Removing address');
      return axios
        .delete(this.getApiBaseUrl() + '/applicants/' + applicantId + '/addresses/' + addressId)
        .then((r) => {
          if (r.data.success) {
            return this.$store.commit('removeApplicantAddress', addressId);
          }
          throw new Error(r);
        })
        .catch((e) => {
          // If we can't find the address in the API, remove it from the local store and carry on
          if (e.response.status === 404) {
            return this.$store.commit('removeApplicantAddress', addressId);
          }

          console.error(e);
        })
        .finally(() => {
          this.$emit('loading', false);
        });
    },

    /**
     * Updated an applicant
     * @param {String} applicationRef Application's reference ID
     * @param {Number} applicantId ID of the applicant to update
     * @param {Object} applicantData Data to push
     */
    async apiUpdateApplicant(applicationRef, applicantId, applicantData) {
      return axios
        .patch(
          this.getApiBaseUrl() + '/applications/' + applicationRef + '/applicants/' + applicantId,
          {
            applicant: applicantData,
          }
        )
        .then((r) => {
          if (r.data.success) {
            this.$store.commit('setApplicant', {
              date_of_birth: applicantData.date_of_birth,
            });
          } else {
            console.error('⚠:', r);
          }
        })
        .catch((e) => {
          console.error('⚠:', e);
        });
    },

    /**
     * Maps an address ready for submitting
     * @param {Object} address
     * @param applicantId
     */
    prepareApiAddress(address, applicantId) {
      let movedInDate = null;
      try {
        // movedInDate = address.date._d.toISOString().slice(0, 10)
        movedInDate = address.date.format('YYYY-MM-DD');
      } catch (e) {
        console.error('Error parsing moved in date...');
        console.error(e);
      }

      return {
        // Required
        postcode: address.address?.postcode ?? null,
        residential_status: address.residentialStatus ?? null,
        moved_in: movedInDate,
        street: address.address?.street ?? null,

        // Optional (but really should be required!)
        building_name: address.address?.buildingName ?? null,
        building_number: address.address?.buildingNumber ?? null,
        town: address.address?.town ?? null,
        key: address.address?.key ?? null,
        applicant_id: applicantId ?? null,
        organisation: null,
        flat: null,
        county: null,
        country: null,
        locality: null,
        latitude: null,
        longitude: null,
        display: address.address?.display,
        // moved_in_moment: address.date
      };
    },

    getAddressDisplayString(address) {
      // If we've got a display property, return it
      if (address.display) {
        return address.display;
      }

      // Don't have a display property so create our own one from the data we have.
      let display =
        (address.building_name ? address.building_name + ', ' : '') +
        (address.building_number ? address.building_number + ', ' : '') +
        (address.street ? address.street + ', ' : '') +
        (address.town ? address.town + ', ' : '') +
        (address.postcode ? address.postcode : '');

      // If the last char is a comma, strip it
      if (display[display.length - 1] === ',') {
        return display.substr(0, display.length - 1);
      }

      // Otherwise return the full thing
      return display;
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },

    restore() {
      // DOB
      if (this.journey.applicant.date_of_birth) {
        try {
          this.form.date_of_birth.value = this.formatDate(
            this.journey.applicant.date_of_birth,
            'YYYY-MM-DD'
          );
        } catch (e) {
          // TODO: Bugsnag
          console.error(e);
          this.form.date_of_birth.value = null;
        }
      }
    },
  },

  mounted() {
    // Restore values from Vuex if we have them
    this.restore();

    this.$nextTick(() => {
      this.isValid();
    });

    this.$emit('set-step', 4);
  },
};
</script>
