import axios from 'axios';
import store from '@/store/index';
import Bugsnag from '@bugsnag/js';

export default {
  selectAllText(ev) {
    ev.target.select();
  },

  selectAllTargetIdText(targetId) {
    const target = document.getElementById(targetId);
    target.select();
  },

  scrollToField(fieldId) {
    if (!this.canScrollToFields) {
      return;
    }
    const field = document.getElementById(fieldId);
    if (field === null) {
      return false;
    }
    this.$scrollTo(field);
  },

  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  },

  empty(value) {
    if (typeof value === 'undefined') {
      return true;
    }
    if (value === null) {
      return true;
    }
    if (typeof value === 'object') {
      return this.isObjectEmpty(value);
    }
    return value === '';
  },

  isValueEmpty(value) {
    if (value === null) {
      return true;
    }
    if (typeof value === 'object') {
      return this.isObjectEmpty(value);
    }
    return value === '';
  },

  isValueEmptyAndPristine(ref, field) {
    if (typeof this.$refs[ref] === 'undefined') {
      return false;
    }
    if (!this.isValueEmpty(field.value)) {
      return false;
    }
    return this.$refs[ref].flags.pristine;
  },

  storeData() {
    // only override if needed
  },

  submit() {
    this.storeData();
    this.$emit('progress', this.$options.name);
    this.$router.push(this.nextPage);
  },

  submitIfValidates() {
    if (!this.validates()) {
      return;
    }
    this.submit();
  },

  goBack() {
    this.$router.back();
    this.$emit('back', true);
  },

  getFormStepData() {
    let data = {};
    for (let field in this.form) {
      if (
        Object.prototype.hasOwnProperty.call(this.form, field) &&
        !this.isValueEmpty(this.form[field].value)
      ) {
        data[field] = this.form[field].value;
      }
    }
    return data;
  },

  storeFormStepData(stepName) {
    this.$store.commit('setFormStepData', {
      name: stepName,
      data: this.getFormStepData(),
    });
  },

  initFormStepData(step) {
    let stepData = this.$store.getters.getApplicationStepData(step);
    // Deep clone to avoid mutating VueX
    stepData = JSON.parse(JSON.stringify(stepData));
    if (this.isValueEmpty(stepData)) {
      return;
    }
    let dirty = false;
    for (let field in stepData) {
      if (Object.prototype.hasOwnProperty.call(this.form, field)) {
        if (!this.isValueEmpty(stepData[field])) {
          this.form[field].value = stepData[field];
          dirty = true;
        }
      }
    }
    if (!dirty) {
      return;
    }
    this.canScrollToFields = false;
  },

  hasEmptyFields() {
    for (let field in this.form) {
      if (
        Object.prototype.hasOwnProperty.call(this.form, field) &&
        this.isValueEmpty(this.form[field].value)
      ) {
        return true;
      }
    }
    return false;
  },

  validates() {
    return !this.hasEmptyFields();
  },

  refVeeValidates(ref, field) {
    if (typeof this.$refs[ref] === 'undefined') {
      return false;
    }
    let validator;
    if (typeof this.$refs[ref].getValidator === 'function') {
      validator = this.$refs[ref].getValidator();
    } else {
      validator = this.$refs[ref];
    }

    if (validator.errors.length) {
      return false;
    }
    return !this.isValueEmpty(field.value) && validator.validates() && validator.flags.dirty;
  },

  getMonthsAgo(month, year) {
    month = parseInt(month) - 1;
    let date = this.$moment([year, month, 1]);
    return this.$moment().diff(date, 'months', true);
  },

  apiCallSuccessful(response) {
    if (typeof response === 'undefined') {
      return false;
    }
    if (response.status !== 200) {
      return false;
    }
    if (typeof response.data === 'undefined') {
      return false;
    }
    return response.data.success;
  },

  apiPhoneVerificationSendCode(phone) {
    const postData = {
      phone: phone,
      dealer_slug: this.$store.state.dealer.slug || null,
    };
    return this.$http
      .post(this.api.base + '/phone-verification/send-code', postData)
      .then((r) => {
        return r.data;
      })
      .catch((error) => {
        if (typeof error.response.data.errors === 'undefined') {
          return {
            success: false,
            errors: {},
          };
        }

        return {
          success: false,
          errors: error.response.data.errors,
        };
      });
  },

  assignFormErrorsFromResponse(errors) {
    if (typeof errors === 'undefined') {
      return;
    }

    for (let i in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, i)) {
        if (typeof this.form[i] !== 'undefined') {
          this.form[i].errors = errors[i];
        }
      }
    }
  },

  getDropdownOptionByValue(options, value) {
    return options.find((item) => item.value === value);
  },

  getCurrentDropdownOption(field, key = null) {
    if (!key && this.isValueEmpty(field.value)) {
      return null;
    }
    if (key) {
      return field.options.find((item) => item.value === key);
    }
    return field.options.find((item) => item.value === field.value);
  },

  isComplete() {
    return this.validates();
  },

  isOnline() {
    if (typeof this.$store.state.online === 'undefined') {
      return false;
    }
    return this.$store.state.online;
  },

  getHomeUrl() {
    // if (this.isPrime()) {
    // 	return "/apply-now"
    // } else if (this.isOnline()) {
    // 	return "/car-finance"
    // }
    return '/';
  },

  triggerInput(target) {
    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    });

    target.dispatchEvent(event);
  },

  async storeFacebookIds() {
    const fbp = this.$cookies.get('_fbp');
    const fbc = this.$cookies.get('_fbc');

    await this.$store.commit('setMarketingPixel', {
      ...this.marketingPixel,
      fbp: fbp,
      fbc: fbc,
    });
  },

  /**
   * Returns the dashboard credit score Url
   */
  getDashboardUrl() {
    return process.env.VUE_APP_BASEURL + '/dashboard/credit-score';
  },

  /**
   * Returns the correct APR based on whether it is overridden, estimated or calculated.
   * @returns {*}
   */
  getApr() {
    const apr =
      this.$store.state.dashboard.creditReport.apr_override ??
      this.$store.state.dashboard.creditReport.apr_estimate;
    return apr;
  },

  /**
   * Returns whether application is Prime or not.
   * @returns {boolean}
   */
  isPrime() {
    return this.$store.state.dashboard.application.automation_route === 'PRIME';
  },

  /**
   * Has the application's APR been overridden?
   * @returns {boolean}
   */
  isAprOverrideSet() {
    return this.$store.state.dashboard.application.apr_override !== null;
  },

  allowApply() {
    // They haven't applied for a real quote yet...
    if (!this.$store.state.dashboard.application.has_applied_with_real_quote) {
      // If they're prime, let them apply.
      // If they're not, only let them apply if their APR has been overwritten
      return this.isPrime() ? true : this.isAprOverrideSet();
    }

    return false;
  },

  /**
   * Returns the default non prime APR
   * @returns {number}
   */
  defaultApr() {
    return 24.9;
  },

  /**
   * Simple notification message
   * @param message
   * @param type
   * @returns {ToastObject}
   */
  toast(message, type = 'success') {
    if (type === 'success') {
      return this.$toasted.show(message, {
        theme: 'toasted-primary',
        position: 'top-center',
        type: 'success',
        duration: 3000,
      });
    }

    return this.$toasted.show(message, {
      theme: 'toasted-primary',
      position: 'top-center',
      type: 'error',
      duration: 3000,
    });
  },

  /**
   * Injects dealer branding CSS into the document's head if found in the local store.
   * Returns true if CSS was found and appended, false otherwise.
   * @returns {boolean}
   */
  injectDealerStyles() {
    if (typeof store.state.dealer.css === 'undefined' || store.state.dealer.css === null) {
      return false;
    }

    let styles = document.createElement('style');
    styles.innerHTML = store.state.dealer.css;
    document.head.appendChild(styles);
    return true;
  },

  /**
   * Returns the relative API base URL depending on the environment
   * and/or domain name.
   * @returns {string|*}
   */
  getApiBaseUrl() {
    if (location.hostname === 'localhost') {
      return process.env.VUE_APP_API_BASE;
    }
    return `https://${location.hostname}/api/api`;
  },

  /**
   * Returns the root API base URL depending on the environment
   * and/or domain name.
   * @returns {string|*}
   */
  getApiRootUrl() {
    if (location.hostname === 'localhost') {
      return process.env.VUE_APP_API_ROOT;
    }
    return `https://${location.hostname}/api`;
  },

  async cacheDealer(slug = null) {
    if (slug === null) {
      Bugsnag.notify(new Error('Cannot fetch dealer. Slug was not provided.'));
      return false;
    }

    let url = this.getApiBaseUrl() + `/v1/partner-dealers/get-by-slug/${slug}.json`;
    if (!isNaN(slug)) {
      url = this.getApiBaseUrl() + `/v1/partner-dealers/get/${slug}.json`;
    }

    return axios
      .get(url)
      .then(async (r) => {
        if (!r.data.success) {
          throw `Error fetching dealer: ${slug}`;
        }

        // Set the dealer
        await store.commit('setDealer', r.data.data);

        // Clear partners
        await store.dispatch('resetPartner');

        return true;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return false;
      });
  },

  /**
   * Returns true if the user is/has been referred by a dealer.
   * @returns {boolean}
   */
  hasDealer() {
    return this.$store.state.dealer.id !== null;
  },

  showApr() {
    return this.hasDealer() ? this.$store.state.dealer.frontend_show_apr : true;
  },

  /**
   * Calls the pipedream upsert contact workflow
   * email property in payload required
   * @param payload
   * @returns {AxiosPromise}
   */
  pipedreamUpsertContact(payload) {
    return axios({
      method: 'post',
      url: process.env.VUE_APP_PIPEDREAM_UPSERT,
      withCredentials: false,
      headers: { Authorization: `Bearer ${process.env.VUE_APP_PIPEDREAM_TOKEN}` },
      data: payload,
    });
  },

  pipedreamAddCalculation() {
    // TODO: implement
  },

  pipedreamAddCallback() {
    // TODO: implement
  },

  /**
   * Push event to Hubspot
   * @param eventName
   * @param payload
   */
  hubspotPush(eventName, payload = {}) {
    //Trigger Hubspot's tracking
    let _hsq = (window._hsq = window._hsq || []);

    _hsq.push([eventName, payload]);
  },

  /**
   * Returns an array of options for the 'Term' field.
   * 24 month terms are currently only available to Flow applications.
   * @returns {[{label: string, value: number},{label: string, value: number},{label: string, value: number}]}
   */
  getTermOptions() {
    let opts = [
      { label: '36 months', value: 36 },
      { label: '48 months', value: 48 },
      { label: '60 months', value: 60 },
    ];

    // If this application doesn't belong to a dealer, add the 24 month term option
    if (!this.dealer.name) {
      opts.unshift({ label: '24 months', value: 24 });
    }

    return opts;
  },

  /**
   * Returns the percentage 'value' of 'max'
   * @param value
   * @param max
   * @returns {number}
   */
  getPercentageOf(value, max) {
    if (value > max) {
      return 100;
    }
    if (value <= 0) {
      return 0;
    }
    return (value / max) * 100;
  },

  async fetchApplication(reference) {
    // Get the latest version of their application
    this.$emit('loading', true, 'Getting your latest updates');

    return axios
      .get(this.getApiBaseUrl() + '/me/applications/' + reference)
      .then(async (r) => {
        if (
          r.data.success &&
          typeof r.data.data.application !== 'undefined' &&
          typeof r.data.data.applicant !== 'undefined'
        ) {
          // Set the dashboard application in Vuex
          await this.$store.commit(
            'setCreditReportAprEstimate',
            r.data.data.application.apr_estimate ?? null
          );
          await this.$store.commit(
            'setCreditReportAprOverride',
            r.data.data.application.apr_override ?? null
          );
          await this.$store.commit('setDashboardApplication', r.data.data.application);
          await this.$store.commit('setDashboardApplicant', r.data.data.applicant);

          // Refresh the dealer branding data
          // TODO: Change to use the user's partner_dealer_id
          if (r.data.data.application.partner_dealer_id) {
            await this.cacheDealer(r.data.data.application.partner_dealer_id);
          } else {
            await store.dispatch('resetDealer');
          }

          return;
        }

        // Could not get application, return to application management
        return await this.$router.push({ name: 'applications__manage' });
      })
      .catch(async (e) => {
        this.$emit('loading', false);
        Bugsnag.notify(e);
        return await this.$router.push({ name: 'applications__manage' });
      })
      .finally(() => {
        this.$emit('loading', false);
      });
  },

  shouldDisplayFeed() {
    // If they were referred by a dealer, only show the feed if it's enabled
    if (this.$store.state.dealer.id !== null) {
      return this.$store.state.dealer.feed_enabled;
    }

    // If they answered the Flow additional questions, only show the feed if they haven't found a vehicle yet
    const applicationQuestion =
      this.$store.state.dashboard.application.application_question || false;
    if (applicationQuestion) {
      return !applicationQuestion.found_vehicle;
    }

    // For Flow users who haven't answered the question (legacy), show the feed if they are non-prime
    return !this.isPrime();
  },
};
