<template>
  <section class="step address-finder" id="address-finder">
    <!-- Friendly errors -->
    <div class="" v-if="pageError">
      <div class="error-message">{{ pageError }}</div>
    </div>

    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
      <!-- If they've added an address but we need more... -->
      <div
        v-show="
          !isAddressHistoryEnough() &&
          journey.applicant.address.addresses &&
          journey.applicant.address.addresses.length &&
          shouldValidate
        "
        class="error-message"
      >
        <div class="grid grid-cols-3 gap-4 null">
          <div class="col-span-6">
            <h4 class="font-bold text-xl mb-2 text-white">We need a bit more history</h4>
            <p class="mb-0">
              We need at least
              {{
                minHistoryMonths > 0
                  ? minHistoryMonths / 12 + ' years'
                  : minHistoryMonths + ' months'
              }}
              of address history to continue.
            </p>
          </div>
        </div>
      </div>

      <!-- Manual entry (only shown if there's an error) -->
      <div v-show="isManualEntry || manualAddressAdded" id="manual-entry">
        <label for="manual-entry-inner"
          >Your <template v-if="savedAddressesCount">previous</template> address</label
        >
        <div id="manual-entry-inner" class="mt-3">
          <div v-show="isManualEntry">
            <ManualAddress
              ref="manual-address-form"
              @manual-address-saved="manualAddressSaved"
              @use-address-finder="changeToAddressFinder"
            />
          </div>
          <div v-if="manualAddressAdded && !isManualEntry">
            <div
              class="mt-2 text-left sm:py-3 sm:whitespace-no-wrap focus:outline-none active:outline-none inline-flex rounded-full w-full items-center px-4 py-2 text-md leading-normal font-medium transition ease-in-out duration-150 pl-12 pr-5 relative"
            >
              <span
                @click="editManualAddress"
                class="hover:dealer-bg-secondary cursor-pointer dealer-bg-primary text-white p-2 rounded-full w-8 h-8 flex justify-center items-center absolute left-1"
                ><i class="fas fa-trash-alt remove-address text-md"></i
              ></span>
              <span
                class="truncate text-ellipsis"
                :title="
                  form.address.value.buildingName +
                  ' ' +
                  form.address.value.buildingNumber +
                  ' ' +
                  form.address.value.street +
                  ' ' +
                  form.address.value.town +
                  ' ' +
                  form.address.value.postcode
                "
              >
                <template v-if="form.address.value.buildingName"
                  >{{ form.address.value.buildingName }}, </template
                ><template v-if="form.address.value.buildingNumber"
                  >{{ form.address.value.buildingNumber }}, </template
                >{{ form.address.value.street }}, {{ form.address.value.town }},
                {{ form.address.value.postcode }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Postcode search -->
      <template v-if="!isManualEntry && !manualAddressAdded">
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
          <FormLabel for-input="postcode"
            >Your <template v-if="savedAddressesCount">previous</template> postcode</FormLabel
          >
          <TextInputButton
            rules="required|min:5|max_no_space:7"
            id=" postcode"
            name="postcode"
            v-model="form.postcode.value"
            :is-uppercase="true"
            validation-mode="aggressive"
            @keyup.enter.native="findAddressesByPostcode()"
            @click="findAddressesByPostcode()"
            :valueButton="form.postcode.loading ? 'Searching...' : 'Lookup'"
            :is-disabled="invalid || form.postcode.loading"
            placeholder="Search address"
          />
          <div class="error-message" v-if="form.postcode.errors.length">
            {{ form.postcode.errors[0] }}
          </div>
          <div
            v-if="form.postcode.errors.length"
            @click="isManualEntry = true"
            class="mt-5 underline hover:no-underline cursor-pointer"
            title=""
          >
            Add your address details manually
          </div>
        </ValidationObserver>

        <!-- Address drop down -->
        <template v-if="form.postcode.found && !form.postcode.errors.length">
          <div id="field__address" class="mt-7 transition duration-150 ease-in-out">
            <validation-provider ref="address" name="address" rules="required" v-slot="{ errors }">
              <!-- <FormLabel for-input="available-addresses">Select your address</FormLabel> -->
              <label for="available-addresses">Select your address</label>
              <select
                id="available-addresses"
                name="available-addresses"
                v-model="form.address.value"
                class="py-2 mt-1 block form-select w-full transition duration-150 ease-in-out cursor-pointer"
              >
                <option v-for="(address, i) in form.address.options" :key="i" :value="i">
                  {{ address.display }}
                </option>
              </select>
              <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </template>
      </template>

      <!-- Date moved in -->
      <transition name="fade">
        <div
          id="address-date-section"
          v-if="(form.postcode.found && !form.postcode.errors.length) || manualAddressAdded"
        >
          <div id="field__address-date" class="mt-9 transition duration-150 ease-in-out">
            <label for="months-dropdown">When did you move in?</label>
            <div class="grid grid-cols-2 gap-6 mt-1 sm:mt-3">
              <div>
                <validation-provider
                  ref="month"
                  name="month"
                  :rules="form.movedInMonth.rules"
                  v-slot="{ errors }"
                >
                  <label for="months-dropdown">Month</label>
                  <select
                    @input="validateMovedInDate('month', $event)"
                    id="months-dropdown"
                    name="months-dropdown"
                    v-model="form.movedInMonth.value"
                    class="form-select text-xl"
                  >
                    <option
                      v-for="(month, i) in form.movedInMonth.options"
                      :key="i"
                      :value="month.value"
                    >
                      {{ month.label }}
                    </option>
                  </select>
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>
              </div>
              <div>
                <validation-provider
                  ref="year"
                  name="year"
                  :rules="form.movedInYear.rules"
                  v-slot="{ errors }"
                >
                  <label for="field-surname">Year</label>
                  <input
                    @input="validateMovedInDate('year', $event)"
                    name="years-input"
                    id="years-input"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="tel"
                    maxlength="4"
                    class="form-input text-lg"
                    v-model="form.movedInYear.value"
                    placeholder="2019"
                  />
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- Address status -->
      <!-- TODO: Dropdown here or buttons? (6 options) -->
      <transition name="fade">
        <div
          v-show="form.residentialStatus.display"
          id="field__address-status"
          class="mt-9 transition duration-150 ease-in-out"
        >
          <label for="address-status" class="mb-3">What best describes you?</label>
          <RadioButtons
            ref="residentialStatusField"
            @changed="saveAddress"
            :options="form.residentialStatus.options"
            rules="required"
            v-model="form.residentialStatus.value"
            classes="sm:grid-cols-2"
          />
        </div>
      </transition>
    </ValidationObserver>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import ManualAddress from '@/components/Form/ManualAddress';
import TextInputButton from '@/components/Form/TextInputButton';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import moment from 'moment';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import Button from '@/components/Form/Button';
import FormLabel from '@/components/Form/FormLabel.vue';

export default {
  components: {
    FormLabel,
    Button,
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    ManualAddress,
    TextInputButton,
  },

  data() {
    return {
      addresses: [],
      currentAddress: {},
      isManualEntry: false,
      manualAddressAdded: false,
      pageError: null,
      form: {
        residentialStatus: {
          value: null,
          options: [
            // Default to unfurnished for private tenancy
            { label: 'Homeowner', value: 'Owner' },
            { label: 'Private tenant', value: 'Tenant Private Unfurnished' },
            // Using the option above to reduce fields (used to be separate):
            // { label: "Private tenant (furnished)", value: "Tenant Private Furnished" },
            // { label: "Private tenant (unfurnished)", value: "Tenant Private Unfurnished" },
            { label: 'Council tenant', value: 'Tenant Council' },
            { label: 'Living with parents', value: 'Living With Parents' },
            { label: 'Other', value: 'Other' },
          ],
          display: false,
        },
        postcode: {
          value: null,
          found: false,
          loading: false,
          errors: [],
        },
        address: {
          value: null,
          options: [],
          errors: [],
        },
        movedInMonth: {
          options: [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' },
          ],
          rules: {
            required: true,
            month_not_future_with: { target: '@year' },
          },
          value: null,
        },
        movedInYear: {
          rules: {
            required: true,
            year: true,
            min_value: 1900,
            max_value: moment().format('YYYY'),
            year_not_future_with: { target: '@month' },
          },
          options: {},
          value: null,
        },
      },
    };
  },

  computed: {
    ...mapState(['journey']),
  },

  props: {
    minHistoryMonths: {
      type: Number,
      default: 36,
    },
    savedAddressesCount: {
      type: Number,
      default: 0,
    },
    shouldValidate: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    changeToAddressFinder() {
      this.manualAddressAdded = false;
      this.isManualEntry = false;
    },

    editManualAddress() {
      this.form.address.value = null;
      this.manualAddressAdded = false;
      this.isManualEntry = true;
      this.form.residentialStatus.display = false;
      this.form.movedInMonth.value = null;
      this.form.movedInYear.value = null;
      this.form.residentialStatus.value = null;
    },

    apiAddressLookup(postcode) {
      return axios(this.getApiBaseUrl() + '/address-lookup?postcode=' + postcode)
        .then((response) => {
          if (!response.data.success) {
            Bugsnag.leaveBreadcrumb('Postcode', postcode, 'log');
            Bugsnag.leaveBreadcrumb('Error', response.data.errors, 'error');
            Bugsnag.leaveBreadcrumb('API response', response, 'log');
            Bugsnag.notify('Error searching for postcode');

            return {
              success: false,
              errors: response.data.errors,
            };
          }
          return response.data;
        })
        .catch((error) => {
          // It wasn't an invalid search param error so log it
          const status = error.response && error.response.status ? error.response.status : null;

          // Not an unprocessable entity, so notify dev team of the error
          if (status !== 422) {
            Bugsnag.notify(error);
          }

          return {
            success: false,
            status: status,
          };
        });
    },

    /**
     * Returns how many months of address history we currently have
     */
    addressHistoryInMonths(addresses = []) {
      const now = moment().startOf('month');

      let largestMonthDiff = 0;
      validateMovedInDate;

      addresses.forEach((address) => {
        let diff = now.diff(moment(address.moved_in), 'months');
        if (largestMonthDiff == 0 || diff > largestMonthDiff) {
          largestMonthDiff = diff;
        }
      });

      return largestMonthDiff;
    },

    /**
     * Sets the manually entered address as the current
     * address and scrolls to the moved in date
     */
    manualAddressSaved(address) {
      this.form.address.value = address;
      this.manualAddressAdded = true;

      // We've got an address, so hide the manual entry form
      this.isManualEntry = false;
    },

    /**
     * Validates the month and year the applicant moved in
     */
    async validateMovedInDate(type, e) {
      if (type === 'month') {
        this.form.movedInMonth.value = e.target.value;
      }

      if (type === 'year') {
        this.form.movedInYear.value = e.target.value;
      }

      if (this.$refs.month && this.$refs.year) {
        this.$nextTick(async () => {
          const monthIsValid = await this.$refs.month.validate();
          const yearIsValid = await this.$refs.year.validate();
          // const movedInDateIsValid = (monthIsValid.valid && yearIsValid.valid)
          this.form.residentialStatus.display = monthIsValid.valid && yearIsValid.valid;

          return;
        });
      }

      return;
    },

    /**
     * Removes a saved address
     * @param {Number} index Index of the address to remove
     */
    removeAddress(index) {
      const removedAddress = this.addresses[index];

      if (confirm('Are you sure you want to remove this address?')) {
        if (this.addresses[index]) {
          this.addresses.splice(index, 1);
        }
      }

      this.$emit('addressRemoved', {
        all: this.addresses,
        removed: removedAddress,
      });
    },

    /**
     * Saves the address. If there's not enough address history, the user will be informed
     */
    saveAddress() {
      if (this.form.address.value !== null) {
        const address = isNaN(this.form.address.value)
          ? this.form.address.value
          : this.form.address.options[this.form.address.value];
        this.currentAddress = this.mapLookupAddress(address);

        if (!this.isAddressComplete(this.currentAddress.address)) {
          this.pageError =
            "We couldn't find all of the details needed for that address, please enter it manually.";
          return (this.isManualEntry = true);
        }

        this.addresses.push(this.currentAddress);

        this.$emit('addressAdded', {
          all: this.addresses,
          current: this.currentAddress,
        });

        this.reset();
      }
    },

    /**
     * Resets the address finder form
     */
    reset() {
      // Reset the additional details form
      this.currentAddress = {};
      this.form.postcode.found = false;
      this.form.postcode.value = null;
      this.form.address.value = null;
      this.form.movedInMonth.value = null;
      this.form.movedInYear.value = null;
      this.form.residentialStatus.value = null;
      this.form.residentialStatus.display = false;
      this.$refs.observer.reset();
      this.manualAddressAdded = false;
      this.isManualEntry = false;
      this.$refs.residentialStatusField.reset();
      this.form.postcode.errors = [];

      // Clear page errors
      this.pageError = null;

      // Reset the manual entry form if it exists
      if (this.$refs['manual-address-form']) {
        this.$refs['manual-address-form'].resetForm();
      }
    },

    /**
     * Returns boolean depending on whether the current address is valid
     * @param {Object} address
     */
    isAddressComplete(address) {
      if (!address) {
        return false;
      }
      if (this.empty(address.street)) {
        return false;
      }
      if (this.empty(address.buildingName) && this.empty(address.buildingNumber)) {
        return false;
      }
      if (this.empty(address.town)) {
        return false;
      }
      return true;
    },

    empty(value) {
      if (typeof value === 'undefined') {
        return true;
      }
      if (value === null) {
        return true;
      }
      if (typeof value === 'object') {
        return this.isObjectEmpty(value);
      }
      return value === '';
    },

    /**
     * Returns true if we have enough address history
     */
    isAddressHistoryEnough() {
      const now = moment().startOf('month');
      const isValid =
        this.addresses.filter((address) => {
          return now.diff(address.date, 'months') >= this.minHistoryMonths;
        }).length > 0;

      this.$emit('isValid', isValid);
      return isValid;
    },

    /**
     * Prepares a looked up address to be stored as an address
     * @param {Object} address
     */
    mapLookupAddress(address) {
      // If the month is less than 10, pad it with a zero
      let month = String(this.form.movedInMonth.value);
      let year = String(this.form.movedInYear.value);

      // Padstart prototype not supported with this version of webpack
      if (this.form.movedInMonth.value < 10) {
        month = String('0' + month);
      }

      const movedInDate = year + '-' + month + '-01';

      // Map the address object
      let mappedAddress = {
        address: {
          buildingName: address.buildingName || null,
          buildingNumber: address.buildingNumber || null,
          key: address.key || null,
          postcode: address.postcode,
          street: address.street,
          town: address.town,
        },
        month: this.form.movedInMonth.value,
        postcode: address.postcode,
        residentialStatus: this.form.residentialStatus.value,
        year: this.form.movedInYear.value,
        date: moment(movedInDate),
      };

      // Generate "display" field
      if (!address.display) {
        let display =
          mappedAddress.address.street +
          ', ' +
          mappedAddress.address.town +
          ', ' +
          mappedAddress.postcode;
        if (mappedAddress.address.buildingName) {
          display = mappedAddress.address.buildingName + ', ' + display;
        }
        if (mappedAddress.address.buildingNumber) {
          display = mappedAddress.address.buildingNumber + ', ' + display;
        }
        mappedAddress.address.display = display;
      } else {
        mappedAddress.address.display = address.display;
      }

      return mappedAddress;
    },

    /**
     * Queries the API for a list of addresses related to a post code
     */
    async findAddressesByPostcode() {
      this.form.postcode.loading = true;
      this.form.postcode.errors = [];
      const postcode = this.form.postcode.value.trim().replace(/\s/g, '');
      const lookup = await this.apiAddressLookup(postcode);
      this.form.postcode.loading = false;

      if (lookup.success) {
        if (lookup.data.items.length) {
          // Got results
          this.form.address.options = lookup.data.items;
          this.form.postcode.found = true;
          this.form.address.value = 0;
        } else {
          // Valid postcode, but no results
          this.form.postcode.errors.push("We couldn't find any addresses for that postcode");
        }
      } else if (lookup.status === 422) {
        // Invalid postcode
        this.form.postcode.errors.push("We couldn't find any addresses for that postcode");
      } else {
        // Error! Show manual entry
        this.pageError =
          'We are currently experiencing issues with our address finder, please enter your address below.';
        this.isManualEntry = true;
      }
    },
  },
};
</script>
