import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import methods from '@/lib/methods';

export default {
  /**
   * Handles API-specific axios request errors
   * @param e
   * @param message
   */
  apiErrorHandler(e = {}, message = null) {
    Bugsnag.leaveBreadcrumb('Error returned from API', e, 'log');

    if (message !== null) {
      Bugsnag.notify(message);
    }
  },

  /**
   * Return all vehicle makes associated with the vehicle class (only supports cars (1) currently).
   * @param vehicleClass
   * @param date
   * @returns {Promise<AxiosResponse<any> | *[]>}
   */
  apiGetVehicleMakes(vehicleClass = 1, date = null) {
    if (date === null) {
      date = new Date();
    } else {
      date = new Date(date);
    }

    const formattedDate = date.toISOString().split('T')[0];
    return axios
      .get(methods.getApiBaseUrl() + '/cap/' + vehicleClass + '/makes', {
        params: { date: formattedDate },
      })
      .then((r) => {
        if (r.data.success && r.data.data.makes) {
          return r.data.data.makes;
        }
        throw r.data.errors;
      })
      .catch((e) => {
        this.apiErrorHandler(e, 'Error fetching vehicle makes');
        return [];
      });
  },

  /**
   * Return vehicle models for a specific make
   * @param make
   * @param date
   * @returns {Promise<AxiosResponse<any> | *[]>|*[]}
   */
  apiGetVehicleModels(make = null, date = null) {
    if (make === null) {
      return [];
    }

    if (date === null) {
      date = new Date();
    } else {
      date = new Date(date);
    }

    const formattedDate = date.toISOString().split('T')[0];
    return axios
      .get(methods.getApiBaseUrl() + '/cap/makes/' + make + '/models', {
        params: { date: formattedDate },
      })
      .then((r) => {
        if (r.data.success && r.data.data.models) {
          return r.data.data.models;
        }
        throw r.data.errors;
      })
      .catch((e) => {
        this.apiErrorHandler(e, 'Error fetching vehicle models');
        return [];
      });
  },

  /**
   * Returns an array of derivatives of a vehicle based on it's model.
   * @param {Number} model Model ID (retrieved from apiGetVehicleModels())
   * @param {Date} date
   * @returns {array}
   */
  apiGetVehicleDerivatives(model, date = null) {
    if (model === null) {
      return [];
    }

    if (date === null) {
      date = new Date();
    } else {
      date = new Date(date);
    }

    const formattedDate = date.toISOString().split('T')[0];
    return axios
      .get(methods.getApiBaseUrl() + '/cap/models/' + model + '/derivatives', {
        params: { date: formattedDate },
      })
      .then((r) => {
        if (r.data.success && r.data.data.derivatives) {
          return r.data.data.derivatives;
        }
        throw r.data.errors;
      })
      .catch((e) => {
        this.apiErrorHandler(e, 'Error fetching vehicle derivatives');
        return [];
      });
  },

  /**
   *
   * @param {*} reference
   * @param {*} updateStore
   * @returns
   */
  async refreshApplication(updateStore = true) {
    return axios
      .get(`${methods.getApiBaseUrl()}/me/all`)
      .then(async (r) => {
        if (
          r.data.success &&
          typeof r.data.data.application !== 'undefined' &&
          typeof r.data.data.applicant !== 'undefined'
        ) {
          // Should we update the store with what we got?
          if (updateStore) {
            await this.$store.commit('setDashboardApplication', r.data.data.application);
            await this.$store.commit('setDashboardApplicant', r.data.data.applicant);
          }
          return true;
        }
        return false;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },

  /**
   * Returns a fixed object with PCP and/or HP quote result arrays
   * @param {object} figures A complex object containing the quote figures
   * @param {*} type "PCP", "HP" or "BOTH". Default: "BOTH"
   * @returns
   */
  apiGetAutquoteResults(figures = {}, type = 'BOTH') {
    const defaults = {
      PartExchange: 0,
      OutstandingSettlement: 0,
      DepositCash: 0,
      CurrentMileage: 0,

      // Additional flags
      FilterBestPrice: false,
      FilterMatchApr: false,
    };

    let payload = defaults;

    // Validate AnnualMileage property
    if (figures.AnnualMileage === null) {
      throw new Error('AnnualMileage property is required');
    }
    if (isNaN(figures.AnnualMileage)) {
      throw new Error('AnnualMileage property must be a valid number');
    }
    if (figures.AnnualMileage < 6000 || figures.AnnualMileage > 40000) {
      throw new Error('AnnualMileage property must be between 6,000 and 40,000');
    }
    // Round AnnualMileage to the nearest 1000
    payload.AnnualMileage = Math.round(figures.AnnualMileage / 1000) * 1000;

    // Validate cash price
    if (figures.CashPrice === null) {
      throw new Error('CashPrice property is required');
    }
    if (isNaN(figures.CashPrice)) {
      throw new Error('CashPrice property must be a valid number');
    }
    payload.CashPrice = figures.CashPrice;

    // Validate term
    if (figures.Term === null) {
      throw new Error('Term property is required');
    }
    if (isNaN(figures.Term)) {
      throw new Error('Term must be a valid number');
    }
    payload.Term = figures.Term;

    // Validate QuoterAPR
    if (figures.QuoterAPR === null) {
      throw new Error('QuoterAPR property is required');
    }
    if (isNaN(figures.QuoterAPR)) {
      throw new Error('QuoterAPR property must be a valid number');
    }
    if (figures.QuoterAPR < 0 || figures.QuoterAPR > 100) {
      throw new Error('QuoterAPR property must be between 0 and 100');
    }
    payload.QuoterAPR = figures.QuoterAPR;

    // Validate CAPCode
    if (figures.CAPCode === null) {
      throw new Error('CAPCode property is required');
    }
    payload.CAPCode = figures.CAPCode;

    // Validate RegistrationDate
    if (figures.RegistrationDate === null) {
      throw new Error('RegistrationDate property is required');
    }
    payload.RegistrationDate = figures.RegistrationDate;

    // Type of quotes to fetch
    payload.Type = type;

    // Optional fields
    payload.PartExchange = figures.PartExchange;
    payload.OutstandingSettlement = figures.OutstandingSettlement;
    payload.DepositCash = figures.DepositCash;
    payload.CurrentMileage = figures.CurrentMileage;
    payload.FilterBestPrice = figures.FilterBestPrice;
    payload.FilterMatchApr = figures.FilterMatchApr;

    return axios
      .post(`${methods.getApiBaseUrl()}/calculator`, payload)
      .then((r) => {
        if (r.data.success && r.data.data.quotes) {
          return r.data.data.quotes;
        }
        throw r.data.errors;
      })
      .catch((e) => {
        this.apiErrorHandler(e, 'Error fetching Autoquote quote results');
        return {
          pcp: [],
          hp: [],
        };
      });
  },

  /**
   * Returns a vehicle by its VRM (Vehicle Registration Mark)
   * @param {String} vrm
   * @returns {object}
   */
  apiGetVehicleByVrm(vrm = null) {
    // Always lookup vehicles from the live API to prevent access issues from different environments
    return axios
      .get('https://apply.magnitudefinance.com/api/api/vehicle-lookup', {
        params: { vrm: vrm },
      })
      .then((r) => {
        if (r.data.success && r.data.data.vehicle) {
          return r.data.data.vehicle;
        }
        throw r.data.errors;
      })
      .catch((e) => {
        this.apiErrorHandler(e, 'Error looking up vehicle (by VRM)');
        throw e;
      });
  },

  apiGetApplicationStatuses(applicationReference) {
    return axios
      .get(`${methods.getApiBaseUrl()}/applications/${applicationReference}/statuses`)
      .then((r) => {
        return r.data.data;
      })
      .catch(() => {
        return null;
      });
  },

  apiGetApplication(reference) {
    return axios.get(`${methods.getApiBaseUrl()}/applications/${reference}`).then((r) => {
      if (!this.apiCallSuccessful(r)) {
        this.error = true;
        return {};
      }
      return r.data.data.application;
    });
  },

  /**
   * Submits an applicant's banking details to the API
   * @param
   * @returns
   */
  apiStoreBankingDetails(applicationReference, applicant, banking) {
    return axios.post(
      `${methods.getApiBaseUrl()}/applications/${applicationReference}/applicants/${
        applicant.id
      }/store-banking`,
      {
        _method: 'PATCH',
        ...banking,
      }
    );
  },

  /**
   * Returns the current quote result which has been submitted on an application. Also includes vehicle and figures.
   * @param reference
   * @returns {Promise<unknown>}
   */
  apiGetSubmittedQuote(reference) {
    return axios
      .get(`${methods.getApiBaseUrl()}/v1/applications/${reference}/submitted/quote.json`)
      .then((r) => {
        if (r.data.success) {
          return r.data.data;
        }
        return null;
      })
      .catch((e) => {
        // TODO: Bugsnag
        console.error(e);
        return null;
      });
  },

  /**
   * Returns partner data from the API.
   * @param id
   * @returns {*}
   */
  apiGetPartner(id) {
    return axios
      .get(`${methods.getApiBaseUrl()}/partner/get/${id}`)
      .then((r) => {
        if (r.data?.data?.partner) {
          return r.data.data.partner;
        }

        // TODO: Bugsnag!
        return null;
      })
      .catch((e) => {
        // TODO: Check error, (404 - invalid partner, otherwise, report to bugsnag)
        return null;
      });
  },

  /**
   * Marks the application as complete and submits the quote they've chosen to MOWS (via Flow API).
   * @param applicationReference
   * @param quoteResultId
   * @returns {Promise<AxiosResponse<any> | boolean>}
   */
  async apiCompleteApplication(applicationReference, quoteResultId) {
    return axios
      .post(`${methods.getApiBaseUrl()}/v1/applications/${applicationReference}/submit.json`, {
        quote_result_id: quoteResultId,
      })
      .then((r) => {
        return r.data.success;
      })
      .catch(() => {
        return false;
      });
  },

  apiGetUserIp() {
    return axios
      .get('https://api.ipify.org?format=json', {
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.ip) {
          return response.data.ip;
        }
        return null;
      })
      .catch((e) => {
        return null;
      });
  },

  /**
   *
   * @param applicationRef
   * @param internalEventName
   * @param category
   * @param action
   * @param label
   * @param unique
   * @returns {Promise<AxiosResponse<any>>}
   */
  apiPushEvent(applicationRef, internalEventName, category, action, label, unique = true) {
    return axios
      .post(`${methods.getApiBaseUrl()}/v1/applications/${applicationRef}/google/send/event.json`, {
        internal_event_name: internalEventName,
        category: category,
        action: action,
        label: label,
        send_once: unique,
      })
      .then((r) => {
        if (r.data.success && r.data.data.sent) {
          return r.data.data.sent;
        }
        return false;
      });
  },

  /**
   * Returns vehicle models based on vehicle class and date
   * @param vehicleClass
   * @param date
   * @returns {*}
   */
  apiGetCapMakes(vehicleClass, date) {
    return axios
      .get(`${methods.getApiBaseUrl()}/cap/${vehicleClass}/makes`, {
        params: {
          date: date,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return null;
      });
  },

  /**
   * Returns vehicle models based on a make
   * @param makeId
   * @param date
   * @returns {*}
   */
  apiGetCapModels(makeId, date) {
    return axios
      .get(`${methods.getApiBaseUrl()}/cap/makes/${makeId}/models`, {
        params: {
          date: date,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return null;
      });
  },

  /**
   * Returns vehicle derivatives based on a vehicle model
   * @param modelId
   * @param date
   * @returns {*}
   */
  apiGetCapDerivatives(modelId, date) {
    return axios
      .get(`${methods.getApiBaseUrl()}/cap/models/${modelId}/derivatives`, {
        params: {
          date: date,
        },
      })
      .then((response) => {
        return response.data.data;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return null;
      });
  },

  /**
   * Validates a login bypass token
   * @param token
   * @param userId
   * @returns {Promise<AxiosResponse<any> | boolean>}
   */
  apiValidateLoginToken(token, userId) {
    return axios
      .post(`${methods.getApiBaseUrl()}/v1/users/${userId}/token/validate.json`, {
        token: token,
      })
      .then((r) => {
        if (!r.data.success) {
          throw new Error('API failed to validate login bypass token or it was invalid.');
        }

        return r.data.data;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return false;
      });
  },

  apiGetCreditReport(ref) {
    return axios
      .get(`${methods.getApiBaseUrl()}/me/credit-report/${ref}`)
      .then((r) => {
        if (r.data.success) {
          return r.data.data;
        }
        throw new Error(r.data.errors);
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return null;
      });
  },

  apiValidateApplicationLogin() {
    return axios
      .get(`${methods.getApiBaseUrl()}/me/application-validation/login`)
      .then((r) => {
        if (!r.data.success) {
          throw new Error('Failed to validate whether user is allowed to login.');
        }
        return r.data.data;
      })
      .catch((e) => {
        Bugsnag.notify(e);
        return false;
      });
  },
};
