<template>
  <section class="application-review">
    <vue-headful
      description="At Flow car Finance we think car finance should be fair, simple & sincere. With over 30 years of knowledge, expertise and listening to you, we came up with an effortless car, van or motorcycle buying experience."
      url="https://flowcarfinance.co.uk/applications"
    />

    <Banner
      banner_title="Review application details"
      banner_subtitle="Please review details below to create an new application"
    />

    <main class="bg-gray-100">
      <div
        v-if="applicant !== null"
        class="max-w-7xl mx-auto text-white py-16 px-4 sm:px-6 xl:px-0 w-full grid lg:grid-cols-2 gap-6"
      >
        <!--Personal details-->
        <div>
          <div class="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-md">
            <div
              class="bg-flow-blue dealer-bg-banner text-white justify-between items-center inline-flex w-full p-3"
            >
              <h4 class="text-white mb-0">Personal details</h4>
              <div>
                <button
                  @click="$router.push({ name: 'applications__review_personal_details' })"
                  type="button"
                  class="rounded-full bg-flow-teal hover:bg-transparent hover:border-flow-teal dealer-btn-primary-outer text-white py-1 px-4 uppercase text-sm"
                >
                  Edit
                </button>
              </div>
            </div>
            <div class="divide-y divide-gray-300">
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">Title</h4>
                <div class="w-1/2">
                  {{ applicant.title }}
                </div>
              </div>
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">First name</h4>
                <div class="w-1/2">
                  {{ applicant.first_name }}
                </div>
              </div>
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">Last name</h4>
                <div class="w-1/2">
                  {{ applicant.surname }}
                </div>
              </div>
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">Email</h4>
                <div class="w-1/2">
                  {{ applicant.email }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--About you-->
        <div>
          <div class="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-md">
            <div
              class="bg-flow-blue dealer-bg-banner text-white justify-between items-center inline-flex w-full p-3"
            >
              <h4 class="text-white mb-0">About you</h4>
              <div>
                <button
                  @click="$router.push({ name: 'applications__review_about_you' })"
                  type="button"
                  class="rounded-full bg-flow-teal hover:bg-transparent hover:border-flow-teal dealer-btn-primary-outer text-white py-1 px-4 uppercase text-sm"
                >
                  Edit
                </button>
              </div>
            </div>
            <div class="divide-y divide-gray-300">
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">Date of birth</h4>
                <div class="w-1/2">
                  {{ applicant.date_of_birth | moment('DD/MM/YYYY') }}
                </div>
              </div>
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">Marital Status</h4>
                <div class="w-1/2">
                  {{ applicant.marital_status }}
                </div>
              </div>
              <div class="inline-flex text-gray-700 p-3 justify-between w-full">
                <h4 class="mb-0">Driving License</h4>
                <div class="w-1/2">
                  {{ applicant.driving_licence_type }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Address history-->
        <div>
          <div class="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-md">
            <div
              class="bg-flow-blue dealer-bg-banner text-white justify-between items-center inline-flex w-full p-3"
            >
              <h4 class="text-white mb-0">Address history</h4>
              <div>
                <button
                  @click="$router.push({ name: 'applications__review_address_history' })"
                  type="button"
                  class="rounded-full bg-flow-teal hover:bg-transparent hover:border-flow-teal dealer-btn-primary-outer text-white py-1 px-4 uppercase text-sm"
                >
                  Edit
                </button>
              </div>
            </div>
            <div v-if="errors.addresses" class="bg-red-500 text-white p-3">
              {{ errors.addresses }}
            </div>
            <div class="divide-y divide-gray-300">
              <div
                class="flex-row sm:inline-flex text-gray-700 p-3 justify-between w-full items-center"
              >
                <h4 class="mb-0">Address</h4>
                <div class="w-auto sm:w-1/2">
                  <div v-for="(address, i) in addresses" :key="i" class="flex flex-wrap py-2">
                    <span class="w-full uppercase"
                      >{{ address.building_name || address.building_number }} {{ address.street }},
                      {{ address.town }}, {{ address.postcode }}</span
                    >
                    <small>Moved in on {{ address.moved_in | moment('DD/MM/YYYY') }}</small>
                  </div>

                  <div v-if="addresses.length === 0" class="flex flex-wrap py-2">
                    <span class="w-full">No address history yet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Employment history-->
        <div>
          <div class="overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 rounded-md">
            <div
              class="bg-flow-blue dealer-bg-banner text-white justify-between items-center inline-flex w-full p-3"
            >
              <h4 class="text-white mb-0">Employment history</h4>
              <div>
                <button
                  @click="$router.push({ name: 'applications__review_employment_history' })"
                  type="button"
                  class="rounded-full bg-flow-teal hover:bg-transparent hover:border-flow-teal dealer-btn-primary-outer text-white py-1 px-4 uppercase text-sm"
                >
                  Edit
                </button>
              </div>
            </div>
            <div v-if="errors.employments" class="bg-red-500 text-white p-3">
              {{ errors.employments }}
            </div>
            <div class="divide-y divide-gray-300">
              <div
                class="flex-row sm:inline-flex text-gray-700 p-3 justify-between w-full items-center"
              >
                <h4 class="mb-0">Address</h4>
                <div class="w-auto sm:w-1/2">
                  <div v-for="(employment, i) in employments" :key="i" class="flex flex-wrap py-2">
                    <span class="w-full uppercase"
                      >{{ employment.building_name || employment.building_number }}
                      {{ employment.street }}, {{ employment.town }},
                      {{ employment.postcode }}</span
                    >
                    <small class="w-full"
                      >{{ employment.type }} - {{ employment.status }} - Since
                      {{ employment.started | moment('DD/MM/YYYY') }}</small
                    >
                  </div>

                  <div v-if="employments.length === 0" class="flex flex-wrap py-2">
                    <span class="w-full">No employment history yet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="max-w-7xl mx-auto justify-between col-span-2 flex items-center pb-16 px-4 sm:px-6 xl:px-0"
      >
        <div
          class="text-flow-blue dealer-text-secondary font-semibold flex items-center hover:text-flow-teal cursor-pointer space-x-2"
          @click="$router.push({ name: 'applications__manage' })"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
          <span>Cancel</span>
        </div>
        <button
          class="bg-flow-teal border-flow-teal dealer-btn-primary ml-auto hover:dealer-text-primary"
          @click="
            $router.push({
              name: 'application-dashboard',
              params: { reference: dashboard.application.reference },
            })
          "
        >
          Confirm
        </button>
      </div>
    </main>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Applications/Banner';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Button from '@/components/Form/Button';
import methods from '@/lib/methods';
import Bugsnag from '@bugsnag/js';

export default {
  name: 'ApplicationsReview',
  components: {
    Button,
    Banner,
  },
  computed: mapState(['dashboard']),
  data() {
    return {
      errors: {
        employments: null,
        addresses: null,
      },
      applicant: null,
      addresses: [],
      employments: [],
    };
  },
  methods: {
    getAddressHistory() {
      this.errors.addresses = null;
      return axios
        .get(`${methods.getApiBaseUrl()}/applicants/${this.applicant.id}/addresses`)
        .then((r) => {
          if (r.data.success && r.data.data.items) {
            return r.data.data.items;
          }
          throw new Error(`Failed to load address history for ${this.applicant.id}`);
        })
        .catch((e) => {
          Bugsnag.notify(e);
          this.errors.addresses =
            'There was a problem loading your address history. If this problem continues, please get in touch';
        });
    },

    getEmploymentHistory() {
      this.errors.employments = null;
      return axios
        .get(`${methods.getApiBaseUrl()}/applicants/${this.applicant.id}/employments`)
        .then((r) => {
          if (r.data.success && r.data.data.items) {
            return r.data.data.items;
          }
          throw new Error(`Failed to load employment history for ${this.applicant.id}`);
        })
        .catch((e) => {
          Bugsnag.notify(e);
          this.errors.employments =
            'There was a problem loading your employment history. If this problem continues, please get in touch';
        });
    },
  },
  async mounted() {
    this.$emit('loading', true);
    this.applicant = this.dashboard.applicant;
    this.addresses = await this.getAddressHistory();
    this.employments = await this.getEmploymentHistory();
    this.$emit('loading', false);
  },
};
</script>
