<template>
  <div class="code-input flex gap-4 md:gap-6 mt-2">
    <div v-for="(digit, key) in digits" :key="key" class="w-full">
      <input
        :ref="'digit-' + key + '-' + _uid"
        :name="'digit-' + key + '-' + _uid"
        :autofocus="!key"
        type="number"
        min="0"
        max="9"
        @input="goToNextDigit"
        v-model="digit.value"
        class="mt-1 text-4xl text-center block w-full py-2 px-1 sm:px-3 border-b-2 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out no-number-controls font-bold h-20"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      digits: [],
    };
  },

  props: {
    length: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    // Push a value object into the digits array for the length of the code
    for (let i = 0; i < this.length; i++) {
      this.digits.push({
        value: '',
      });
    }
  },

  computed: {
    code() {
      let code = '';
      this.digits.forEach((digit) => {
        code += digit.value.toString();
      });

      return code;
    },
  },

  methods: {
    /**
     * Focuses on the first digit's input
     */
    focusOnFirstDigit() {
      this.$refs['digit-0-' + this._uid][0].focus();
    },

    /**
     * Focuses on the next digit field
     */
    goToNextDigit() {
      this.$emit('input', this.code);

      for (let [key, i] in this.digits) {
        let currentDigit = this.digits[key].value;
        if (currentDigit.length > 1) {
          this.digits[key].value = currentDigit.substr(0, 1);
        }
        if (!currentDigit.length) {
          this.$refs['digit-' + key + '-' + this._uid][0].focus();
          return;
        }
      }

      this.$emit('completed', this.code);
    },

    /**
     * Resets all digits to empty
     */
    resetDigits() {
      for (let key in this.digits) {
        this.digits[key].value = '';
      }
    },
  },
};
</script>
