var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page--login absolute h-screen w-full",class:{
    'login-overlay': !_vm.dealerStylesInjected,
    'dealer-login-bg-color': _vm.dealerStylesInjected,
  }},[_c('vue-headful',{attrs:{"title":"Secure Your Finance First | How It works | Magnitude Finance | Car Finance Broker UK"}}),_c('main',{staticClass:"flex items-center h-screen"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"rounded-t-xl flex items-center justify-center p-8 border-b border-b-gray-400 bg-white dealer-header-bg-color mx-auto w-11/12 sm:w-3/4 md:w-1/2 lg:max-w-lg",attrs:{"id":"logo"}},[(_vm.dealer.logo_url)?_c('img',{attrs:{"src":_vm.dealer.logo_url,"alt":""}}):_c('Logo')],1),_c('div',{staticClass:"bg-white shadow-lg rounded-b-xl mx-auto px-12 pb-16 pt-10 relative w-11/12 sm:w-3/4 md:w-1/2 lg:max-w-lg"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.codeSent),expression:"!codeSent"}],staticClass:"form send-code"},[_c('h1',{staticClass:"text-xl"},[_vm._v("Login")]),(_vm.mainError !== null)?_c('div',{staticClass:"bg-red-100 py-3 px-5 rounded-lg text-red-700 mb-5"},[_vm._v(" "+_vm._s(_vm.mainError)+" ")]):_vm._e(),_c('p',[_vm._v("Please enter the mobile phone number you used to create your application.")]),_c('ValidationObserver',{ref:"phoneObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var invalid = ref.invalid;
return [_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"rules":_vm.form.phone.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"}},[_vm._v("Mobile phone")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone.value),expression:"form.phone.value"}],ref:"phone",attrs:{"type":"tel","id":"phone","autocomplete":"tel-local","placeholder":"07712312312"},domProps:{"value":(_vm.form.phone.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendCode()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.phone, "value", $event.target.value)}}}),_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn-primary dealer-btn-primary w-full",class:{ 'opacity-50': invalid },attrs:{"disabled":invalid},on:{"click":function($event){return _vm.sendCode()}}},[_vm._v(" Send code ")])])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.codeSent),expression:"codeSent"}],staticClass:"form verify-code"},[_c('p',[_vm._v(" We've sent a 6 digit code to "),_c('strong',[_vm._v(_vm._s(_vm.form.phone.value))]),_vm._v(". Please enter it below. ")]),(_vm.mainError !== null)?_c('div',{staticClass:"bg-red-100 py-3 px-5 rounded-lg text-red-700 my-5"},[_vm._v(" "+_vm._s(_vm.mainError)+" ")]):_vm._e(),_c('ValidationObserver',{ref:"codeObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var invalid = ref.invalid;
return [_c('ValidationProvider',{attrs:{"rules":_vm.form.code.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('label',{attrs:{"for":"code"}},[_vm._v("6 digit code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.code.value),expression:"form.code.value"}],ref:"code",attrs:{"type":"tel","autocomplete":"off","required":"","id":"code","placeholder":"6 digit code"},domProps:{"value":(_vm.form.code.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.verifyCode()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.code, "value", $event.target.value)}}}),_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn-primary dealer-btn-primary w-full",class:{ 'opacity-50': invalid },attrs:{"disabled":invalid},on:{"click":function($event){return _vm.verifyCode()}}},[_vm._v(" Login ")])])]}}])})],1)]),(_vm.codeSent)?_c('div',{staticClass:"w-full text-center"},[_c('button',{staticClass:"text-white underline hover:dealer-text-primary mt-5 mx-auto",on:{"click":function($event){_vm.codeSent = false}}},[_vm._v(" Didn't receive a code? ")])]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }