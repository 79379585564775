import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/scss/app.scss';
import './vee-validate/vee-validate-rules';
import './vee-validate/vee-validate';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import VueEllipseProgress from 'vue-ellipse-progress';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import vmodal from 'vue-js-modal';
import VueScrollTo from 'vue-scrollto';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import commonMethods from './lib/methods';
import VueLazyload from 'vue-lazyload';
import vueHeadful from 'vue-headful';
import VueGtm from 'vue-gtm';
import VueCookies from 'vue-cookies';
import Toasted from 'vue-toasted';
import vueDebounce from 'vue-debounce';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueAppInsights from 'vue-application-insights';

try {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
  }
} catch (e) {
  console.error(e);
}

if (process.env.NODE_ENV === 'production') {
  // Application Insights
  Vue.use(VueAppInsights, {
    id: process.env.VUE_APP_INSIGHTS_KEY,
    router,
  });
}

Vue.use(VueLodash, { name: 'custom', lodash: lodash });
Vue.use(VueCookies);
Vue.component('vue-headful', vueHeadful);
Vue.use(VueLazyload);
Vue.use(VueScrollTo);
Vue.use(Toasted);
Vue.use(vueDebounce);
Vue.router = router;

// GTM
if (process.env.NODE_ENV === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-5N4KQRV',
    defer: true,
    enabled: false,
    debug: process.env.NODE_ENV !== 'production',
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  });
}

/**
 * Setup Bugsnag
 */
Bugsnag.start({
  // apiKey: "d264a08db0f59d088cc96531b0d93df7",
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  onError: function (event) {
    event.addMetadata('store', {
      journey: {
        applicant: {
          firstName: store.state.journey.applicant?.first_name ?? '?',
          surname: store.state.journey.applicant?.last_name ?? '?',
        },
        application: {
          step: store.state.journey.application.step ?? '?',
        },
      },
      dashboard: {
        authed: store.state.dashboard.authed ?? '?',
        application: {
          reference: store.state.dashboard.application?.reference ?? '?',
        },
        applicant: {
          id: store.state.dashboard.applicant?.id ?? '?',
        },
      },
    });
  },
});
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

/**
 * Setup axios
 */

axios.defaults.withCredentials = true;
window.axios = axios;

// Intercept all caught request errors
axios.interceptors.response.use(
  (r) => r,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Unauthenticate and redirect to login page
      store.commit('setUser', null);
      store.commit('setAuthed', false);
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login' });
      }
      return Promise.reject(error);
    }

    // Other error, redirect to error page with relevant details
    Bugsnag.notify(error);
    // TODO: Have removed generic handler because legitimate API validation errors are also
    // triggering it which is incorrect.
    // router.push({ name: 'error' })
    // alert('Something went wrong! We have been notified and are working to correct the issue.')
    return Promise.reject(error);
  }
);

// Fontawesome

Vue.component('font-awesome-icon', FontAwesomeIcon);
import { faStoreAlt, faMapMarkerAlt, faInfoSquare } from '@fortawesome/pro-duotone-svg-icons';

import {
  faEllipsisH,
  faCog,
  faHardHat,
  faCoins,
  faCircle,
  faInfo,
  faLock,
  faComment,
  faInfoCircle,
  faSms,
  faTimes,
  faUser,
  faCheck,
  faAngleRight,
  faAngleLeft,
  faChartPie,
  faClipboardCheck,
  faPhone,
  faPiggyBank,
  faPercentage,
  faCar,
  faTruckMonster,
  faEnvelope,
  faEnvelopeOpen,
  faHourglass,
  faClipboardList,
  faInbox,
  faBookReader,
  faHandshake,
  faSlidersH,
  faSort,
  faCalendarCheck,
  faLongArrowAltLeft,
  faChevronLeft,
  faChevronRight,
  faHeart,
  faEdit,
  faGasPump,
  faMale,
  faChair,
  faDoorOpen,
  faCogs,
  faBookOpen,
  faCamera,
  faPoundSign,
  faTrashAlt,
  faListAlt,
  faTag,
  faUserFriends,
  faCalculator,
  faCalendar,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTachometerAlt,
  faSteeringWheel,
  faRectangleWide,
  faTachometerAltFast,
  faPlusCircle,
  faCalculatorAlt,
  faCommentsAlt,
  faIdCard,
  faClipboardListCheck,
  faUserHeadset,
  faSearch,
  faMoneyCheck,
  faBadgeCheck,
  faBrowser,
  faBellOn,
  faTimesCircle,
  faCreditCardFront,
  faUserHardHat,
  faCalendarAlt,
  faPhoneAlt,
  faAnalytics,
  faCars,
  faFileCertificate,
  faCommentAltDots,
  faQuestionCircle,
  faMobile,
  faHome,
  faAngleDown,
  faImagePolaroid,
  faRoad,
  faListUl,
  faLampDesk,
  faThumbsUp,
  faSpinnerThird,
  faCreditCardBlank,
  faLongArrowRight,
  faBarcodeAlt,
  faLayerPlus,
  faCarGarage,
  faCarSide,
  faBallotCheck,
  faUsers,
  faPeopleArrows,
  faUserChart,
  faUserCowboy,
  faCashRegister,
  faStore,
  faAddressCard,
  faPalette,
  faAlignLeft,
  faSlidersVSquare,
  faSquare,
  faFileCheck,
  faQuestion,
  faCommentAlt,
  faSirenOn,
  faFileContract,
  faHandsHelping,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faFileContract,
  faSirenOn,
  faCarGarage,
  faLayerPlus,
  faBarcodeAlt,
  faPeopleArrows,
  faUserFriends,
  faTag,
  faListAlt,
  faLongArrowRight,
  faTrashAlt,
  faListUl,
  faLampDesk,
  faInfoSquare,
  faMapMarkerAlt,
  faImagePolaroid,
  faAngleDown,
  faStoreAlt,
  faMobile,
  faCommentAltDots,
  faFileCertificate,
  faCars,
  faAnalytics,
  faCircle,
  faInfo,
  faInfoCircle,
  faLock,
  faComment,
  faPhone,
  faSms,
  faTimes,
  faQuestionCircle,
  faUser,
  faCheck,
  faAngleRight,
  faAngleLeft,
  faChartPie,
  faClipboardCheck,
  faCalculator,
  faCoins,
  faHardHat,
  faPiggyBank,
  faTag,
  faEnvelope,
  faEnvelopeOpen,
  faPercentage,
  faCar,
  faTruckMonster,
  faCog,
  faCalendarAlt,
  faEllipsisH,
  faTachometerAlt,
  faRoad,
  faHourglass,
  faClipboardList,
  faInbox,
  faBookReader,
  faHandshake,
  faHome,
  faSlidersH,
  faSort,
  faCalendarCheck,
  faLongArrowAltLeft,
  faChevronLeft,
  faChevronRight,
  faHeart,
  faEdit,
  faGasPump,
  faMale,
  faChair,
  faPhoneAlt,
  faDoorOpen,
  faCogs,
  faBookOpen,
  faCarSide,
  faCamera,
  faPoundSign,
  faSteeringWheel,
  faRectangleWide,
  faTachometerAltFast,
  faPlusCircle,
  faCalculatorAlt,
  faCommentsAlt,
  faIdCard,
  faClipboardListCheck,
  faUserHeadset,
  faSearch,
  faMoneyCheck,
  faBadgeCheck,
  faBrowser,
  faBellOn,
  faTimesCircle,
  faCreditCardFront,
  faUserHardHat,
  faThumbsUp,
  faSpinnerThird,
  faCreditCardBlank,
  faCalendar,
  faBallotCheck,
  faStar,
  faBallotCheck,
  faUsers,
  faPeopleArrows,
  faUserChart,
  faUserCowboy,
  faCashRegister,
  faStore,
  faAddressCard,
  faPalette,
  faAlignLeft,
  faSlidersVSquare,
  faSquare,
  faFileCheck,
  faQuestion,
  faHandsHelping,
  faQuestion,
  faCommentAlt
);
dom.watch();

/**
 * Setup Vue
 */
Vue.config.productionTip = false;
Vue.use(VueEllipseProgress);
Vue.use(VueBreadcrumbs);
Vue.use(require('vue-moment'));
Vue.use(vmodal);
Vue.prototype.moment = moment;

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value;
  }
  value = value / 100;
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter('toGbp', function (value) {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter('thousands', function (value) {
  const formatter = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.mixin({
  methods: commonMethods,
});

/**
 * Start Vue
 */

axios
  .get(`${commonMethods.getApiRootUrl()}/sanctum/csrf-cookie`)
  .then((r) => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  })
  .catch((e) => {
    alert('Error communicating with the Magnitude system.');
  });
