<template>
  <div class="flex items-center justify-center">
    <button
      @click="drawer"
      aria-label="Open Menu"
      class="text-white dealer-nav-buttons w-10 h-10 flex items-center justify-center text-sm rounded-full focus:outline-none w-md ml-3"
      style="padding: 6px"
    >
      <svg
        class="fill-current"
        style="width: 16px; height: 16px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    </button>

    <transition
      enter-class="opacity-0"
      enter-active-class="ease-out transition duration-150"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="ease-in-out transition duration-150"
      leave-to-class="opacity-0"
    >
      <div
        @keydown.esc="isOpen = false"
        v-show="isOpen"
        class="z-10 fixed inset-0 transition-opacity"
      >
        <div
          @click="isOpen = false"
          class="absolute inset-0 bg-black opacity-75"
          tabindex="0"
        ></div>
      </div>
    </transition>

    <!-- Sidebar -->
    <aside
      class="transform top-0 right-0 w-70 bg-white fixed h-full z-30 overflow-auto ease-in-out transition-all duration-300 flex"
      :class="!isOpen ? 'translate-x-full' : '-translate-x-0'"
    >
      <div
        class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white dealer-bg-sidebar"
      >
        <div class="pb-5 flex-shrink-0 flex items-center px-4 border-b border-gray-500">
          <img
            :src="dealer.logo_url"
            class="mr-auto cursor-pointer object-contain h-12 w-auto"
            alt=""
            v-if="dealer.logo_url"
          />
          <Logo v-else></Logo>
        </div>

        <div @click="isOpen = false" class="absolute right-4 cursor-pointer text-white">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
        <div class="overflow-y-auto">
          <nav v-if="$route.meta.requiresAuth === true">
            <ul class="space-y-0">
              <li class="menu-item relative">
                <router-link
                  :to="{ name: 'home' }"
                  @click.native="closeSideBar()"
                  class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                >
                  Home
                </router-link>
              </li>
              <li class="menu-item relative">
                <div
                  @click="toggleAccordion()"
                  class="flex justify-between items-center cursor-pointer w-full border-b border-gray-500 group pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary hover:bg-flow-teal transition ease-in-out duration-300 hover:text-white"
                >
                  Get a quote

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 fill-white"
                    :class="{ 'rotate-180': isChildLinks, 'rotate-0': !isChildLinks }"
                    viewBox="0 0 448 512"
                  >
                    <path
                      d="M224 353.9l17-17L401 177l17-17L384 126.1l-17 17-143 143L81 143l-17-17L30.1 160l17 17L207 337l17 17z"
                    />
                  </svg>
                </div>

                <div v-show="isChildLinks" class="">
                  <router-link
                    :to="{ name: 'dashboard__calculator__add-vehicle-reg' }"
                    @click.native="closeSideBar()"
                    class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                  >
                    Add car by registration
                  </router-link>
                  <router-link
                    :to="{ name: 'dashboard__calculator__add-vehicle-manually' }"
                    @click.native="closeSideBar()"
                    class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                  >
                    Add car by make/model registration
                  </router-link>
                </div>
              </li>
              <li class="menu-item relative">
                <router-link
                  :to="{ name: 'dashboard__calculator__quotes' }"
                  @click.native="closeSideBar()"
                  class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                >
                  Apply now
                </router-link>
              </li>
              <li class="menu-item relative">
                <router-link
                  :to="{ name: 'dashboard__calculator__quotes' }"
                  @click.native="closeSideBar()"
                  class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                >
                  Saved vehicles
                </router-link>
              </li>
              <li class="menu-item relative">
                <router-link
                  :to="{ name: 'dashboard__calculator__quotes' }"
                  @click.native="closeSideBar()"
                  class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                >
                  FAQs
                </router-link>
              </li>
              <li class="menu-item relative">
                <router-link
                  :to="{ name: 'dashboard__messages' }"
                  @click.native="closeSideBar()"
                  class="cursor-pointer w-full border-b border-gray-500 group flex items-center pl-4 pr-2 py-3 text-base leading-6 font-medium focus:outline-none focus:dealer-bg-secondary transition ease-in-out duration-300 hover:bg-flow-teal hover:text-white"
                >
                  Messages
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  </div>
</template>
<script>
import Logo from '@/components/Layout/Logo.vue';
import Button from '@/components/Form/Button.vue';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex/dist/vuex.esm.browser';

export default {
  name: 'Sidebar',
  components: {
    Logo,
    Button,
  },
  watch: {
    $route(to) {
      // Using watch and manual assignment (as opposed to using computed) so when you click
      // on a different item in the menu you don't have to wait for the route to change.
      // This avoids the new focused menu item + current menu item being both highlighted
      this.currentRouteName = to.name;
    },
  },

  computed: {
    ...mapGetters({
      quotes: 'quotes',
    }),
    ...mapState(['dealer', 'dashboard']),
  },

  data() {
    return {
      isOpen: false,
      isChildLinks: false,
      currentRouteName: this.$route.name,
      dashboardlinks: [],
      currentChild: -1,
    };
  },
  methods: {
    toggleAccordion() {
      this.isChildLinks = !this.isChildLinks;
    },

    closeSideBar() {
      this.isOpen = false;
    },

    drawer() {
      this.isOpen = !this.isOpen;
    },

    dial() {
      if (this.dealer.phone) {
        window.location = 'tel:' + this.dealer.phone;
      }
      window.location = 'tel:0161 406 3994';
    },

    clickLink(link) {
      this.currentRouteName = link.route;
      this.isOpen = false;
      this.$router.push({ name: link.route });
    },

    toggleMenu: function (newChild) {
      if (newChild === this.currentChild) {
        this.currentChild = -1;
      } else {
        this.currentChild = newChild;
      }
    },

    show(name) {
      this.$modal.show(name);
    },
    hide() {
      this.$modal.hide(applyModal);
    },
  },

  mounted() {},
};
</script>
