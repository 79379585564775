<template>
  <div
    @click="
      $router.push({ name: 'dashboard__search__view', params: { vehicleId: vehicle.external_id } })
    "
    class="bg-white rounded-md overflow-hidden grid grid-cols-1 sm:grid-cols-3 hover:shadow-lg transition-shadow ease-in-out cursor-pointer"
  >
    <div
      class="overflow-hidden mr-px relative col-span-1 car-images flex w-full h-52 sm:h-34"
      :style="
        vehicle.images && vehicle.images.length
          ? 'background-image: url(' + vehicle.images[0] + ')'
          : ''
      "
    ></div>
    {{ vehicle.images }}
    <div class="col-span-2">
      <div class="p-4">
        <h4 class="mb-0 flex space-x-2 items-center">
          <span v-if="exists(vehicle.make)">{{ vehicle.make }}</span>
          <span v-if="exists(vehicle.model)"> | </span>
          <span v-if="exists(vehicle.model)">{{ vehicle.model }} </span>
          <span v-if="exists(vehicle.transmission)"> | </span>
          <span v-if="exists(vehicle.transmission)">{{ vehicle.transmission }}</span>
          <span v-if="exists(vehicle.colour)"> | </span>
          <span v-if="exists(vehicle.colour)">{{ vehicle.colour }}</span>
        </h4>
      </div>
      <!-- <template v-if="vehicle.quote_quote_results.submitted">

            </template> -->
      <div v-if="vehicle.quote.quote_results.length" class="inline-flex border-t-1 w-full">
        <div v-for="(result, o) in vehicle.quote.quote_results" :key="o" class="price p-4 w-full">
          <div class="font-bold dealer-text-secondary">{{ result.type }}</div>
          <div class="font-bold text-2xl tracking-tight">
            &pound;{{ getMonthlyPrice(result.monthly_payment)
            }}<span class="text-sm font-lighter"> p/m</span>
          </div>
        </div>
      </div>
      <div class="p-4" v-else>
        Sorry, we couldn't find any personalised deals for this vehicle. Try
        <span class="underline">adjusting your quote</span> and try again.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleted: false,
    };
  },

  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getMonthlyPrice(amount) {
      return parseFloat(amount / 100).toLocaleString();
    },

    exists(property) {
      return typeof property !== 'undefined' && property !== null && property.trim() !== '';
    },
  },
};
</script>
