<template>
  <section class="step personal-details flex-1">
    <template v-if="journey.applicant.verified">
      <h2>Already verified</h2>
      <h5 class="grey">
        Your phone number ({{ journey.applicant.mobile_phone }}) has already been verified.
      </h5>
      <button
        @click="skip()"
        class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary"
      >
        Continue
      </button>
    </template>
    <template v-else>
      <h2 class="dealer-text-primary">Now enter the code we just sent you</h2>
      <h5 class="light grey">
        We've sent a 6 digit code to <strong>{{ journey.applicant.mobile_phone }}</strong
        >, please enter it below.
      </h5>

      <section class="form">
        <!-- Phone verification code field -->
        <div class="field">
          <FormLabel>Code</FormLabel>
          <CodeInput :length="6" @completed="verifyCode($event)" />
          <div v-if="invalidCode" class="error-message">That code is incorrect</div>
        </div>

        <div class="field">
          <!-- Resend code -->
          <div class="mt-10 flex items-center gap-4">
            <div
              class="underline cursor-pointer hover:no-underline hover:dealer-text-secondary"
              @click="$router.push({ name: 'personal-details' })"
            >
              Wrong number?
            </div>
          </div>
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import CodeInput from '../Form/CodeInput.vue';
import FormLabel from '@/components/Form/FormLabel.vue';
import Api from '@/lib/Api';
import Bugsnag from '@bugsnag/js';

export default {
  components: {
    CodeInput,
    FormLabel,
  },

  data() {
    return {
      invalidCode: false,
    };
  },

  computed: mapState(['dealer', 'journey']),

  methods: {
    ...Api,

    async verifyCode(code) {
      this.invalidCode = false;
      this.$emit('loading', true, 'Verifying...');

      // Check the code
      axios
        .post(this.getApiBaseUrl() + '/phone-verification/check-code', {
          phone: this.journey.applicant.mobile_phone,
          code: code,
          dealer_slug: this.$store.state.dealer.slug || null,
        })
        .then(async (r) => {
          // If it was correct...
          if (r.data.success && r.data.data?.verified === true) {
            // Decide whether create a new applicant or update an existing one
            let applicantProcessed;

            if (this.journey.applicant.id) {
              // Update one
              applicantProcessed = await this.updateApplicant(
                this.journey.applicant.id,
                this.journey.application.reference,
                this.journey.applicant
              );
            } else {
              // Create one
              applicantProcessed = await this.createApplicant(
                this.journey.application.reference,
                this.journey.applicant
              );
            }

            if (applicantProcessed) {
              // Mark them as verified (phone has been verified)
              this.$store.commit('setApplicant', { verified: true });

              let dealerName = this.dealer.name;

              if (this.dealer.name) {
                // Attach dealer name to push event
                await this.apiPushEvent(
                  this.journey.application.reference,
                  dealerName + '_action_phone_verified',
                  dealerName + '_feed_journey',
                  dealerName + '_phone_verified',
                  dealerName + '_verified'
                );
              } else {
                // Standard push event
                await this.apiPushEvent(
                  this.journey.application.reference,
                  'action_phone_verified',
                  'feed_journey',
                  'phone_verified',
                  'verified'
                );
              }

              this.$emit('loading', false);
              return await this.$router.push({ name: 'about-you' });
            }

            this.$emit('loading', false);
            console.error('Problem processing applicant!', applicantProcessed);
            return;
          }

          this.$emit('loading', false);
          this.invalidCode = true;
        })
        .catch((e) => {
          Bugsnag.notify(e);
          this.$emit('loading', false);
          this.toast('Error while verifying your code', 'error');
        });
    },

    updateApplicant(applicantId, applicationReference, applicantData) {
      return axios
        .patch(
          this.getApiBaseUrl() +
            '/applications/' +
            applicationReference +
            '/applicants/' +
            applicantId,
          {
            applicant: applicantData,
          }
        )
        .then((r) => {
          return !!r.data.success;
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },

    createApplicant(applicationReference, applicantData) {
      return axios
        .post(this.getApiBaseUrl() + '/applications/' + applicationReference + '/applicants', {
          applicant: applicantData,
        })
        .then((r) => {
          // Set the applicant's ID in the store
          if (r.data.success && r.data.data.applicant.id) {
            this.$store.commit('setApplicant', {
              id: r.data.data.applicant.id,
            });
            return true;
          }

          return false;
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },

    async skip() {
      return await this.$router.push({ name: 'about-you' });
    },
  },

  async mounted() {
    this.$emit('set-step', 2);
    await this.apiPushEvent(
      this.journey.application.reference,
      'step_phone_verification',
      'feed_journey',
      'step',
      'phone_verification'
    );
  },
};
</script>
