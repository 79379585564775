<template>
  <!-- Activity Feed -->
  <div class="mt-6 flow-root">
    <ul role="list" class="">
      <!-- Completed application -->
      <li>
        <div class="relative pb-8">
          <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span
                class="text-flow-blue dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center"
              >
                <i class="text-md fas fa-check text-white"></i>
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1 flex justify-between space-x-4">
              <div>
                <p class="text-lg text-flow-blue dealer-text-secondary">Completed registration</p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- Applied for finance -->
      <li>
        <div class="relative pb-8">
          <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="{
                  'dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center':
                    dashboard.application.completed,
                  'text-white rounded-full bg-gray-300 w-8 h-8 flex justify-center items-center':
                    !dashboard.application.completed,
                }"
              >
                <i class="text-md fas fa-check text-white"></i>
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1 flex justify-between space-x-4">
              <div>
                <p class="text-lg text-flow-blue dealer-text-secondary">Applied for finance</p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- Processing... -->
      <li>
        <div class="relative pb-8">
          <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="{
                  'text-flow-blue dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center':
                    dashboard.application.mows_submitted,
                  'text-white rounded-full bg-gray-300 w-8 h-8 flex justify-center items-center':
                    !dashboard.application.mows_submitted,
                }"
              >
                <i
                  v-if="
                    lastStatus() == 'Converted' ||
                    lastStatus() == 'Declined' ||
                    lastStatus() == 'User cancelled'
                  "
                  class="w-5 h-5 fas fa-check text-white"
                ></i>
                <i v-else class="text-md fas fa-hourglass"></i>
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1 flex justify-between space-x-4">
              <div>
                <p class="text-lg text-flow-blue dealer-text-secondary">
                  <template v-if="lastStatus()">
                    {{ lastStatus() }}
                  </template>
                  <template v-else> In progress </template>
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- Final step -->
      <li v-if="lastStatus() == 'Declined' || lastStatus() == 'User cancelled'">
        <div class="relative pb-8">
          <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span
                class="text-flow-blue dealer-text-secondary rounded-full bg-black w-8 h-8 flex justify-center items-center"
              >
                <i class="text-md fas fa-times text-white"></i>
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1 flex justify-between space-x-4">
              <div>
                <p class="text-lg text-flow-blue dealer-text-secondary">
                  <template v-if="hasStatus('Declined')"> Application declined </template>
                  <template v-else> Application cancelled </template>
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li v-else>
        <div class="relative">
          <span class="absolute top-0 left-4 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="{
                  'text-flow-blue dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center':
                    lastStatus() == 'Converted',
                  'text-white rounded-full bg-gray-300 w-8 h-8 flex justify-center items-center':
                    lastStatus() != 'Converted',
                }"
              >
                <i class="text-md fas fa-check text-white"></i>
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1 flex justify-between space-x-4">
              <div>
                <p class="text-lg text-flow-blue dealer-text-secondary mb-0">Completed</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState(['dashboard']),

  methods: {
    hasStatus(status) {
      if (
        this.dashboard.application.statuses &&
        this.dashboard.application.statuses.filter((stat) => stat.internal_stage === status).length
      ) {
        return true;
      }
      return false;
    },

    lastStatus() {
      if (this.dashboard.application.statuses && this.dashboard.application.statuses.length) {
        const statuses = this.dashboard.application.statuses;
        return statuses[statuses.length - 1].internal_stage;
      }
      return false;
    },
  },
};
</script>
