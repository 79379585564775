<template>
  <section class="step personal-details flex-1">
    <h2>Tell us a little about you</h2>
    <h5 class="grey">Get your credit score and personalised APR rate in just 60 seconds.</h5>
    <h5 class="grey">This soft search won't affect your credit score.</h5>
    <section class="form">
      <ValidationObserver v-slot="{ invalid }" tag="div">
        <!-- Title field -->
        <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
          <div class="field">
            <FormLabel forInput="">What is your title?</FormLabel>
            <RadioButtons
              :max-options="6"
              :rules="form.title.rules"
              :options="form.title.options"
              v-model="form.title.value"
              cols="2"
            />
          </div>
        </ValidationProvider>

        <!-- First name field -->
        <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
          <div class="field">
            <FormLabel for-input="field-first-name" :tooltip="form.first_name.tooltip">
              First name
            </FormLabel>
            <input
              type="text"
              v-model="form.first_name.value"
              id="field-first-name"
              tabindex="20"
              class="capitalize"
            />
            <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>

        <!-- Surname field -->
        <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
          <div class="field">
            <FormLabel for-input="field-surname"> Surname </FormLabel>
            <input
              type="text"
              v-model="form.surname.value"
              id="field-surname"
              tabindex="30"
              class="capitalize"
            />
            <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
          </div>
        </ValidationProvider>

        <!-- Phone number field -->
        <ValidationProvider
          rules="required|mobile_phone|mobile_phone_length:11"
          v-slot="{ errors }"
          tag="div"
        >
          <div class="field">
            <FormLabel for-input="field-phone" :tooltip="form.mobile_phone.tooltip">
              Mobile number
            </FormLabel>
            <template v-if="journey.applicant.verified">
              <input
                type="tel"
                disabled
                :value="form.mobile_phone.value"
                id="field-phone-verified"
                tabindex="40"
              />
              <p class="mt-3">
                Your phone number has already been verified. To change your number, please
                <span @click="resetApplication()" class="underline cursor-pointer"
                  >start your application again</span
                >.
              </p>
            </template>
            <template v-else>
              <input
                type="tel"
                autocomplete="tel"
                v-model="form.mobile_phone.value"
                id="field-phone"
                tabindex="40"
                @keyup="
                  form.mobile_phone.exists = form.mobile_phone.value === form.mobile_phone.oldValue
                "
              />
              <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
            </template>
          </div>
        </ValidationProvider>

        <!-- Number already registered -->
        <div v-if="form.mobile_phone.exists" class="bg-red-500 text-white p-6 mb-5">
          <h4 class="font-bold text-xl mb-2 text-white">Number already exists</h4>
          <p class="mb-0">
            If you are a returning customer
            <a
              href="javascript:void(0)"
              @click="$router.push({ name: 'login' })"
              class="underline font-bold"
              >click here</a
            >
            to login or if you wish to use this number to start a new application, please contact
            us.
          </p>
        </div>

        <div class="form-actions flex">
          <button
            v-if="form.mobile_phone.exists"
            class="dealer-btn-primary ml-auto"
            @click="$router.push({ name: 'login' })"
          >
            Login
          </button>
          <button
            v-else
            class="dealer-btn-primary ml-auto"
            @click="submit"
            :disabled="invalid"
            :class="{ 'opacity-50': invalid }"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import FormLabel from '@/components/Form/FormLabel';
import Api from '@/lib/Api';
import { getGoogleCid } from '@/helpers';
import Bugsnag from '@bugsnag/js';

export default {
  data() {
    return {
      form: {
        title: {
          options: [
            {
              label: 'Mr',
              value: 'Mr',
            },
            {
              label: 'Mrs',
              value: 'Mrs',
            },
            {
              label: 'Miss',
              value: 'Miss',
            },
            {
              label: 'Ms',
              value: 'Ms',
            },
            {
              label: 'Dr',
              value: 'Dr',
            },
            {
              label: 'Prof',
              value: 'Prof',
            },
          ],
          value: null,
          rules: 'required',
        },
        first_name: {
          value: '',
          tooltip: {
            title: 'Your do you need your first name?',
            body: 'Your first name as it appears on your driving license',
          },
        },
        surname: {
          value: '',
        },
        mobile_phone: {
          value: '',
          tooltip: {
            title: 'Why do we need your phone number?',
            body: "We'll use your phone number to inform you of any changes during your application. All our finance partners require your number in order to process an application.",
          },
          exists: false,
          oldValue: null,
        },
      },
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    FormLabel,
  },

  computed: mapState(['dealer', 'journey']),

  methods: {
    ...Api,

    /**
     * Stores the applicant data in Vuex, saves a new application in the API and sends a
     * verification code to the phone number provided. If all succeeds, proceed to next step.
     */
    async submit() {
      this.form.mobile_phone.exists = false;
      this.form.mobile_phone.oldValue = null;

      // Check if the phone number has already been registered
      const applicantExists = await axios
        .post(`${this.getApiBaseUrl()}/v1/applicants/exists.json`, {
          mobile_phone: this.form.mobile_phone.value,
          partner_dealer_id: this.dealer.id ?? null,
        })
        .then((r) => {
          this.$emit('loading', false);
          return r.data.data.exists;
        });

      if (applicantExists) {
        this.form.mobile_phone.exists = true;
        this.form.mobile_phone.oldValue = this.form.mobile_phone.value;
        this.$emit('loading', false);
        return;
      }

      this.$emit('loading', true, 'Sending your code...');

      // Save applicant to Vuex
      await this.$store.commit('setApplicant', {
        title: this.form.title.value,
        first_name: this.form.first_name.value.charAt(0) + this.form.first_name.value.slice(1),
        surname: this.form.surname.value.charAt(0) + this.form.surname.value.slice(1),
        mobile_phone: this.form.mobile_phone.value,
      });

      // Only create an application if they're a new customer
      if (typeof this.journey.application.reference == 'undefined') {
        await this.createApplication();
        await this.storeMarketingPixel();

        let dealerName = this.dealer.name;

        if (this.dealer.name) {
          // Attach dealer name to push event
          await this.apiPushEvent(
            this.journey.application.reference,
            dealerName + '_step_personal_details',
            dealerName + '_feed_journey',
            dealerName + '_step',
            dealerName + '_personal_details'
          );
        } else {
          // Standard push event
          await this.apiPushEvent(
            this.journey.application.reference,
            'step_personal_details',
            'feed_journey',
            'step',
            'personal_details'
          );
        }
      }

      // Store all GET params as app. properties now that application has been created
      await this.storeApplicationProperties();

      // Send a validation code to the phone number
      // TODO: Potentially need to update where we get the phone number from (API)
      const codeSent = await this.sendVerificationCode();

      if (codeSent) {
        // Done, proceed to next step
        this.$emit('loading', false);
        return await this.$router.push({ name: 'phone-verification' });
      }

      this.$emit('loading', false);
    },

    /**
     * Sends a verification code to the phone number provided
     */
    sendVerificationCode() {
      return axios
        .post(this.getApiBaseUrl() + '/phone-verification/send-code', {
          phone: this.$store.state.journey.applicant.mobile_phone,
          dealer_slug: this.$store.state.dealer.slug || null,
        })
        .then((r) => {
          return r.data.success;
        })
        .catch((e) => {
          this.toast('Failed to send your code', 'error');
          Bugsnag.notify(e);
        });
    },

    storeMarketingPixel() {
      if (
        this.journey.application.reference === null ||
        typeof this.journey.application.reference === 'undefined'
      ) {
        // TODO: Bugsnag
        return false;
      }

      return axios
        .post(
          `${this.getApiBaseUrl()}/applications/${
            this.journey.application.reference
          }/marketing-pixel`,
          this.journey.marketingPixel
        )
        .then((r) => {
          if (r.data.success) {
            return true;
          }
          Bugsnag.notify(new Error('Failed to save marketing pixel (API error)'));
          return false;
        })
        .catch((e) => {
          Bugsnag.notify(e);
          return false;
        });
    },

    /**
     * Creates a new application and stores it in the Vuex store
     */
    createApplication() {
      // Set the journey type
      let data = {
        application: {
          journey_type: 'feed',
          ga_cid: getGoogleCid(),
        },
      };

      // If we have a dealer stored locally, attach it to the application
      if (this.dealer.id && !isNaN(this.dealer.id)) {
        data.application = {
          partner_dealer_id: this.dealer.id,
        };
      } else {
        // Only check if there's a partner if there's no dealer
        if (this.journey.partner?.id && !isNaN(this.journey.partner.id)) {
          data.application = {
            partner_id: this.journey.partner.id,
          };
        }
      }

      return axios
        .post(this.getApiBaseUrl() + '/applications', data)
        .then(async (r) => {
          if (r.data.success) {
            // Save the application to Vuex
            this.$store.commit('setApplication', r.data.data.application);

            return true;
          } else {
            // TODO: Big error!
            throw new Error('Critical: could not save application (API)');
          }
        })
        .catch((e) => {
          console.error('⚠ TODO: Error handling: ' + e);
        });
    },

    /**
     * Send all stored application properties to the API.
     * @returns {Promise<boolean|boolean>}
     */
    async storeApplicationProperties() {
      if (
        !this.$store.state.journey.applicationProperties.length ||
        this.journey.application.reference === null ||
        typeof this.journey.application.reference === 'undefined'
      ) {
        return false;
      }

      return axios
        .post(
          `${this.getApiBaseUrl()}/applications/${
            this.journey.application.reference
          }/application-properties`,
          {
            properties: this.$store.state.journey.applicationProperties,
          }
        )
        .then(() => {
          // Clear them locally
          this.$store.dispatch('resetApplicationProperties');

          return true;
        })
        .catch((e) => {
          Bugsnag.notify(e);
          return false;
        });
    },

    restore() {
      const applicant = this.journey.applicant;

      // Title
      if (applicant.title) {
        this.form.title.value = applicant.title;
      }

      // First name
      if (applicant.first_name) {
        this.form.first_name.value = applicant.first_name;
      }

      // Surname
      if (applicant.surname) {
        this.form.surname.value = applicant.surname;
      }

      // Mobile phone
      if (applicant.mobile_phone) {
        this.form.mobile_phone.value = applicant.mobile_phone;
      }
    },

    resetApplication() {
      if (confirm('Are you sure? All your current data will be lost.')) {
        this.$store.dispatch('resetJourney');
        return location.reload();
      }
    },
  },

  async mounted() {
    this.$emit('set-step', 1);

    // Restore values from Vuex if we have them
    this.restore();

    // Store any marketing pixel data we have
    const marketingPixelData = {
      fbp: this.$cookies.get('_fbp') ?? null,
      fbc: this.$cookies.get('_fbc') ?? null,
      google_client_id: getGoogleCid(),
      user_ip: (await this.apiGetUserIp()) ?? null,
      user_agent: navigator && navigator.userAgent ? navigator.userAgent : null,
    };

    await this.$store.commit('setMarketingPixel', marketingPixelData);
  },
};
</script>
