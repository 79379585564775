<template>
  <section class="journey">
    <nav class="dealer-header-bg-color w-full" id="navbar" style="z-index: 3">
      <div
        class="max-w-7xl justify-between flex flex-shrink-0 mx-auto text-white py-4 px-4 sm:px-6 xl:px-0 w-full"
      >
        <div>
          <a :href="dealer.url" class="logo flex justify-center" v-if="dealer.logo_url">
            <img :src="dealer.logo_url" class="mr-auto cursor-pointer" alt="" />
          </a>
          <div class="logo flex justify-center" @click="$router.push({ name: 'home-page' })" v-else>
            <Logo :blue="true" class="mr-auto cursor-pointer"></Logo>
          </div>

          <div v-if="dealer.subtitle" class="powered-by text-sm flex-start">
            {{ dealer.subtitle }}
          </div>
        </div>
      </div>
    </nav>

    <PartnerHeader v-if="displayPartnerHeader" />

    <ProgressBar :progress="journey.progress ? journey.progress : 0" :total="6" />

    <main class="">
      <div class="max-w-xl mx-auto w-full px-6 lg:px-8 py-12">
        <router-view @loading="loading" @set-step="setStep" />
      </div>
    </main>
    <Footer />
  </section>
</template>

<script>
import Logo from '@/components/Layout/Logo';
import Footer from '@/components/Layout/Footer';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import ProgressBar from '@/components/Ui/ProgressBar';
import Breadcrumbs from '@/components/Ui/Breadcrumbs';
import Api from '@/lib/Api';
import PartnerHeader from '@/components/Layout/PartnerHeader';
import { partnerData } from '@/config/partners';

export default {
  components: {
    Logo,
    ProgressBar,
    Footer,
    Breadcrumbs,
    PartnerHeader,
  },

  data() {
    return {
      displayPartnerHeader: false,
    };
  },

  computed: mapState(['dealer', 'journey', 'dashboard']),

  methods: {
    ...Api,

    loading(state = false, text = 'One moment...') {
      this.$emit('loading', state, text);
    },

    /**
     * Set's the current step
     * @param step
     */
    setStep(step) {
      let adjustedStep = step - 1;
      this.$store.commit('setJourneyProgress', adjustedStep);

      // Update the API
      if (this.journey.application.reference && this.journey.applicant.id) {
        axios
          .post(
            `${this.getApiBaseUrl()}/applications/${
              this.journey.application.reference
            }/update-current-step`,
            {
              applicantId: this.journey.applicant.id,
              step: this.$route.name,
            }
          )
          .then((r) => r.data.success)
          .catch((e) => console.error(e));
      }
    },

    async loadPartner() {
      if (this.journey.partner?.id) {
        try {
          const partner = await this.apiGetPartner(this.journey.partner.id);

          if (partner.cockpit_collection_id) {
            partner.branding = partnerData[partner.cockpit_collection_id] ?? null;
          }

          await this.$store.commit('setPartner', partner);
          this.displayPartnerHeader = partner.branding?.enable_branding || false;
        } catch (e) {
          await this.$store.commit('setPartner', null);
        }
      }
    },
  },

  async mounted() {
    // If logged in, redirect to dashboard
    if (this.dashboard.authed) {
      return await this.$router.push({ name: 'applications__manage' });
    }

    // Inject dealer styles if available
    const dealerStylesInjected = this.injectDealerStyles();

    // If dealer styling wasn't injected, load a partner (if provided)
    if (!dealerStylesInjected) {
      await this.loadPartner();
    }

    // Update favicon if set
    if (this.dealer.favicon_url) {
      let favicon = document.querySelector('link[rel="icon"]');
      return (favicon.href = this.dealer.favicon_url);
    }
  },
};
</script>
