import moment from 'moment';

export default class QuoteHelper {
  constructor(
    AnnualMileage = 0,
    CashPrice = 0,
    Term = 0,
    QuoterAPR = 0,
    PartExchange = 0,
    OutstandingSettlement = 0,
    DepositCash = 0,
    CurrentMileage = 0,
    AllowVariableApr = false,
    VehicleFirstRegistered = null,
    EnteredManually = false
  ) {
    this.AnnualMileage = AnnualMileage;
    this.CashPrice = CashPrice;
    this.Term = Term;
    this.QuoterAPR = QuoterAPR;
    this.PartExchange = PartExchange;
    this.OutstandingSettlement = OutstandingSettlement;
    this.DepositCash = DepositCash;
    this.CurrentMileage = CurrentMileage;
    this.AllowVariableApr = AllowVariableApr;
    this.VehicleEnteredManually = EnteredManually;

    if (VehicleFirstRegistered === null) {
      this.VehicleFirstRegistered = new Date().toISOString();
    } else {
      this.VehicleFirstRegistered = VehicleFirstRegistered;
    }

    this.Now = moment();
    this.tips = [];

    this.settings = {
      VEHICLE_TOO_OLD: {
        title: 'Try a newer vehicle',
        retryRoute: this.VehicleEnteredManually
          ? 'dashboard__calculator__add-vehicle-manually'
          : 'dashboard__calculator__add-vehicle-reg',
        buttonLabel: 'Change vehicle',
      },
      TERM_TOO_LONG: {
        title: 'Try a shorter term',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Change term',
      },
      TERM_TOO_SHORT: {
        title: 'Try a longer term',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Change term',
      },
      BALANCE_TOO_LOW: {
        title: 'Try borrowing more',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Increase balance',
      },
      BALANCE_TOO_HIGH: {
        title: 'Try borrowing less',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Reduce balance',
      },
      APR_TOO_HIGH: {
        title: 'Try a lower APR',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Change APR',
      },
      MILEAGE_TOO_LOW: {
        title: 'Try a vehicle with more miles',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Change mileage',
      },
      MILEAGE_TOO_HIGH: {
        title: 'Try a vehicle with fewer miles',
        retryRoute: 'dashboard__calculator__figures',
        buttonLabel: 'Change mileage',
      },
      ALLOW_NEW_VEHICLES: {
        title: 'Is this vehicle new?',
        retryRoute: this.VehicleEnteredManually
          ? 'dashboard__calculator__add-vehicle-manually'
          : 'dashboard__calculator__add-vehicle-reg',
        buttonLabel: 'Change vehicle',
      },
      REJECT_NEW_VEHICLES: {
        title: 'Try a used vehicle',
        retryRoute: this.VehicleEnteredManually
          ? 'dashboard__calculator__add-vehicle-manually'
          : 'dashboard__calculator__add-vehicle-reg',
        buttonLabel: 'Change vehicle',
      },
    };
  }

  set setAnnualMileage(AnnualMileage) {
    this.AnnualMileage = AnnualMileage;
  }
  get getAnnualMileage() {
    return this.AnnualMileage;
  }

  set setCashPrice(CashPrice) {
    this.CashPrice = CashPrice;
  }
  get getCashPrice() {
    return this.CashPrice;
  }

  set setTerm(Term) {
    this.Term = Term;
  }
  get getTerm() {
    return this.Term;
  }

  set setQuoterAPR(QuoterAPR) {
    this.QuoterAPR = QuoterAPR;
  }
  get getQuoterAPR() {
    return this.QuoterAPR;
  }

  set setPartExchange(PartExchange) {
    this.PartExchange = PartExchange;
  }
  get getPartExchange() {
    return this.PartExchange;
  }

  set setOutstandingSettlement(OutstandingSettlement) {
    this.OutstandingSettlement = OutstandingSettlement;
  }
  get getOutstandingSettlement() {
    return this.OutstandingSettlement;
  }

  set setDepositCash(DepositCash) {
    this.DepositCash = DepositCash;
  }
  get getDepositCash() {
    return this.DepositCash;
  }

  set setCurrentMileage(CurrentMileage) {
    this.CurrentMileage = CurrentMileage;
  }
  get getCurrentMileage() {
    return this.CurrentMileage;
  }

  set setVehicleFirstRegistered(VehicleFirstRegistered) {
    this.VehicleFirstRegistered = VehicleFirstRegistered;
  }
  get getVehicleFirstRegistered() {
    return this.VehicleFirstRegistered;
  }

  getTips() {
    this.clearTips();

    // 6.9% APR
    if (this.QuoterAPR === 9.9) {
      this.validateBalance(4000, 100000);
      this.validateVehicleAgeEndTerm(12);
      this.validateVehicleMileage(1, 80000);
      this.validateAllowNewVehicles(false);
    }

    // 9.9% APR
    if (this.QuoterAPR === 9.9) {
      this.validateTerm(36, 60);
      this.validateBalance(2500, 25000);
      this.validateVehicleMileage(1, 100000);
      this.validateAllowNewVehicles(true);
    }

    // 16.9% APR
    if (this.QuoterAPR === 16.9) {
      this.validateTerm(36, 60);
      this.validateBalance(2500, 25000);
      this.validateVehicleMileage(1, 80000);
      this.validateAllowNewVehicles(false);
    }

    // 23.9% APR
    if (this.QuoterAPR === 23.9) {
      this.validateTerm(36, 60);
      this.validateBalance(2500, 20000);
      this.validateVehicleMileage(1, 100000);
      this.validateAllowNewVehicles(false);
    }

    // 29.9% APR
    if (this.QuoterAPR === 29.9) {
      this.validateTerm(36, 60);
      this.validateBalance(2500, 15000);
      this.validateVehicleMileage(1, 100000);
      this.validateAllowNewVehicles(false);
    }

    return this.tips;
  }

  validateTerm(min, max) {
    if (this.Term < min) {
      this.addTip(
        this.settings.TERM_TOO_SHORT,
        'Try increasing your term to at least ' + min + ' months.'
      );
    }
    if (this.Term > max) {
      this.addTip(this.settings.TERM_TOO_LONG, 'Try reducing your term to ' + max + ' months.');
    }
    return;
  }

  validateBalance(min, max) {
    const balance = this.getBalance();
    const formattedBalance = '&pound;' + new Intl.NumberFormat('en-GB').format(balance);

    if (balance < min) {
      const formattedMin = '&pound;' + new Intl.NumberFormat('en-GB').format(min);
      this.addTip(
        this.settings.BALANCE_TOO_LOW,
        'Try increasing your balance from ' +
          formattedBalance +
          ' to at least ' +
          formattedMin +
          '.'
      );
    }
    if (this.getBalance() > max) {
      const formattedMax = '&pound;' + new Intl.NumberFormat('en-GB').format(max);
      this.addTip(
        this.settings.BALANCE_TOO_HIGH,
        'Try reducing your balance from ' +
          formattedBalance +
          ' to ' +
          formattedMax +
          '. You can do this by choosing a cheaper vehicle, increasing your deposit, part exchanging your vehicle and/or reducing your outstanding settlement.'
      );

      if (this.AllowVariableApr && this.getQuoterAPR > 9.9) {
        this.addTip(
          this.settings.APR_TOO_HIGH,
          'Try lowering your estimated APR. Remember that this is based off your credit score which will be determined automatically if you choose to apply for finance.'
        );
      }
    }
    return;
  }

  validateVehicleAgeEndTerm(maxYears) {
    const age = this.getVehicleAgeEnd();

    if (age > maxYears) {
      this.addTip(
        this.settings.VEHICLE_TOO_OLD,
        'The age of your vehicle at the end of your term is quite high (~' +
          Math.ceil(age) +
          ' years old). Try a newer vehicle which will not be older than ' +
          maxYears +
          ' years old at the end of your agreement.'
      );
    }
    return;
  }

  validateVehicleAge(maxYears) {
    const age = this.getVehicleAge();

    if (age > maxYears) {
      this.addTip(
        this.settings.VEHICLE_TOO_OLD,
        'The age of your vehicle is quite high (~' +
          Math.ceil(age) +
          ' years old). Try a newer vehicle which is no older than ' +
          maxYears +
          ' years old.'
      );
    }
    return;
  }

  validateAllowNewVehicles(isAllowed = false) {
    const age = this.getVehicleAge();

    if (age == 0) {
      if (isAllowed) {
        this.addTip(
          this.settings.ALLOW_NEW_VEHICLES,
          'Please chat with us to get quotes for a new or unregistered vehicle. Alternatively you can select a used vehicle which is already registered.'
        );
      } else {
        this.addTip(
          this.settings.REJECT_NEW_VEHICLES,
          'Try selecting a used vehicle which has already been registered.'
        );
      }
    }
    return;
  }

  validateVehicleMileage(min, max) {
    const mileage = this.CurrentMileage;

    if (mileage < min) {
      this.addTip(
        this.settings.MILEAGE_TOO_LOW,
        'The mileage on your selected vehicle is too low. Try one with more miles.'
      );
    }
    if (mileage > max) {
      this.addTip(
        this.settings.MILEAGE_TOO_HIGH,
        `The mileage on this vehicle is quite high. Quotes are normally available where the vehicle's current mileage is less than ${max} miles.`
      );
    }
    return;
  }

  addTip(template, message) {
    this.tips.push({
      ...template,
      message: message,
    });
    return;
  }

  clearTips() {
    this.tips = [];
  }

  getVehicleAge() {
    const now = moment();
    const firstReg = moment(this.VehicleFirstRegistered);
    return now.diff(firstReg, 'years', true);

    return {
      days: now.diff(firstReg, 'days'),
      months: now.diff(firstReg, 'months'),
      years: now.diff(firstReg, 'years', true),
    };
  }

  getVehicleAgeEnd() {
    const endOfAgreement = moment().add(this.Term, 'months');
    const firstReg = moment(this.VehicleFirstRegistered);
    return endOfAgreement.diff(firstReg, 'years', true);

    return {
      days: endOfAgreement.diff(firstReg, 'days'),
      months: endOfAgreement.diff(firstReg, 'months'),
      years: endOfAgreement.diff(firstReg, 'years', true),
    };
  }

  getBalance() {
    return (
      parseFloat(this.CashPrice) +
      parseFloat(this.OutstandingSettlement) -
      parseFloat(this.DepositCash) -
      parseFloat(this.PartExchange)
    );
  }

  getTerm() {
    return this.Term;
  }
}
