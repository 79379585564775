<template>
  <section class="dashboard">
    <Header />
    <CreditBanner :credit-report="creditReport" :loading="isLoading.creditReport"></CreditBanner>
    <Breadcrumbs />

    <main id="main" class="px-4 sm:px-6 py-8 md:py-16 bg-gray-100">
      <div class="mx-auto max-w-3/4 md:max-w-7xl lg:max-w-7xl w-full">
        <div class="grid gap-10 md:gap-20" :class="getColumnClasses()">
          <div class="text-flow-blue dealer-text-secondary col-span-1">
            <CreditScore
              :credit-report="creditReport"
              @loading="loading"
              :loading="isLoading.creditReport"
              @request-toggle-chat="$emit('request-toggle-chat')"
            />
          </div>
        </div>
      </div>
      <div class="border-flow-blue dealer-text-primary col-span-1">
        <div id="chatholder"></div>
      </div>
    </main>

    <Footer />
  </section>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Footer from '@/components/Layout/Footer';
import Breadcrumbs from '@/components/Ui/Breadcrumbs';
import CreditBanner from '@/components/Ui/Banners/CreditBanner';
import Header from '@/components/Layout/Dashboard/Header';
import CreditScore from '@/components/Dashboard/CreditScore';
import Bugsnag from '@bugsnag/js';
import Api from '@/lib/Api';

export default {
  components: {
    CreditScore,
    CreditBanner,
    Footer,
    Breadcrumbs,
    Header,
  },

  computed: mapState(['dashboard', 'dealer']),

  data() {
    return {
      status: null,
      creditReport: null,
      isLoading: {
        creditReport: false,
      },
    };
  },

  methods: {
    ...Api,

    /**
     *
     * @returns {Promise<void>}
     */
    async fetchCreditReport() {
      this.isLoading.creditReport = true;
      try {
        this.creditReport = await this.apiGetCreditReport(this.dashboard.application.reference);
      } catch (e) {
        console.error(e);
        Bugsnag.notify(e);
      }
      this.isLoading.creditReport = false;
    },

    /**
     *
     * @returns {string}
     */
    getColumnClasses() {
      let baseClass = 'grid-cols-1';

      if (this.$route.meta.columns && !isNaN(this.$route.meta.columns)) {
        baseClass += ' md:grid-cols-' + this.$route.meta.columns;
      }

      return baseClass;
    },

    /**
     *
     * @param state
     * @param text
     */
    loading(state = false, text = 'One moment...') {
      this.$emit('loading', state, text);
    },
  },

  async mounted() {
    // Inject dealer styles if available
    this.injectDealerStyles();

    // Update favicon if set
    // TODO: Should we move this to the injectDealerStyles global method?
    if (this.dealer.favicon_url) {
      let favicon = document.querySelector('link[rel="icon"]');
      favicon.href = this.dealer.favicon_url;
    }

    await this.fetchApplication(this.$route.params.reference);

    await this.fetchCreditReport();
  },
};
</script>

<style lang="scss" scoped>
.chatPulse {
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(39, 42, 92, 0.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(39, 42, 92, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(39, 42, 92, 0);
  }
}
</style>
