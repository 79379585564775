import { Loader } from '@googlemaps/js-api-loader';

export default class GoogleMaps {
  constructor(libs = []) {
    this.loader = new Loader({
      // TODO: Move to env
      apiKey: 'AIzaSyBGd7CB93r-4bLibBsBZKO31me25BxJoVY',
      version: 'weekly',
      libraries: libs,
    });

    this.google = null;
  }

  async getPostcodeCoords(postcode) {
    if (this.google === null) {
      this.google = await this.loader.load();
    }

    const geocoder = new google.maps.Geocoder();

    return geocoder.geocode({
      componentRestrictions: {
        country: 'GB',
      },
      address: postcode,
    });
  }
}
