<template>
  <footer class="footer w-full dealer-footer-bg">
    <modal
      name="applyModal"
      id="applyModal"
      styles="border-radius:1rem; background-color: #272A5C;"
      classes="p-6 sm:p-8 text-white leading-5 modal"
      :adaptive="true"
      height="auto"
      :scrollable="true"
    >
      <div class="h-10 block w-full text-right">
        <span
          class="float-right flex items-center cursor-pointer"
          @click="$modal.hide('applyModal')"
        >
          <span class="text-xl -mt-1 text-white w-6">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#ffffff">
              <path
                d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
              />
            </svg>
          </span>
          <span class="ml-3 uppercase text-base">Close</span>
        </span>
      </div>

      <div class="text-base p-2 font-normal">
        <h2 class="text-left w-full text-white text-xl sm:text-3xl font-semibold">Are you:</h2>
        <div class="text-lg sm:text-xl text-white leading-normal">
          <ul
            v-if="this.isPrime() && !this.isOnline()"
            class="mt-5 check-list leading-6 sm:leading-9 w-full"
          >
            <li><i class="fas fa-check"></i> Are you 23+?</li>
            <li><i class="fas fa-check"></i> Do you have a UK bank account?</li>
            <li>
              <i class="fas fa-check"></i> Do you have a good credit history with no defaults, court
              judgements or bankruptcies in the past 3 years?
            </li>
          </ul>
          <ul v-else class="mt-5 check-list leading-6 sm:leading-9 w-full">
            <li><i class="fas fa-check"></i> Are you 21+?</li>
            <li><i class="fas fa-check"></i> In permanent employment or retired?</li>
            <li>
              <i class="fas fa-check"></i> Fair credit history with no defaults/ CCJs in the last 18
              months?
            </li>
            <li><i class="fas fa-check"></i> Have a UK bank account?</li>
            <li>
              <i class="fas fa-check"></i> Not using the car as a taxi/driving instructor or
              similar?
            </li>
          </ul>
        </div>

        <button
          @click="varifyAge"
          data-criteria
          type="submit"
          class="mt-5 dealer-text-primary button bg-white border border-white hover:bg-transparent hover:text-white"
        >
          Yes, continue
        </button>
      </div>
    </modal>

    <div
      v-if="dealer.legal_text"
      class="max-w-screen-xl mx-auto py-12 px-8 sm:px-6 lg:py-12 lg:px-8"
    >
      <div class="text-white text-center">
        <div v-html="dealer.legal_text" class="text-sm leading-5" />

        <div
          v-if="dealer.terms_conditions || dealer.privacy_policy"
          class="mx-auto mt-6 text-center"
        >
          <div class="text-white text-center">
            <ul class="list-none inline-flex space-x-3 text-sm justify-items-center">
              <li
                v-if="dealer.terms_conditions"
                @click="show('termsModal')"
                class="cursor-pointer underline hover:no-underline"
              >
                Terms & Conditions
              </li>
              <li
                v-if="dealer.privacy_policy"
                @click="show('privacyModal')"
                class="cursor-pointer underline hover:no-underline"
              >
                Privacy Policy
              </li>
            </ul>

            <modal
              name="privacyModal"
              id="privacyModal"
              styles="border-radius:1rem;"
              classes="modal my-8"
              height="auto"
              :scrollable="true"
            >
              <div
                class="bg-flow-blue text-white dealer-bg-secondary p-6 sm:p-8 text-white leading-5"
              >
                <div class="h-10 block w-full text-right">
                  <span
                    class="float-right flex items-center cursor-pointer"
                    @click="$modal.hide('privacyModal')"
                  >
                    <span class="text-xl -mt-1 text-white w-6">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#ffffff">
                        <path
                          d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                        />
                      </svg>
                    </span>
                    <span class="ml-3 uppercase text-base">Close</span>
                  </span>
                </div>
                <div class="text-sm p-2 font-normal text-left">
                  <div v-html="dealer.privacy_policy" />
                </div>
              </div>
            </modal>

            <modal
              name="termsModal"
              id="termsModal"
              styles="border-radius:1rem;"
              classes="modal my-8"
              height="auto"
              :scrollable="true"
            >
              <div
                class="bg-flow-blue text-white dealer-bg-secondary p-6 sm:p-8 text-white leading-5"
              >
                <div class="h-10 block w-full text-right">
                  <span
                    class="float-right flex items-center cursor-pointer"
                    @click="$modal.hide('termsModal')"
                  >
                    <span class="text-xl -mt-1 text-white w-6">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#ffffff">
                        <path
                          d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                        />
                      </svg>
                    </span>
                    <span class="ml-3 uppercase text-base">Close</span>
                  </span>
                </div>
                <div class="text-sm p-2 font-normal text-left">
                  <div v-html="dealer.terms_conditions" />
                </div>
              </div>
            </modal>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="max-w-screen-xl mx-auto py-12 px-8 sm:px-6 lg:py-12 lg:px-8">
      <div class="flex flex-col lg:flex-row lg:justify-between">
        <!--<div class="footer-logo">-->
        <!--    <div v-if="showCollaborationMsg()" class="text-white text-center">-->
        <!--        {{ journey.partner.name }} in collaboration with-->
        <!--    </div>-->
        <!--</div>-->

        <div class="lg:flex space-y-4 lg:space-y-0 lg:gap-12">
          <!--Address-->
          <div class="flex gap-4">
            <div class="dealer-text-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8"
                fill="#CB8161"
                viewBox="0 0 384 512"
              >
                <path
                  d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 20.2 9.1 48.6 26.5 82.7c16.9 33.2 39.9 68.2 63.4 100.5c23.4 32.2 46.9 61 64.5 81.9c1.9 2.3 3.8 4.5 5.6 6.6c1.8-2.1 3.6-4.3 5.6-6.6c17.7-20.8 41.1-49.7 64.5-81.9c23.5-32.3 46.4-67.3 63.4-100.5C342.9 240.6 352 212.2 352 192zm32 0c0 88.8-120.7 237.9-170.7 295.9C200.2 503.1 192 512 192 512s-8.2-8.9-21.3-24.1C120.7 429.9 0 280.8 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"
                />
              </svg>
            </div>
            <div class="text-white leading-normal">
              <span class="font-semibold">Magnitude Finance Unity House,</span> <br />
              Unity House, Bird Hall Lane, <br />
              Stockport, SK3 0UX
            </div>
          </div>

          <!--Telephone-->
          <div class="flex">
            <div class="dealer-text-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8"
                viewBox="0 0 384 512"
                fill="#CB8161"
              >
                <path
                  d="M64 32V480H320V32H64zM32 0H64 320h32V32 480v32H320 64 32V480 32 0zM160 400h64 16v32H224 160 144V400h16z"
                />
              </svg>
            </div>
            <p class="text-white ml-4">
              <a class="font-medium" href="tel:01614063994">
                {{ content.telephone }}
              </a>
            </p>
          </div>

          <!--Email-->
          <div class="flex">
            <div class="dealer-text-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8"
                viewBox="0 0 512 512"
                fill="#CB8161"
              >
                <path
                  d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224h16v32H256C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v96 16H496 424c-33.4 0-62.5-18.7-77.4-46.1c-20.4 28-53.4 46.1-90.6 46.1c-61.9 0-112-50.1-112-112s50.1-112 112-112c31.3 0 59.7 12.9 80 33.6V176 160h32v16 80 24c0 30.9 25.1 56 56 56h56V256C480 132.3 379.7 32 256 32zm80 224a80 80 0 1 0 -160 0 80 80 0 1 0 160 0z"
                />
              </svg>
            </div>
            <p class="text-white ml-4">
              <a class="font-medium" :href="`mailto:${content.email}`">{{ content.email }}</a>
            </p>
          </div>
        </div>

        <!--Social media-->
        <div class="flex gap-6 mt-6 lg:mt-0">
          <!--Twitter-->
          <a
            href="#"
            title=""
            class="rounded-full border-2 border-flow-teal w-10 h-10 p-2 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="#CB8161"
              class="w-6 h-6"
            >
              <path
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>
          </a>

          <!--Facebook-->
          <a
            href="#"
            title=""
            class="rounded-full border-2 border-flow-teal w-10 h-10 p-2 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              fill="#CB8161"
              class="w-6 h-6"
            >
              <path
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
              />
            </svg>
          </a>

          <!--Instagram-->
          <a
            href="#"
            title=""
            class="rounded-full border-2 border-flow-teal w-10 h-10 p-2 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              fill="#CB8161"
              class="w-6 h-6"
            >
              <path
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </a>

          <!--LinkedIn-->
          <a
            href="#"
            title=""
            class="rounded-full border-2 border-flow-teal w-10 h-10 p-2 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
              fill="#CB8161"
              class="w-6 h-6"
            >
              <path
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              />
            </svg>
          </a>

          <!--Youtube-->
          <a
            href="#"
            title=""
            class="rounded-full border-2 border-flow-teal w-10 h-10 p-2 flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 576 512"
              fill="#CB8161"
              class="w-6 h-6"
            >
              <path
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
              />
            </svg>
          </a>
        </div>
      </div>

      <div class="mt-2 lg:mt-8 pt-8 text-white w-full text-sm lg:text-md leading-normal">
        <p>
          We can introduce you to a limited number of lenders and their finance products. We are not
          an independent financial advisor; we will provide details of products available, but no
          advice or recommendation will be made. You must decide whether the finance product is
          right for you. We do not charge you a fee for our services. Whichever lender we introduce
          you to, we will typically receive commission from them (either a fixed fee or a fixed
          percentage of the amount you borrow). The commission arrangements we have in place vary,
          depending on which lender we are able to place you with. We will always introduce you to
          the most suitable finance lender offering you the most appropriate finance product for
          your needs, and at the best interest rate possible (which is determined by the finance
          lender) based on the commission we are paid and by your credit profile. The amount of
          commission we receive, and your credit profile, will impact the amounts you pay under your
          credit agreement.
        </p>

        <p>
          We may select a lender which is part of our group (Unity Auto Finance Ltd). We will only
          ever select this particular lender from the panel available to us if it is in your
          interests and is the most appropriate lender for your particular needs.
        </p>

        <p>
          We are a credit broker and not a lender. Magnitude Finance is a trading style of DSG
          Financial Services Ltd. DSG Financial Services Ltd is a company registered in England &
          Wales with a company number 02313903. Our VAT registration number is 511678843.
        </p>

        <p>
          DSG Financial Services Ltd is authorised and regulated by the Financial Conduct Authority
          under firm reference number 649675. If you would like to know how we handle complaints,
          please ask for a copy of our complaints handling procedure, or
          <router-link to="/complaints" class="underline hover:no-underline dealer-text-secondary"
            >click here</router-link
          >. You can also find information about referring a complaint to the Financial Ombudsman
          Service (FOS) at
          <a
            class="underline hover:no-underline dealer-text-secondary"
            target="_blank"
            title="Financial Ombudsman Service"
            href="//www.financial-ombudsman.org.uk"
            >www.financial-ombudsman.org.uk</a
          >.
        </p>

        <p>
          Magnitude Finance is a part of the DSG Group, a collective of fintech companies providing
          car finance solutions to the UK's businesses and individuals.
        </p>

        <nav class="flex flex-wrap items-center" aria-label="Footer">
          <div class="text-base mr-5">
            <a
              href="https://dsgfinance.group/"
              target="_blank"
              title="DSG Finance"
              class="no-underline hover:underline font-semibold"
              >DSG Group</a
            >
            are:
          </div>

          <div class="pr-2 md:border-r border-white border-opacity-50">
            <a
              href="https://igneousassetfinance.com"
              target="_blank"
              title="Igneous Asset Finance"
              class="text-base dealer-text-secondary no-underline hover:underline"
            >
              Igneous Asset Finance
            </a>
          </div>

          <div class="px-2 md:border-r border-white border-opacity-50">
            <a
              href="https://leasing.cars"
              target="_blank"
              title="Leasing Cars"
              class="text-base dealer-text-secondary no-underline hover:underline"
            >
              leasing.cars
            </a>
          </div>

          <div class="px-2 md:border-r border-white border-opacity-50">
            <a
              href="https://www.connectedcarfinance.co.uk/"
              target="_blank"
              title="Connected Car Finance"
              class="text-base dealer-text-secondary no-underline hover:underline"
            >
              Connected Car Finance
            </a>
          </div>

          <div class="px-2 md:border-r border-white border-opacity-50">
            <a
              href="https://unityauto.finance"
              target="_blank"
              title="Unity Auto Finance"
              class="text-base dealer-text-secondary no-underline hover:underline"
            >
              Unity Auto Finance
            </a>
          </div>

          <div class="px-2">
            <a
              href="https://www.dsgfs.com"
              target="_blank"
              title="DSGFS Finance"
              class="text-base dealer-text-secondary no-underline hover:underline"
            >
              DSG Finance
            </a>
          </div>
        </nav>
      </div>

      <div class="flex flex-wrap text-base gap-4 leading-6 text-white md:order-1 mt-5">
        <a
          href="https://www.magnitudefinance.com/terms-and-conditions/"
          target="_blank"
          class="hover:dealer-text-secondary"
          >Terms and conditions</a
        >
        <a
          href="https://www.magnitudefinance.com/cookie-policy/"
          target="_blank"
          class="hover:dealer-text-secondary px-2"
          >Cookie Policy</a
        >
        <a
          href="javascript:void(0)"
          class="hover:text-flow-teal"
          @click="openHubspotCookieSettings()"
          >Cookie Settings</a
        >
        <a
          href="https://www.magnitudefinance.com/privacy-policy/"
          target="_blank"
          class="hover:dealer-text-secondary px-2"
          >Privacy Policy</a
        >
        <a
          href="https://www.magnitudefinance.com/complaints-handling-procedure/"
          target="_blank"
          title=""
          >Complaints Procedure</a
        >
        <a
          href="https://www.magnitudefinance.com/faqs/"
          target="_blank"
          class="hover:dealer-text-secondary"
          >FAQs</a
        >
        <a
          href=" https://flowcarfinance.co.uk/cockpit/storage/uploads/2021/09/17/6144aeb2d8c1dmodern_slavery.pdf"
          target="_blank"
          class="hover:dealer-text-secondary"
          >Modern Slavery</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/Layout/Logo';
import { mapState } from 'vuex';
import { content } from '@/config/content';

export default {
  name: 'FooterBar',
  components: {
    Logo,
  },

  computed: {
    setting() {
      return this.$store.state.web.settings;
    },
    ...mapState(['journey', 'dealer', 'dashboard']),
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_BASEURL,
      content: content,
    };
  },

  methods: {
    varifyAge() {
      this.$router.push('/apply/personal-details');
    },

    show(name) {
      this.$modal.show(name);
    },

    hide() {
      this.$modal.hide(privacyModal);
      this.$modal.hide(termsModal);
    },

    showCollaborationMsg() {
      return (
        this.journey.partner &&
        this.journey.partner.name &&
        this.journey.partner.branding &&
        this.journey.partner.branding.enable_branding
      );
    },

    openHubspotCookieSettings() {
      (function () {
        let _hsp = (window._hsp = window._hsp || []);
        _hsp.push(['showBanner']);
      })();
    },
  },
};
</script>

<style scoped lang="scss">
#applyModal {
  ul {
    li {
      &::before {
        content: '\2713';
        width: 20px;
        height: 20px;

        @apply absolute left-0 block text-flow-teal font-bold;
      }

      @apply relative pl-7;
    }
  }
}
</style>
