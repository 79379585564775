var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"step employment-history flex-1"},[_c('h2',[_vm._v("Employment")]),_c('h5',{staticClass:"grey"},[_vm._v("Let's take a look at your employment history.")]),_c('section',{staticClass:"form mt-8"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.isEmploymentHistoryEnough(_vm.journey.applicant.employment.employments) &&
        _vm.journey.applicant.employment.employments.length
      ),expression:"\n        !isEmploymentHistoryEnough(journey.applicant.employment.employments) &&\n        journey.applicant.employment.employments.length\n      "}],staticClass:"mt-6 bg-red-500 p-6 mb-5",attrs:{"id":"need-more-history"}},[_c('div',{staticClass:"grid grid-cols-3 gap-4 null"},[_c('div',{staticClass:"col-span-6"},[_c('h4',{staticClass:"font-bold text-xl mb-2 text-white"},[_vm._v("We need a bit more history")]),_c('p',{staticClass:"mb-0 text-white"},[_vm._v(" We need at least "+_vm._s(_vm.form.employments.options.minHistoryMonths > 0 ? _vm.form.employments.options.minHistoryMonths / 12 + ' years' : _vm.form.employments.options.minHistoryMonths + ' months')+" of employment history to continue. ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isEmploymentHistoryEnough(_vm.journey.applicant.employment.employments) &&
        _vm.journey.applicant.employment.employments &&
        _vm.journey.applicant.employment.employments.length
      ),expression:"\n        isEmploymentHistoryEnough(journey.applicant.employment.employments) &&\n        journey.applicant.employment.employments &&\n        journey.applicant.employment.employments.length\n      "}],staticClass:"bg-green-500 text-white p-6 mb-5"},[_vm._m(0)]),(!_vm.isEmploymentHistoryEnough(_vm.journey.applicant.employment.employments))?_c('div',[_c('EmploymentFinder',{attrs:{"min-history-months":36,"addressCount":_vm.journey.applicant.employment.employments.length},on:{"historyAdded":_vm.addEmploymentHistory}})],1):_vm._e(),(
        _vm.journey.applicant.employment.employments &&
        _vm.journey.applicant.employment.employments.length
      )?_c('div',{staticClass:"previous-employment mt-10"},[_c('FormLabel',[_vm._v("Previous employers")]),_vm._l((_vm.journey.applicant.employment.employments),function(employment){return _c('div',{key:employment.id,staticClass:"mt-2 text-left sm:py-3 sm:whitespace-no-wrap focus:outline-none active:outline-none inline-flex w-full items-center py-2 text-md leading-normal font-medium transition ease-in-out duration-150 pl-12 pr-5 relative mb-2",attrs:{"id":"previous-employment"}},[_c('span',{staticClass:"cursor-pointer w-10 h-10 flex justify-center items-center absolute left-0",on:{"click":function($event){return _vm.removeEmployment(employment.id)}}},[_c('svg',{staticClass:"remove-employment w-8 h-8",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 384 512"}},[_c('path',{attrs:{"d":"M192 233.4L59.5 100.9 36.9 123.5 169.4 256 36.9 388.5l22.6 22.6L192 278.6 324.5 411.1l22.6-22.6L214.6 256 347.1 123.5l-22.6-22.6L192 233.4z"}})])]),(employment.type === 'Retired')?_c('span',{staticClass:"truncate",attrs:{"title":"Retired"}},[(employment.started)?_c('span',{staticClass:"text-sm font-bold mr-2"},[_vm._v(_vm._s(_vm.IsCalendarDate(employment.started))+",")]):_vm._e(),_vm._v(" Retired")]):(employment.type === 'Unemployed')?_c('span',{staticClass:"truncate",attrs:{"title":"Unemployed"}},[(employment.started)?_c('span',{staticClass:"text-sm font-bold mr-2"},[_vm._v(_vm._s(_vm.IsCalendarDate(employment.started))+",")]):_vm._e(),_vm._v("Unemployed")]):_c('span',{staticClass:"truncate",attrs:{"title":_vm.getEmploymentSummaryText(employment)}},[(employment.started)?_c('span',{staticClass:"text-sm font-bold mr-2"},[_vm._v(_vm._s(_vm.formatDate(employment.started, 'MMMM Y'))+",")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getEmploymentSummaryText(employment)))])])})],2):_vm._e(),_c('ValidationObserver',{ref:"observer",staticClass:"employment",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var passes = ref.passes;
      var invalid = ref.invalid;
return [(_vm.journey.applicant.employment.employments.length)?_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.form.income.rules,"name":"Salary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('FormLabel',{attrs:{"for-input":"salary"}},[_vm._v("Your annual income before tax")]),_c('TextInputIcon',{attrs:{"id":"salary","icon":"fas fa-pound-sign","name":"salary","is-number":true},on:{"change":function($event){_vm.form.income.dirty = true}},model:{value:(_vm.form.income.value),callback:function ($$v) {_vm.$set(_vm.form.income, "value", $$v)},expression:"form.income.value"}}),(_vm.form.income.value !== 0 && _vm.form.income.value < 12000 && _vm.form.income.dirty)?_c('span',[_vm._v("Is this "),_c('strong',[_vm._v("annual income before tax")]),_vm._v(" correct? £"+_vm._s(_vm.formatPrice(_vm.form.income.value)))]):_vm._e(),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.isEmploymentHistoryEnough(_vm.journey.applicant.employment.employments))?_c('div',{staticClass:"form-actions flex"},[_c('button',{staticClass:"dealer-btn-primary ml-auto",on:{"click":_vm.submitIfValid}},[_vm._v("Next")])]):_vm._e()]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid grid-cols-3 gap-4 null"},[_c('div',{staticClass:"col-span-6"},[_c('span',{staticClass:"font-bold text-xl mb-2"},[_vm._v("You've provided enough history, thanks!")])])])}]

export { render, staticRenderFns }