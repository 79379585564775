<template>
  <div
    class="page page--login absolute h-screen w-full"
    :class="{
      'login-overlay': !dealerStylesInjected,
      'dealer-login-bg-color': dealerStylesInjected,
    }"
  >
    <vue-headful
      title="Secure Your Finance First | How It works | Magnitude Finance | Car Finance Broker UK"
    />

    <main class="flex items-center h-screen">
      <div class="container mx-auto">
        <div
          class="rounded-t-xl flex items-center justify-center p-8 border-b border-b-gray-400 bg-white dealer-header-bg-color mx-auto w-11/12 sm:w-3/4 md:w-1/2 lg:max-w-lg"
          id="logo"
        >
          <img v-if="dealer.logo_url" :src="dealer.logo_url" alt="" class="" />
          <Logo v-else></Logo>
        </div>

        <div
          class="bg-white shadow-lg rounded-b-xl mx-auto px-12 pb-16 pt-10 relative w-11/12 sm:w-3/4 md:w-1/2 lg:max-w-lg"
        >
          <!-- Displayed if a code hasn't been sent yet -->
          <div v-show="!codeSent" class="form send-code">
            <h1 class="text-xl">Login</h1>

            <div
              v-if="mainError !== null"
              class="bg-red-100 py-3 px-5 rounded-lg text-red-700 mb-5"
            >
              {{ mainError }}
            </div>

            <p>Please enter the mobile phone number you used to create your application.</p>

            <ValidationObserver ref="phoneObserver" v-slot="{ invalid }">
              <div class="field">
                <ValidationProvider :rules="form.phone.rules" v-slot="{ errors }">
                  <label for="phone">Mobile phone</label>
                  <input
                    ref="phone"
                    type="tel"
                    id="phone"
                    v-model="form.phone.value"
                    autocomplete="tel-local"
                    placeholder="07712312312"
                    v-on:keyup.enter="sendCode()"
                  />
                  <div class="text-red-500">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>

              <div class="form-actions">
                <button
                  :disabled="invalid"
                  class="btn-primary dealer-btn-primary w-full"
                  :class="{ 'opacity-50': invalid }"
                  @click="sendCode()"
                >
                  Send code
                </button>
              </div>
            </ValidationObserver>
          </div>

          <!-- Displayed once a code has been sent -->
          <div v-show="codeSent" class="form verify-code">
            <p>
              We've sent a 6 digit code to <strong>{{ form.phone.value }}</strong
              >. Please enter it below.
            </p>

            <div
              v-if="mainError !== null"
              class="bg-red-100 py-3 px-5 rounded-lg text-red-700 my-5"
            >
              {{ mainError }}
            </div>

            <ValidationObserver ref="codeObserver" v-slot="{ invalid }">
              <ValidationProvider :rules="form.code.rules" v-slot="{ errors }">
                <div class="field">
                  <label for="code">6 digit code</label>
                  <input
                    ref="code"
                    type="tel"
                    autocomplete="off"
                    required
                    id="code"
                    v-model="form.code.value"
                    placeholder="6 digit code"
                    v-on:keyup.enter="verifyCode()"
                  />
                  <div class="text-red-500">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>

              <div class="form-actions">
                <button
                  :disabled="invalid"
                  @click="verifyCode()"
                  class="btn-primary dealer-btn-primary w-full"
                  :class="{ 'opacity-50': invalid }"
                >
                  Login
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>

        <div v-if="codeSent" class="w-full text-center">
          <button
            @click="codeSent = false"
            class="text-white underline hover:dealer-text-primary mt-5 mx-auto"
          >
            Didn't receive a code?
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Logo from '@/components/Layout/Logo';
import axios from 'axios';
import Api from '@/lib/Api';
import Bugsnag from '@bugsnag/js';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Logo,
  },

  computed: mapState(['dealer']),

  data() {
    return {
      codeSent: false,
      loading: false,
      mainError: null,
      form: {
        phone: {
          value: null,
          rules: 'required|min:8|numeric',
        },
        code: {
          value: null,
          rules: 'required|min:6|numeric',
        },
      },
      dealerStylesInjected: false,
    };
  },

  methods: {
    ...Api,

    translateErrors(error) {
      if (typeof this.errors.messages[error] !== undefined) {
        return this.errors.messages[error];
      }
      return error;
    },

    async sendCode() {
      const isValid = await this.$refs.phoneObserver.validate();

      if (isValid) {
        this.$emit('loading', true, 'Sending your code...');

        const response = await this.apiPhoneVerificationSendCode(this.form.phone.value);

        if (response) {
          this.codeSent = true;

          // Focus on the code input
          this.$nextTick(() => {
            this.$refs.code.focus();
          });
        }

        this.$emit('loading', false);
      }
    },

    async verifyCode() {
      const isValid = await this.$refs.codeObserver.validate();

      if (isValid) {
        this.$emit('loading', true, 'Verifying...');
        const is2FAValid = await this.apiPhoneVerificationCheckCode(
          this.form.phone.value,
          this.form.code.value
        );
        this.$emit('loading', false);

        if (is2FAValid) {
          this.$emit('loading', true, 'Logging in...');

          // Login
          return axios
            .get(this.getApiBaseUrl() + '/me/all', {
              withCredentials: true,
            })
            .then(async (userResponse) => {
              this.$store.commit('setAuthed', true);
              this.$store.commit('setUser', userResponse.data.data.user);

              const validatedApplication = await this.apiValidateApplicationLogin();

              if (!validatedApplication) {
                this.mainError =
                  'Sorry, there was a problem verifying your application data - if this problem continues, please get in touch.';
                return false;
              }

              if (!validatedApplication.valid) {
                // Log them out and resume
                return await axios.post(this.getApiBaseUrl() + '/auth/logout').then(async (r) => {
                  await this.$store.dispatch('logout');
                  await this.$store.commit(
                    'mapApplicationJourney',
                    validatedApplication.application
                  );
                  this.$emit('loading', false);
                  return await this.$router.push({
                    name: 'incomplete',
                    params: {
                      routeName: validatedApplication.route_name,
                    },
                  });
                });
              }

              let dealerName = this.dealer.name;

              // TODO: This will need refactoring due to multi-app support.
              if (this.dealer.name) {
                // Attach dealer name to push event
                await this.apiPushEvent(
                  userResponse.data.data.application.reference,
                  dealerName + '_action_login',
                  dealerName + '_feed_login',
                  dealerName + '_dashboard',
                  dealerName + '_login',
                  'false'
                );
              } else {
                // Standard push event
                await this.apiPushEvent(
                  userResponse.data.data.application.reference,
                  'action_login',
                  'feed_login',
                  'dashboard',
                  'login',
                  'false'
                );
              }

              // Redirect them to their latest application dashboard.
              // If no result, take them to the application management screen.
              const applicationRef = userResponse.data.data.application?.reference ?? null;
              if (applicationRef !== null) {
                return (window.location = this.$router.resolve({
                  name: 'application-dashboard',
                  params: {
                    reference: applicationRef,
                  },
                }).href);
              }
              return (window.location = this.$router.resolve({
                name: 'applications__manage',
              }).href);
            })
            .catch((e) => {
              console.error(e);
              this.$store.commit('setAuthed', false);
              this.$store.commit('setUser', null);
              this.$emit('loading', false);
              Bugsnag.notify(e);
              this.mainError =
                'Sorry, there was a problem logging you in. Please get in touch if this issue persists.';
              return null;
            });
        }

        this.$emit('loading', false);
        this.mainError = 'The code you entered was incorrect';
      }
    },

    apiPhoneVerificationSendCode(phone) {
      this.mainError = null;

      const postData = {
        phone: phone,
        auth: true,
        dealer_slug: this.$store.state.dealer.slug || null,
        dealer_id: this.$store.state.dealer.id || null,
      };

      return axios
        .post(this.getApiBaseUrl() + '/phone-verification/send-code', postData)
        .then((r) => {
          if (r.data.success) {
            return true;
          }

          if (r.data.errors.user) {
            this.mainError = "We couldn't find an account registered to that phone number.";
            return false;
          }

          if (r.data.errors.length) {
            this.mainError = r.data.errors[r.data.errors.length - 1].message;
            return false;
          }

          throw new Error('Unknown error sending code');
        })
        .catch(() => {
          this.mainError = 'There was a problem sending your code. Please try again.';
          return false;
        });
    },

    apiPhoneVerificationCheckCode(phone, code) {
      this.mainError = null;

      const postData = {
        phone: phone,
        code: code,
        auth: true,
        partner_slug: this.$store.state.dealer.slug || null,
        partner_dealer_id: this.$store.state.dealer.id || null,
      };

      Bugsnag.leaveBreadcrumb('PV - request', postData);
      return axios
        .post(this.getApiBaseUrl() + '/auth/login', postData)
        .then((r) => {
          Bugsnag.leaveBreadcrumb('PV - response', r);
          if (!r.data.success) {
            Bugsnag.leaveBreadcrumb('PV - application considered code to be invalid');
            return false;
          }
          Bugsnag.leaveBreadcrumb('PV - application considered code to be valid');
          Bugsnag.notify('DEBUG');
          return r.data.data.authed;
        })
        .catch((e) => {
          Bugsnag.notify(e);
          return false;
        });
    },
  },

  mounted() {
    // Focus on the phone number field
    if (this.$refs.phone) {
      this.$refs.phone.focus();
    }

    // Disable loading overlay if it's been left on by another module
    this.$emit('loading', false);

    // Inject dealer styles if available
    this.dealerStylesInjected = this.injectDealerStyles();
  },
};
</script>
