<template>
  <div class="address-finder" id="address-finder">
    <!-- Friendly errors -->
    <div class="" v-if="pageError">
      <p class="mt-2 text-red-600">{{ pageError }}</p>
    </div>

    <ValidationObserver ref="historyObserver" v-slot="{ passes, invalid, errors }" tag="div">
      <!-- Type of employment -->
      <template>
        <div id="employment-status" class="field">
          <validation-provider
            ref="status"
            name="status"
            :rules="form.status.rules"
            v-slot="{ errors }"
          >
            <label>What {{ addressCount > 0 ? 'was' : 'is' }} your employment status?</label>

            <RadioButtons
              ref="employmentStatus"
              :max-options="6"
              :options="form.status.options"
              :rules="form.status.rules"
              v-model="form.status.value"
              cols="2"
            />

            <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
        </div>
      </template>

      <!-- Postcode search -->

      <transition name="fade">
        <div
          v-show="
            form.status.value !== null &&
            form.status.value !== 'Retired' &&
            form.status.value !== 'Unemployed'
          "
          id="postcode-section"
          class="field"
        >
          <label for="postcode-search"
            >Your {{ addressCount > 0 ? 'previous ' : '' }}employer</label
          >

          <div id="postcode-search" v-if="!manualEntry.enabled">
            <p class="mt-1">Search by address, postcode or company name</p>
            <Postcoder ref="postcoder" v-model="form.address.value" />
            <div
              @click="manualEntry.enabled = true"
              class="font-bold mt-3 cursor-pointer hover:underline text-right"
            >
              Can't find the address?
            </div>
          </div>

          <template v-else>
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
              <validation-provider
                ref="manual_employer"
                name="manual_employer_validation"
                :rules="manualEntry.employer.rules"
                v-slot="{ errors }"
                tag="div"
                class="mb-4"
              >
                <label for="manual-employer">Business name</label>
                <input
                  name="manual-employer"
                  id="manual-employer"
                  type="text"
                  class="form-input"
                  v-model="manualEntry.employer.value"
                />
                <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
              </validation-provider>

              <div class="grid grid-cols-2 gap-8 mb-4">
                <validation-provider
                  ref="manual_building_name"
                  name="building name"
                  rules="required_if_other_empty:@building number"
                  v-slot="{ errors }"
                  tag="div"
                >
                  <label for="manual-building-name">Building name</label>
                  <input
                    name="building name"
                    id="manual-building-name"
                    type="text"
                    class="form-input"
                    v-model="manualEntry.building_name.value"
                  />
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>

                <validation-provider
                  ref="manual_building_number"
                  name="building number"
                  rules="required_if_other_empty:@building name"
                  v-slot="{ errors }"
                  tag="div"
                >
                  <label for="manual-building-number">Building number</label>
                  <input
                    name="building number"
                    id="manual-building-number"
                    type="text"
                    class="form-input"
                    v-model="manualEntry.building_number.value"
                  />
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>
              </div>

              <validation-provider
                ref="manual_street"
                name="manual_street_validation"
                :rules="manualEntry.street.rules"
                v-slot="{ errors }"
                tag="div"
                class="mb-4"
              >
                <label for="manual-street">Street</label>
                <input
                  name="manual-street"
                  id="manual-street"
                  type="text"
                  class="form-input"
                  v-model="manualEntry.street.value"
                />
                <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
              </validation-provider>

              <div class="grid grid-cols-2 gap-8 mb-4">
                <validation-provider
                  ref="manual_town"
                  name="manual_town_validation"
                  :rules="manualEntry.town.rules"
                  v-slot="{ errors }"
                  tag="div"
                >
                  <label for="manual-town">Town</label>
                  <input
                    name="manual-town"
                    id="manual-town"
                    type="text"
                    class="form-input"
                    v-model="manualEntry.town.value"
                  />
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>

                <validation-provider
                  ref="manual_postcode"
                  name="manual_postcode_validation"
                  :rules="manualEntry.postcode.rules"
                  v-slot="{ errors }"
                  tag="div"
                >
                  <label for="manual-postcode">Postcode</label>
                  <input
                    name="manual-postcode"
                    id="manual-postcode"
                    type="text"
                    class="form-input"
                    v-model="manualEntry.postcode.value"
                  />
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>
              </div>

              <div class="grid grid-cols-2 gap-8 mb-4 mt-10">
                <div class="justify-self-start">
                  <div
                    @click="manualEntry.enabled = false"
                    class="font-bold mt-3 cursor-pointer hover:underline"
                  >
                    Back to search
                  </div>
                </div>
                <div class="justify-self-end">
                  <button
                    class="btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary"
                    @click="addManualEmploymentAddress"
                    :disabled="invalid"
                    :class="{ 'opacity-50': invalid }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </template>
        </div>
      </transition>

      <!-- Date employed -->
      <transition name="fade">
        <div
          id="employment-date-section"
          v-if="
            form.address.value ||
            form.status.value === 'Retired' ||
            form.status.value === 'Unemployed'
          "
          class="field"
        >
          <div id="field__employment-date" class="transition duration-150 ease-in-out">
            <label for="months-dropdown"
              ><span v-html="employmentStatusLabel()" class="block"></span
            ></label>
            <div class="grid grid-cols-2 gap-6 mt-1 sm:mt-3">
              <div>
                <validation-provider
                  ref="month"
                  name="month"
                  :rules="form.startedMonth.rules"
                  v-slot="{ errors }"
                >
                  <label for="months-dropdown">Month</label>
                  <select
                    @input="validateStartedDate('month', $event)"
                    id="months-dropdown"
                    name="months-dropdown"
                    v-model="form.startedMonth.value"
                    class="form-select text-xl"
                    required
                  >
                    <option
                      v-for="(month, i) in form.startedMonth.options"
                      :key="i"
                      :value="month.value"
                    >
                      {{ month.label }}
                    </option>
                  </select>
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>
              </div>
              <div>
                <validation-provider
                  ref="year"
                  name="year"
                  :rules="form.startedYear.rules"
                  v-slot="{ errors }"
                >
                  <label for="years-input">Year</label>
                  <input
                    @keyup="validateStartedDate('year', $event)"
                    name="years-input"
                    id="years-input"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type="tel"
                    maxlength="4"
                    class="form-input text-lg"
                    v-model="form.startedYear.value"
                    placeholder="2019"
                  />
                  <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- Job title -->
      <transition name="fade">
        <div
          id="job-title-section"
          v-if="
            form.jobTitle.display &&
            form.status.value !== 'Retired' &&
            form.status.value !== 'Unemployed'
          "
          class="field"
        >
          <validation-provider
            ref="jobTitleValidation"
            name="job-title"
            :rules="form.jobTitle.rules"
            v-slot="{ errors }"
          >
            <label for="industry-dropdown"
              >What {{ addressCount > 0 ? 'was' : 'is' }} your job title?</label
            >
            <input
              class="form-input text-xl"
              placeholder="Job title"
              v-model.trim="form.jobTitle.value"
            />
            <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
        </div>
      </transition>

      <!-- Industry -->
      <transition name="fade">
        <div
          id="industry-section"
          v-if="
            form.jobTitle.value !== null &&
            form.jobTitle.value !== '' &&
            form.status.value !== 'Retired' &&
            form.status.value !== 'Unemployed'
          "
          class="field"
        >
          <validation-provider
            ref="industry"
            name="industry"
            :rules="form.industry.rules"
            v-slot="{ errors }"
          >
            <label for="industry-dropdown"
              >Which industry {{ addressCount > 0 ? 'did' : 'do' }} you work in?</label
            >
            <select
              id="industry-dropdown"
              name="industry-dropdown"
              v-model="form.industry.value"
              class="mt-1 block form-select w-full transition duration-150 ease-in-out cursor-pointer"
              required
            >
              <option
                v-for="(industry, i) in form.industry.options"
                :key="i"
                :value="industry.value"
                :selected="form.industry.value === industry.value"
              >
                {{ industry.label }}
              </option>
            </select>
            <div class="error-message" v-if="errors.length">{{ errors[0] }}</div>
          </validation-provider>
        </div>
      </transition>

      <div class="form-actions flex" v-if="form.industry.value">
        <button class="dealer-btn-primary ml-auto" @click="saveEmployment()">Save</button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Postcoder from '@/components/Form/Postcoder';
import moment from 'moment';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    Postcoder,
  },

  computed: {
    ...mapState(['application']),

    'form.jobTitle.value': {
      get(value) {
        return value;
      },
      set(value) {
        this.form.jobTitle.value.charAt(0).toUpperCase() + this.form.jobTitle.value.slice(1);
      },
    },
  },

  data() {
    return {
      pageError: null,
      manualEntry: {
        enabled: false,

        employer: {
          value: null,
          rules: {
            required: true,
          },
        },
        building_name: {
          value: '',
        },
        building_number: {
          value: '',
        },
        street: {
          value: '',
          rules: {
            required: true,
          },
        },
        town: {
          value: '',
          rules: null,
        },
        postcode: {
          value: '',
          rules: null,
        },
      },
      form: {
        status: {
          options: [
            { label: 'Full Time', value: 'Full Time' },
            { label: 'Part Time', value: 'Part Time' },
            { label: 'Self-Employed', value: 'Self Full Time' },
            { label: 'Temp', value: 'Temp' },
            { label: 'Unemployed', value: 'Unemployed' },
            { label: 'Retired', value: 'Retired' },
          ],
          value: null,
          rules: 'required',
        },
        industry: {
          value: null,
          display: false,
          options: [
            { value: 'Accountancy', label: 'Accountancy' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Artists', label: 'Artists' },
            { value: 'Benefits', label: 'On Benefits' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'Clergy or Religious Worker', label: 'Clergy or Religious Worker' },
            { value: 'Clerical or Admin Support', label: 'Clerical or Admin Support' },
            { value: 'Computers or IT', label: 'Computers or IT' },
            { value: 'Construction', label: 'Construction' },
            { value: 'Consultants', label: 'Consultants' },
            { value: 'Counsellors', label: 'Counsellors' },
            { value: 'Directors', label: 'Directors' },
            { value: 'Drivers', label: 'Drivers' },
            { value: 'Engineers', label: 'Engineers' },
            { value: 'Factory', label: 'Factory' },
            { value: 'Farmers', label: 'Farmers' },
            { value: 'Finance or Banking', label: 'Finance or Banking' },
            { value: 'Food Preparation or Service', label: 'Food Preparation or Service' },
            { value: 'Health Occupations', label: 'Health Occupations' },
            { value: 'Housewife or House Husband', label: 'Housewife or House Husband' },
            { value: 'Insurance', label: 'Insurance' },
            { value: 'Lawyers or Judges', label: 'Lawyers or Judges' },
            { value: 'Librarians', label: 'Librarians' },
            { value: 'Management', label: 'Management' },
            { value: 'Mechanics and Repairers', label: 'Mechanics and Repairers' },
            { value: 'Military Officer', label: 'Military Officer' },
            { value: 'Other', label: 'Other' },
            { value: 'Police', label: 'Police' },
            { value: 'Precision Production Occupation', label: 'Precision Production Occupation' },
            { value: 'Professional Sports', label: 'Professional Sports' },
            { value: 'Retail', label: 'Retail' },
            { value: 'Retired', label: 'Retired' },
            { value: 'Sales Rep or Account Mgmt', label: 'Sales Rep or Account Mgmt' },
            { value: 'Scientist or Researcher', label: 'Scientist or Researcher' },
            { value: 'Security Occupations', label: 'Security Occupations' },
            { value: 'Service Occupations', label: 'Service Occupations' },
            { value: 'Student', label: 'Student' },
            { value: 'Taxi Driver Black Cab', label: 'Taxi Driver Black Cab' },
            { value: 'Taxi Driver Non Black Cab', label: 'Taxi Driver Non Black Cab' },
            { value: 'Teachers or Professors', label: 'Teachers or Professors' },
            { value: 'Team Leader', label: 'Team Leader' },
            { value: 'Telesales', label: 'Telesales' },
          ],
          rules: {
            required: true,
          },
        },
        jobTitle: {
          value: null,
          rules: {
            required: true,
          },
          display: false,
        },
        postcode: {
          value: null,
          found: false,
          loading: false,
          errors: [],
        },
        address: {
          value: null,
          options: [],
          errors: [],
        },
        startedMonth: {
          options: [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' },
          ],
          rules: {
            required: true,
            month_not_future_with: { target: '@year' },
          },
          value: null,
        },
        startedYear: {
          rules: {
            required: true,
            year: true,
            min_value: 1900,
            max_value: moment().format('YYYY'),
            year_not_future_with: { target: '@month' },
          },
          options: {},
          value: null,
        },
      },
    };
  },

  props: {
    minHistoryMonths: {
      type: Number,
      default: 36,
    },

    addressCount: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    apiRemoveEmployment(employment) {
      if (typeof this.journey.applicant.id === 'undefined') {
        return null;
      }
      if (typeof employment.id === 'undefined') {
        return null;
      }
      return axios
        .delete(
          this.getApiBaseUrl() +
            '/applicants/' +
            this.journey.applicant.id +
            '/employments/' +
            employment.id
        )
        .then((r) => {
          if (!this.apiCallSuccessful(r)) {
            this.error = true;
            return {};
          }
          return r.data.data;
        });
    },

    addManualEmploymentAddress() {
      if (this.manualEntry.enabled) {
        this.form.address.value = this.manualEntry;
      }
    },

    /**
     * Update label depending on status
     */
    employmentStatusLabel() {
      if (this.form.status.value === 'Retired') {
        return 'When did you retire?';
      } else if (this.form.status.value === 'Unemployed') {
        return 'When did you go into unemployment?';
      }
      return 'When did you start with this company?';
    },

    /**
     * Validates the month and year the applicant moved in
     */
    async validateStartedDate(type, e) {
      if (type === 'month') {
        this.form.startedMonth.value = e.target.value;
      }

      if (type === 'year') {
        this.form.startedYear.value = e.target.value;
      }

      if (this.$refs.month && this.$refs.year) {
        this.$nextTick(async () => {
          const monthIsValid = await this.$refs.month.validate();
          const yearIsValid = await this.$refs.year.validate();
          const startedDateIsValid = monthIsValid.valid && yearIsValid.valid;

          if (startedDateIsValid && this.form.status.value === 'Unemployed') {
            this.$emit('historyAdded', this.mapUnemployedData());
            this.reset();
            return;
          }

          if (startedDateIsValid && this.form.status.value === 'Retired') {
            this.$emit('historyAdded', this.mapRetiredData());
            this.reset();
            return;
          }

          this.form.jobTitle.display = startedDateIsValid;
        });
      } else {
        this.form.jobTitle.display = false;
      }
    },

    /**
     * Saves the employer. If there's not enough employment history, the user will be informed
     */
    saveEmployment() {
      this.$emit('historyAdded', this.mapEmploymentData());

      this.reset();
    },

    /**
     * Resets the address finder form
     */
    reset() {
      // Reset the additional details form
      this.form.address.value = null;
      this.form.status.value = null;
      this.form.startedMonth.value = null;
      this.form.startedYear.value = null;
      this.form.industry.value = null;
      this.form.jobTitle.value = null;
      this.form.industry.display = false;
      this.form.jobTitle.display = false;
      this.$refs.postcoder.reset();
      this.$refs.employmentStatus.reset();

      // TODO: This seems to be causing an issue where the ValidationObserver no longer validates on the 2nd+ try without refreshing
      this.$refs.historyObserver.reset();

      // Clear page errors
      this.pageError = null;
    },

    prepareStartDate(month, year) {
      // If the month is less than 10, pad it with a zero
      let startedMonth = String(month);
      let startedYear = String(year);

      // Padstart prototype not supported with this version of webpack
      if (month < 10) {
        startedMonth = String('0' + startedMonth);
      }

      return moment(startedYear + '-' + startedMonth + '-01');
    },

    /**
     * Prepares a looked up address to be stored as an address
     */
    mapEmploymentData() {
      if (this.manualEntry.enabled) {
        return {
          status: this.form.status.value,
          started: this.prepareStartDate(this.form.startedMonth.value, this.form.startedYear.value),
          type: this.form.industry.value,
          occupation: this.form.jobTitle.value,
          employer: this.form.address.value.employer.value ?? null,
          phone: null,
          building_name: this.form.address.value.building_name.value ?? null,
          building_number: this.form.address.value.building_number.value ?? null,
          flat: null,
          street: this.form.address.value.street.value ?? null,
          county: null,
          locality: null,
          town: this.form.address.value.town.value ?? null,
          postcode: this.form.address.value.postcode.value ?? null,
          country: 'United Kingdom',
        };
      }

      return {
        status: this.form.status.value,
        started: this.prepareStartDate(this.form.startedMonth.value, this.form.startedYear.value),
        type: this.form.industry.value,
        occupation: this.form.jobTitle.value,
        employer: this.form.address.value.organisation ?? null,
        phone: null,
        building_name: this.form.address.value.premise ?? null,
        building_number: this.form.address.value.number ?? null,
        flat: this.form.address.value.flat ?? null,
        street: this.form.address.value.street ?? null,
        county: this.form.address.value.county ?? null,
        locality: null,
        town: this.form.address.value.posttown ?? null,
        postcode: this.form.address.value.postcode ?? null,
        country: 'United Kingdom',
      };
    },

    mapRetiredData() {
      return {
        status: 'Full Time',
        started: this.prepareStartDate(this.form.startedMonth.value, this.form.startedYear.value),
        type: 'Retired',
        occupation: '',
        employer: null,
        phone: null,
        building_name: null,
        building_number: null,
        flat: null,
        street: null,
        county: null,
        locality: null,
        town: null,
        postcode: null,
        country: null,
      };
    },

    mapUnemployedData() {
      return {
        status: 'Unemployed',
        started: this.prepareStartDate(this.form.startedMonth.value, this.form.startedYear.value),
        type: 'Other', // TODO: Make unemployed 'type' dynamic
        occupation: '',
        employer: null,
        phone: null,
        building_name: null,
        building_number: null,
        flat: null,
        street: null,
        county: null,
        locality: null,
        town: null,
        postcode: null,
        country: null,
      };
    },
  },
};
</script>
