var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-finder",attrs:{"id":"address-finder"}},[(_vm.pageError)?_c('div',{},[_c('p',{staticClass:"mt-2 text-red-600"},[_vm._v(_vm._s(_vm.pageError))])]):_vm._e(),_c('ValidationObserver',{ref:"historyObserver",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var invalid = ref.invalid;
var errors = ref.errors;
return [[_c('div',{staticClass:"field",attrs:{"id":"employment-status"}},[_c('validation-provider',{ref:"status",attrs:{"name":"status","rules":_vm.form.status.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("What "+_vm._s(_vm.addressCount > 0 ? 'was' : 'is')+" your employment status?")]),_c('RadioButtons',{ref:"employmentStatus",attrs:{"max-options":6,"options":_vm.form.status.options,"rules":_vm.form.status.rules,"cols":"2"},model:{value:(_vm.form.status.value),callback:function ($$v) {_vm.$set(_vm.form.status, "value", $$v)},expression:"form.status.value"}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.form.status.value !== null &&
          _vm.form.status.value !== 'Retired' &&
          _vm.form.status.value !== 'Unemployed'
        ),expression:"\n          form.status.value !== null &&\n          form.status.value !== 'Retired' &&\n          form.status.value !== 'Unemployed'\n        "}],staticClass:"field",attrs:{"id":"postcode-section"}},[_c('label',{attrs:{"for":"postcode-search"}},[_vm._v("Your "+_vm._s(_vm.addressCount > 0 ? 'previous ' : '')+"employer")]),(!_vm.manualEntry.enabled)?_c('div',{attrs:{"id":"postcode-search"}},[_c('p',{staticClass:"mt-1"},[_vm._v("Search by address, postcode or company name")]),_c('Postcoder',{ref:"postcoder",model:{value:(_vm.form.address.value),callback:function ($$v) {_vm.$set(_vm.form.address, "value", $$v)},expression:"form.address.value"}}),_c('div',{staticClass:"font-bold mt-3 cursor-pointer hover:underline text-right",on:{"click":function($event){_vm.manualEntry.enabled = true}}},[_vm._v(" Can't find the address? ")])],1):[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('validation-provider',{ref:"manual_employer",staticClass:"mb-4",attrs:{"name":"manual_employer_validation","rules":_vm.manualEntry.employer.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"manual-employer"}},[_vm._v("Business name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.manualEntry.employer.value),expression:"manualEntry.employer.value"}],staticClass:"form-input",attrs:{"name":"manual-employer","id":"manual-employer","type":"text"},domProps:{"value":(_vm.manualEntry.employer.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.manualEntry.employer, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"grid grid-cols-2 gap-8 mb-4"},[_c('validation-provider',{ref:"manual_building_name",attrs:{"name":"building name","rules":"required_if_other_empty:@building number","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"manual-building-name"}},[_vm._v("Building name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.manualEntry.building_name.value),expression:"manualEntry.building_name.value"}],staticClass:"form-input",attrs:{"name":"building name","id":"manual-building-name","type":"text"},domProps:{"value":(_vm.manualEntry.building_name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.manualEntry.building_name, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{ref:"manual_building_number",attrs:{"name":"building number","rules":"required_if_other_empty:@building name","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"manual-building-number"}},[_vm._v("Building number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.manualEntry.building_number.value),expression:"manualEntry.building_number.value"}],staticClass:"form-input",attrs:{"name":"building number","id":"manual-building-number","type":"text"},domProps:{"value":(_vm.manualEntry.building_number.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.manualEntry.building_number, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('validation-provider',{ref:"manual_street",staticClass:"mb-4",attrs:{"name":"manual_street_validation","rules":_vm.manualEntry.street.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"manual-street"}},[_vm._v("Street")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.manualEntry.street.value),expression:"manualEntry.street.value"}],staticClass:"form-input",attrs:{"name":"manual-street","id":"manual-street","type":"text"},domProps:{"value":(_vm.manualEntry.street.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.manualEntry.street, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"grid grid-cols-2 gap-8 mb-4"},[_c('validation-provider',{ref:"manual_town",attrs:{"name":"manual_town_validation","rules":_vm.manualEntry.town.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"manual-town"}},[_vm._v("Town")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.manualEntry.town.value),expression:"manualEntry.town.value"}],staticClass:"form-input",attrs:{"name":"manual-town","id":"manual-town","type":"text"},domProps:{"value":(_vm.manualEntry.town.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.manualEntry.town, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{ref:"manual_postcode",attrs:{"name":"manual_postcode_validation","rules":_vm.manualEntry.postcode.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"manual-postcode"}},[_vm._v("Postcode")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.manualEntry.postcode.value),expression:"manualEntry.postcode.value"}],staticClass:"form-input",attrs:{"name":"manual-postcode","id":"manual-postcode","type":"text"},domProps:{"value":(_vm.manualEntry.postcode.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.manualEntry.postcode, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"grid grid-cols-2 gap-8 mb-4 mt-10"},[_c('div',{staticClass:"justify-self-start"},[_c('div',{staticClass:"font-bold mt-3 cursor-pointer hover:underline",on:{"click":function($event){_vm.manualEntry.enabled = false}}},[_vm._v(" Back to search ")])]),_c('div',{staticClass:"justify-self-end"},[_c('button',{staticClass:"btn-primary dealer-bg-primary hover:dealer-bg-secondary hover:dealer-text-primary",class:{ 'opacity-50': invalid },attrs:{"disabled":invalid},on:{"click":_vm.addManualEmploymentAddress}},[_vm._v(" Add ")])])])]}}],null,true)})]],2)]),_c('transition',{attrs:{"name":"fade"}},[(
          _vm.form.address.value ||
          _vm.form.status.value === 'Retired' ||
          _vm.form.status.value === 'Unemployed'
        )?_c('div',{staticClass:"field",attrs:{"id":"employment-date-section"}},[_c('div',{staticClass:"transition duration-150 ease-in-out",attrs:{"id":"field__employment-date"}},[_c('label',{attrs:{"for":"months-dropdown"}},[_c('span',{staticClass:"block",domProps:{"innerHTML":_vm._s(_vm.employmentStatusLabel())}})]),_c('div',{staticClass:"grid grid-cols-2 gap-6 mt-1 sm:mt-3"},[_c('div',[_c('validation-provider',{ref:"month",attrs:{"name":"month","rules":_vm.form.startedMonth.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"months-dropdown"}},[_vm._v("Month")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.startedMonth.value),expression:"form.startedMonth.value"}],staticClass:"form-select text-xl",attrs:{"id":"months-dropdown","name":"months-dropdown","required":""},on:{"input":function($event){return _vm.validateStartedDate('month', $event)},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.startedMonth, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.form.startedMonth.options),function(month,i){return _c('option',{key:i,domProps:{"value":month.value}},[_vm._v(" "+_vm._s(month.label)+" ")])}),0),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('validation-provider',{ref:"year",attrs:{"name":"year","rules":_vm.form.startedYear.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"years-input"}},[_vm._v("Year")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.startedYear.value),expression:"form.startedYear.value"}],staticClass:"form-input text-lg",attrs:{"name":"years-input","id":"years-input","oninput":"if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","type":"tel","maxlength":"4","placeholder":"2019"},domProps:{"value":(_vm.form.startedYear.value)},on:{"keyup":function($event){return _vm.validateStartedDate('year', $event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.startedYear, "value", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(
          _vm.form.jobTitle.display &&
          _vm.form.status.value !== 'Retired' &&
          _vm.form.status.value !== 'Unemployed'
        )?_c('div',{staticClass:"field",attrs:{"id":"job-title-section"}},[_c('validation-provider',{ref:"jobTitleValidation",attrs:{"name":"job-title","rules":_vm.form.jobTitle.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"industry-dropdown"}},[_vm._v("What "+_vm._s(_vm.addressCount > 0 ? 'was' : 'is')+" your job title?")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.jobTitle.value),expression:"form.jobTitle.value",modifiers:{"trim":true}}],staticClass:"form-input text-xl",attrs:{"placeholder":"Job title"},domProps:{"value":(_vm.form.jobTitle.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.jobTitle, "value", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(
          _vm.form.jobTitle.value !== null &&
          _vm.form.jobTitle.value !== '' &&
          _vm.form.status.value !== 'Retired' &&
          _vm.form.status.value !== 'Unemployed'
        )?_c('div',{staticClass:"field",attrs:{"id":"industry-section"}},[_c('validation-provider',{ref:"industry",attrs:{"name":"industry","rules":_vm.form.industry.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('label',{attrs:{"for":"industry-dropdown"}},[_vm._v("Which industry "+_vm._s(_vm.addressCount > 0 ? 'did' : 'do')+" you work in?")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.industry.value),expression:"form.industry.value"}],staticClass:"mt-1 block form-select w-full transition duration-150 ease-in-out cursor-pointer",attrs:{"id":"industry-dropdown","name":"industry-dropdown","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.industry, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.form.industry.options),function(industry,i){return _c('option',{key:i,domProps:{"value":industry.value,"selected":_vm.form.industry.value === industry.value}},[_vm._v(" "+_vm._s(industry.label)+" ")])}),0),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e()]),(_vm.form.industry.value)?_c('div',{staticClass:"form-actions flex"},[_c('button',{staticClass:"dealer-btn-primary ml-auto",on:{"click":function($event){return _vm.saveEmployment()}}},[_vm._v("Save")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }