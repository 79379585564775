<template>
  <main class="resume-application"></main>
</template>

<script>
import Api from '@/lib/Api';
import { stripMobilePrefix } from '@/lib/Helpers';

export default {
  name: 'Resume',

  data() {
    return {
      stepRouteMap: [
        'personal-details',
        'personal-details',
        'phone-verification',
        'about-you',
        'address-history',
        'employment-history',
        'privacy',
      ],
    };
  },

  async mounted() {
    this.$emit('loading', true, 'Picking up where we left off...');

    if (this.$route.query.ref) {
      const ref = this.$route.query.ref;

      try {
        const application = await this.apiGetApplication(ref);

        if (application.complete) {
          return await this.redirect('dashboard');
        }

        // Reset the application
        await this.$store.dispatch('resetJourney');

        // Set the fields
        await this.restoreApplicationData(application);

        // Redirect them to their last step:
        if (application.step) {
          return await this.redirect(application.step);
        } else {
          return this.redirect('apply');
          // TODO: Bugsnag, step was not set in the database... defaulting to first step
          return await this.redirect('apply');
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      return await this.redirect('apply');
    }

    this.$emit('loading', false);
  },

  methods: {
    ...Api,

    async redirect(routeName) {
      this.$emit('loading', false);
      return await this.$router.push({ name: routeName });
    },

    /**
     * Restores application data in the store
     * @param {Object} application
     */
    async restoreApplicationData(application) {
      // Check if they're on the dashboard
      if (application.step === 'dashboard') {
        return await this.redirect('dashboard');
      }

      let applicant = application.applicant;

      // If they have a phone number, strip the prefix
      if (applicant.mobile_phone) {
        applicant.mobile_phone = stripMobilePrefix(applicant.mobile_phone);
      }

      // Put addresses in the correct place in the store
      if (applicant.addresses.length) {
        applicant.address = { addresses: [] };
        applicant.address.addresses = applicant.addresses;
      }

      // Put employment history in the correct place in the store
      if (applicant.employments.length) {
        applicant.employment = { employments: [] };
        applicant.employment.employments = applicant.employments;
      }

      // Retrieve contact preferences if available
      if (application.contactPreferences) {
        const prefs = application.contactPreferences;
        const finalPrefs = {
          messaging_updates: prefs.messaging_updates ? true : false,
          phone_updates: prefs.phone_updates ? true : false,
          phone_offers: prefs.phone_offers ? true : false,
          messaging_offers: prefs.messaging_offers ? true : false,
          partner_sharing: prefs.partner_sharing ? true : false,
        };
        applicant.privacy = finalPrefs;
      }

      delete application.applicant;

      // Restore data
      this.$store.commit('setApplication', application);
      this.$store.commit('setApplicant', applicant);
    },
  },
};
</script>
