var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6 flow-root"},[_c('ul',{attrs:{"role":"list"}},[_vm._m(0),_c('li',[_c('div',{staticClass:"relative pb-8"},[_c('span',{staticClass:"absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"relative flex space-x-3"},[_c('div',[_c('span',{class:{
                'dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center':
                  _vm.dashboard.application.completed,
                'text-white rounded-full bg-gray-300 w-8 h-8 flex justify-center items-center':
                  !_vm.dashboard.application.completed,
              }},[_c('i',{staticClass:"text-md fas fa-check text-white"})])]),_vm._m(1)])])]),_c('li',[_c('div',{staticClass:"relative pb-8"},[_c('span',{staticClass:"absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"relative flex space-x-3"},[_c('div',[_c('span',{class:{
                'text-flow-blue dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center':
                  _vm.dashboard.application.mows_submitted,
                'text-white rounded-full bg-gray-300 w-8 h-8 flex justify-center items-center':
                  !_vm.dashboard.application.mows_submitted,
              }},[(
                  _vm.lastStatus() == 'Converted' ||
                  _vm.lastStatus() == 'Declined' ||
                  _vm.lastStatus() == 'User cancelled'
                )?_c('i',{staticClass:"w-5 h-5 fas fa-check text-white"}):_c('i',{staticClass:"text-md fas fa-hourglass"})])]),_c('div',{staticClass:"min-w-0 flex-1 pt-1 flex justify-between space-x-4"},[_c('div',[_c('p',{staticClass:"text-lg text-flow-blue dealer-text-secondary"},[(_vm.lastStatus())?[_vm._v(" "+_vm._s(_vm.lastStatus())+" ")]:[_vm._v(" In progress ")]],2)])])])])]),(_vm.lastStatus() == 'Declined' || _vm.lastStatus() == 'User cancelled')?_c('li',[_c('div',{staticClass:"relative pb-8"},[_c('span',{staticClass:"absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"relative flex space-x-3"},[_vm._m(2),_c('div',{staticClass:"min-w-0 flex-1 pt-1 flex justify-between space-x-4"},[_c('div',[_c('p',{staticClass:"text-lg text-flow-blue dealer-text-secondary"},[(_vm.hasStatus('Declined'))?[_vm._v(" Application declined ")]:[_vm._v(" Application cancelled ")]],2)])])])])]):_c('li',[_c('div',{staticClass:"relative"},[_c('span',{staticClass:"absolute top-0 left-4 -ml-px h-full w-0.5 bg-gray-300",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"relative flex space-x-3"},[_c('div',[_c('span',{class:{
                'text-flow-blue dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center':
                  _vm.lastStatus() == 'Converted',
                'text-white rounded-full bg-gray-300 w-8 h-8 flex justify-center items-center':
                  _vm.lastStatus() != 'Converted',
              }},[_c('i',{staticClass:"text-md fas fa-check text-white"})])]),_vm._m(3)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"relative pb-8"},[_c('span',{staticClass:"absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-300",attrs:{"aria-hidden":"true"}}),_c('div',{staticClass:"relative flex space-x-3"},[_c('div',[_c('span',{staticClass:"text-flow-blue dealer-text-secondary rounded-full dealer-bg-primary w-8 h-8 flex justify-center items-center"},[_c('i',{staticClass:"text-md fas fa-check text-white"})])]),_c('div',{staticClass:"min-w-0 flex-1 pt-1 flex justify-between space-x-4"},[_c('div',[_c('p',{staticClass:"text-lg text-flow-blue dealer-text-secondary"},[_vm._v("Completed registration")])])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-w-0 flex-1 pt-1 flex justify-between space-x-4"},[_c('div',[_c('p',{staticClass:"text-lg text-flow-blue dealer-text-secondary"},[_vm._v("Applied for finance")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"text-flow-blue dealer-text-secondary rounded-full bg-black w-8 h-8 flex justify-center items-center"},[_c('i',{staticClass:"text-md fas fa-times text-white"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-w-0 flex-1 pt-1 flex justify-between space-x-4"},[_c('div',[_c('p',{staticClass:"text-lg text-flow-blue dealer-text-secondary mb-0"},[_vm._v("Completed")])])])}]

export { render, staticRenderFns }