<template>
  <div class="relative">
    <!--        <div class="flex justify-center w-full" v-if="vehicle.quote_quote_results.submitted">-->
    <!--            <div class="dealer-bg-secondary text-white text-sm text-center py-1 px-4 font-bold rounded-full absolute -top-4 uppercase">-->
    <!--                Submitted-->
    <!--            </div>-->
    <!--        </div>-->
    <div
      @click="$router.push({ name: 'dashboard__calculator__view', params: { quoteId: quote.id } })"
      class="bg-white rounded-md overflow-hidden grid hover:shadow-lg transition-shadow ease-in-out cursor-pointer"
    >
      <div class="col-span-2">
        <div class="p-4">
          <h3 class="mb-0 flex space-x-2 items-center text-flow-blue dealer-text-secondary">
            <span v-if="exists(quote.vehicle.make)">{{ quote.vehicle.make }}</span>
          </h3>
          <p class="mb-0">
            <span v-if="exists(quote.vehicle.model)">{{ quote.vehicle.model }} </span>
            <span v-if="exists(quote.vehicle.transmission)"> | </span>
            <span v-if="exists(quote.vehicle.transmission)">{{ quote.vehicle.transmission }}</span>
            <span v-if="exists(quote.vehicle.colour)"> | </span>
            <span v-if="exists(quote.vehicle.colour)">{{ quote.vehicle.colour }}</span>
          </p>
        </div>

        <div v-if="quote.quote_results.length" class="inline-flex border-t-1 w-full">
          <div v-for="(result, o) in quote.quote_results" :key="o" class="price p-4 w-full">
            <div class="font-bold dealer-text-secondary">{{ result.type }}</div>
            <div class="font-bold text-2xl tracking-tight">
              &pound;{{ getMonthlyPrice(result.monthly_payment)
              }}<span class="text-sm font-lighter"> p/m</span>
            </div>
          </div>
        </div>
        <div class="p-4" v-else>
          Sorry, we couldn't find any personalised deals for this vehicle. Try
          <span class="underline">adjusting your quote</span> and try again.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleted: false,
    };
  },

  props: {
    quote: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getMonthlyPrice(amount) {
      return parseFloat(amount / 100).toLocaleString();
    },

    exists(property) {
      return typeof property !== 'undefined' && property !== null && property.trim() !== '';
    },
  },
};
</script>
