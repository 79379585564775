<template>
  <div class="page page--credit-score">
    <!--Loading-->
    <template v-if="loading">
      <h3 class="mb-8 bg-gray-300 animate-pulse h-8 w-1/4 rounded-full"></h3>
      <div class="md:grid grid-cols-1 sm:grid-cols-2 md:gap-4 mt-5">
        <div v-for="i in 6" :key="i">
          <div class="flex overflow-hidden">
            <div
              class="w-full h-52 overflow-hidden mr-px flex justify-center items-center bg-gray-300 animate-pulse rounded-xxl mb-5"
            ></div>
          </div>
        </div>
      </div>
    </template>

    <!-- Credit report -->
    <template v-if="!loading && creditReport !== null">
      <h2 class="font-xxl mt-5 text-flow-blue dealer-text-secondary">Credit factors</h2>

      <!-- Tips -->
      <div class="cards mt-6 grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- APR card container -->
        <AprCard
          v-if="status && status.aprCard && showApr()"
          :tooltip="status.tooltip"
          :content="status.aprCard"
          :apr="creditReport.apr"
        />

        <!-- Rest of the finance tip cards -->
        <template v-if="creditReport.credit_factors && creditReport.credit_factors.length">
          <TipCard
            v-for="(tip, i) in creditReport.credit_factors"
            :key="i"
            :tip="tip"
            :align="i % 2 === 0 ? 'left' : 'right'"
          />
        </template>
        <div v-else>
          <h3 class="mt-10">
            <strong
              >Sorry, there was an issue retrieving your credit factors. Don't worry, you can still
              continue with your application.</strong
            >
          </h3>
        </div>
      </div>
    </template>

    <!--Error-->
    <template v-if="!loading && creditReport === null">
      <div class="text-center">
        <p class="mt-5 text-lg">
          We're sorry, it looks like there was a problem retrieving your credit report. Don't worry
          though, you can still submit your application.
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TipCard from '@/components/Ui/CreditReport/TipCard';
import AprCard from '@/components/Ui/CreditReport/AprCard';

export default {
  components: {
    TipCard,
    AprCard,
  },

  props: {
    creditReport: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    // When we receive a credit report, calculate score and generate text
    creditReport: {
      handler() {
        this.status = this.calculateStatus();
      },
    },
  },

  data() {
    return {
      status: null,
      progress: {
        value: 0,
        interval: null,
        takingTooLong: false,
      },
    };
  },

  computed: mapState(['dashboard', 'dealer']),

  methods: {
    /**
     * Determines which text should be displayed
     * @returns {{prime: boolean, aprCard: string, tooltip: string, accepted: boolean}|{prime: boolean, aprCard: string, accepted: boolean}|*}
     */
    calculateStatus() {
      const apr = this.getApr();

      let statuses = {
        PRIME: {
          accepted: true,
          prime: true,
          aprCard: `Congratulations! Thanks to your excellent credit score, you qualify for one of our lowest APR rates, ${apr}%. We may need to do a quick affordability check to confirm your APR% rate.`,
          tooltip: null,
        },

        NONPRIME: {
          accepted: false,
          prime: false,
          aprCard: `Congratulations! Thanks to your good credit score, your profile suggests that you qualify for our representative APR rate of ${apr}%. Your dashboard will be updated shortly with your exact APR, once we have completed a quick affordability and employment status check. This only takes a few minutes during business hours, so hang fire. If you can't wait, use our calculator to add cars using an estimated APR.`,
          tooltip: `Good stuff! We have assessed your credit profile and based on the info you have provided, we have an estimate of what your APR rate would be. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. However, each lender has different rules, and to find you the very best deal we need a few more details from you. Once we have this we can confirm your exact APR. Our representative APR for customers with this credit profile is 16.6%, which means that over 51% of people get that rate or better. Please bear in mind that your APR rate could change depending on your circumstances.`,
        },

        APROVERRIDE: {
          accepted: true,
          prime: false,
          tooltip:
            'We have assessed your credit profile and based on the info you have provided, we have updated your dashboard with the very best APR that one of our many lenders has to offer. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. To create your personalised deal, simply add a car!',
          aprCard: `Congratulations! Thanks to your good credit score, your profile suggests that you qualify for our representative APR rate of ${apr}%. Your dashboard has been updated with your exact rate. To create your personalised deal, simply add a car!`,
        },

        UNITYONLY: {
          accepted: true,
          prime: false,
          aprCard: `Congratulations! Thanks to your great credit score, you qualify for an APR rate of approximately ${apr}%. We still need to do a quick affordability and employment status check, which could affect your rate.`,
          tooltip: `Great stuff! We have assessed your credit profile and based on the info you have provided, we have an estimate of what your APR rate would be. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. However, each lender has different rules, and to find you the very best deal we need a few more details from you. Once we have this we can confirm your exact APR. Our representative APR for customers with this credit profile is 16.6%, which means that over 51% of people get that rate or better. Please bear in mind that your APR rate could change depending on your circumstances.`,
        },

        NOSCORE: {
          accepted: false,
          prime: false,
          aprCard: `Sorry, we weren't able to provide you with a credit score, but we could still offer you a fair APR. To find out what it could be, complete your application below. Or, we can work out your exact credit score when you contact our team via email, text, phone or through the chat!`,
        },

        UNITYNONPRIME: {
          accepted: true,
          prime: false,
          tooltip:
            'We have assessed your credit profile and based on the info you have provided, we have updated your dashboard with our very best APR. To create your personalised deal, simply add a car!',
          aprCard: `Congratulations! Thanks to your good credit score, you qualify for an APR rate of ${apr}%. We will be in contact with you shortly to discuss the next steps. To create your personalised deal, simply add a car!`,
        },
      };

      if (apr) {
        // "Learn more" modal text for users without a score but who have an APR estimate or override
        statuses.NOSCORE.tooltip = `We have assessed your credit profile and based on the info you have provided we have estimated what your APR will be. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. However, each lender has different rules and to match you to the very best lender we need a full application with a bit more detail from you. Once we have this we can confirm your exact APR. Our representative APR for customers with this credit profile is ${apr}%.`;

        // APR card texct for users with no score but who have an APR estimate or override
        statuses.NOSCORE.aprCard = `You qualify for a rate of around ${apr}% APR.<br /><small>We'll still need to check affordability and your employment status. Add a car and complete the full application to get your exact APR</small>`;
      }

      if (this.$route.query.override && process.env.NODE_ENV !== 'production') {
        const override = this.$route.query.override;
        if (statuses[override]) {
          return statuses[override];
        }
      }

      if (!this.isPrime() && this.isAprOverrideSet()) {
        if (this.dealer.name) {
          return statuses.UNITYNONPRIME;
        }
        return statuses.APROVERRIDE;
      }

      if (this.dashboard.creditReport.uniscore) {
        return statuses[this.dashboard.creditReport.route];
      }

      return statuses['NOSCORE'];
    },
  },
};
</script>
