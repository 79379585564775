<template>
  <div
    v-if="journey.partner"
    class="sponsor-header"
    :style="{ backgroundColor: hasColour ? journey.partner.branding.colour : 'whitesmoke' }"
  >
    <img
      v-if="hasLogo()"
      :src="`/img/partners/${journey.partner.branding.logo}`"
      alt="Partner logo"
    />
    <div v-else>
      <p class="mb-0"><strong>In collaboration with:</strong></p>
      <h2 class="mt-0">{{ journey.partner.name }}</h2>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.esm.browser';

export default {
  computed: mapState(['journey']),

  data() {
    return {
      branding: null,
      displayBranding: false,
    };
  },

  methods: {
    hasLogo() {
      return !!this.journey.partner.branding?.logo;
    },

    hasColour() {
      return !!this.journey.partner.branding?.colour;
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsor-header {
  padding: 15px 10px;
  background: whitesmoke;
  display: flex;
  justify-content: center;
  text-align: center;

  p {
    font-size: 0.9rem;
  }

  h2 {
    text-transform: none;
    font-size: 1.4rem;
  }

  img {
    max-width: 260px;
    max-height: 50px;
    object-fit: contain;
  }
}
</style>
