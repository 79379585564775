var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"step address-finder",attrs:{"id":"address-finder"}},[(_vm.pageError)?_c('div',{},[_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.pageError))])]):_vm._e(),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.isAddressHistoryEnough() &&
        _vm.journey.applicant.address.addresses &&
        _vm.journey.applicant.address.addresses.length &&
        _vm.shouldValidate
      ),expression:"\n        !isAddressHistoryEnough() &&\n        journey.applicant.address.addresses &&\n        journey.applicant.address.addresses.length &&\n        shouldValidate\n      "}],staticClass:"error-message"},[_c('div',{staticClass:"grid grid-cols-3 gap-4 null"},[_c('div',{staticClass:"col-span-6"},[_c('h4',{staticClass:"font-bold text-xl mb-2 text-white"},[_vm._v("We need a bit more history")]),_c('p',{staticClass:"mb-0"},[_vm._v(" We need at least "+_vm._s(_vm.minHistoryMonths > 0 ? _vm.minHistoryMonths / 12 + ' years' : _vm.minHistoryMonths + ' months')+" of address history to continue. ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isManualEntry || _vm.manualAddressAdded),expression:"isManualEntry || manualAddressAdded"}],attrs:{"id":"manual-entry"}},[_c('label',{attrs:{"for":"manual-entry-inner"}},[_vm._v("Your "),(_vm.savedAddressesCount)?[_vm._v("previous")]:_vm._e(),_vm._v(" address")],2),_c('div',{staticClass:"mt-3",attrs:{"id":"manual-entry-inner"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isManualEntry),expression:"isManualEntry"}]},[_c('ManualAddress',{ref:"manual-address-form",on:{"manual-address-saved":_vm.manualAddressSaved,"use-address-finder":_vm.changeToAddressFinder}})],1),(_vm.manualAddressAdded && !_vm.isManualEntry)?_c('div',[_c('div',{staticClass:"mt-2 text-left sm:py-3 sm:whitespace-no-wrap focus:outline-none active:outline-none inline-flex rounded-full w-full items-center px-4 py-2 text-md leading-normal font-medium transition ease-in-out duration-150 pl-12 pr-5 relative"},[_c('span',{staticClass:"hover:dealer-bg-secondary cursor-pointer dealer-bg-primary text-white p-2 rounded-full w-8 h-8 flex justify-center items-center absolute left-1",on:{"click":_vm.editManualAddress}},[_c('i',{staticClass:"fas fa-trash-alt remove-address text-md"})]),_c('span',{staticClass:"truncate text-ellipsis",attrs:{"title":_vm.form.address.value.buildingName +
                ' ' +
                _vm.form.address.value.buildingNumber +
                ' ' +
                _vm.form.address.value.street +
                ' ' +
                _vm.form.address.value.town +
                ' ' +
                _vm.form.address.value.postcode}},[(_vm.form.address.value.buildingName)?[_vm._v(_vm._s(_vm.form.address.value.buildingName)+", ")]:_vm._e(),(_vm.form.address.value.buildingNumber)?[_vm._v(_vm._s(_vm.form.address.value.buildingNumber)+", ")]:_vm._e(),_vm._v(_vm._s(_vm.form.address.value.street)+", "+_vm._s(_vm.form.address.value.town)+", "+_vm._s(_vm.form.address.value.postcode)+" ")],2)])]):_vm._e()])]),(!_vm.isManualEntry && !_vm.manualAddressAdded)?[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [_c('FormLabel',{attrs:{"for-input":"postcode"}},[_vm._v("Your "),(_vm.savedAddressesCount)?[_vm._v("previous")]:_vm._e(),_vm._v(" postcode")],2),_c('TextInputButton',{attrs:{"rules":"required|min:5|max_no_space:7","id":" postcode","name":"postcode","is-uppercase":true,"validation-mode":"aggressive","valueButton":_vm.form.postcode.loading ? 'Searching...' : 'Lookup',"is-disabled":invalid || _vm.form.postcode.loading,"placeholder":"Search address"},on:{"click":function($event){return _vm.findAddressesByPostcode()}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findAddressesByPostcode()}},model:{value:(_vm.form.postcode.value),callback:function ($$v) {_vm.$set(_vm.form.postcode, "value", $$v)},expression:"form.postcode.value"}}),(_vm.form.postcode.errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.form.postcode.errors[0])+" ")]):_vm._e(),(_vm.form.postcode.errors.length)?_c('div',{staticClass:"mt-5 underline hover:no-underline cursor-pointer",attrs:{"title":""},on:{"click":function($event){_vm.isManualEntry = true}}},[_vm._v(" Add your address details manually ")]):_vm._e()]}}],null,true)}),(_vm.form.postcode.found && !_vm.form.postcode.errors.length)?[_c('div',{staticClass:"mt-7 transition duration-150 ease-in-out",attrs:{"id":"field__address"}},[_c('validation-provider',{ref:"address",attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{attrs:{"for":"available-addresses"}},[_vm._v("Select your address")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address.value),expression:"form.address.value"}],staticClass:"py-2 mt-1 block form-select w-full transition duration-150 ease-in-out cursor-pointer",attrs:{"id":"available-addresses","name":"available-addresses"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.address, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.form.address.options),function(address,i){return _c('option',{key:i,domProps:{"value":i}},[_vm._v(" "+_vm._s(address.display)+" ")])}),0),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]:_vm._e()]:_vm._e(),_c('transition',{attrs:{"name":"fade"}},[((_vm.form.postcode.found && !_vm.form.postcode.errors.length) || _vm.manualAddressAdded)?_c('div',{attrs:{"id":"address-date-section"}},[_c('div',{staticClass:"mt-9 transition duration-150 ease-in-out",attrs:{"id":"field__address-date"}},[_c('label',{attrs:{"for":"months-dropdown"}},[_vm._v("When did you move in?")]),_c('div',{staticClass:"grid grid-cols-2 gap-6 mt-1 sm:mt-3"},[_c('div',[_c('validation-provider',{ref:"month",attrs:{"name":"month","rules":_vm.form.movedInMonth.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{attrs:{"for":"months-dropdown"}},[_vm._v("Month")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.movedInMonth.value),expression:"form.movedInMonth.value"}],staticClass:"form-select text-xl",attrs:{"id":"months-dropdown","name":"months-dropdown"},on:{"input":function($event){return _vm.validateMovedInDate('month', $event)},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.movedInMonth, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.form.movedInMonth.options),function(month,i){return _c('option',{key:i,domProps:{"value":month.value}},[_vm._v(" "+_vm._s(month.label)+" ")])}),0),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',[_c('validation-provider',{ref:"year",attrs:{"name":"year","rules":_vm.form.movedInYear.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{attrs:{"for":"field-surname"}},[_vm._v("Year")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.movedInYear.value),expression:"form.movedInYear.value"}],staticClass:"form-input text-lg",attrs:{"name":"years-input","id":"years-input","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","type":"tel","maxlength":"4","placeholder":"2019"},domProps:{"value":(_vm.form.movedInYear.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.movedInYear, "value", $event.target.value)},function($event){return _vm.validateMovedInDate('year', $event)}]}}),(errors.length)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.residentialStatus.display),expression:"form.residentialStatus.display"}],staticClass:"mt-9 transition duration-150 ease-in-out",attrs:{"id":"field__address-status"}},[_c('label',{staticClass:"mb-3",attrs:{"for":"address-status"}},[_vm._v("What best describes you?")]),_c('RadioButtons',{ref:"residentialStatusField",attrs:{"options":_vm.form.residentialStatus.options,"rules":"required","classes":"sm:grid-cols-2"},on:{"changed":_vm.saveAddress},model:{value:(_vm.form.residentialStatus.value),callback:function ($$v) {_vm.$set(_vm.form.residentialStatus, "value", $$v)},expression:"form.residentialStatus.value"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }