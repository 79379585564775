<template>
  <div class="mt-2">
    <div v-if="fullAddress" class="flex relative flex-grow focus-within:z-10 gap-4">
      <input
        name="fullAddress"
        v-model="localValue.summaryline"
        id="fullAddress"
        disabled="disabled"
        class="bg-gray-50 text-gray-500 form-input mt-0 truncate w-3/4"
      />
      <button class="dealer-btn-primary" @click="reset">Change</button>
    </div>

    <div class="relative flex-grow focus-within:z-10 w-full" v-else>
      <validation-provider rules="required" tag="div" v-slot="{ errors }">
        <input
          name="query"
          v-model="query"
          id="query"
          @keyup="updateResults"
          autocomplete="xyz"
          class="form-input available-employment mt-0 w-full text-2xl sm:leading-5"
          :class="{ 'border-red-300': errors.length }"
        />
        <p class="error-message" v-if="errors && errors.length">{{ errors[0] }}</p>
      </validation-provider>
      <div
        class="absolute right-0 top-0 mt-3 mr-3 spinner rounded-full w-4 h-4 spinner--2"
        v-if="loading"
      ></div>
    </div>

    <div class="relative">
      <!-- Select popover, show/hide based on select state. -->
      <div
        class="absolute mt-2 z-50 w-full bg-white shadow-lg"
        v-if="!fullAddress && results.length"
      >
        <div v-show="loading" class="absolute w-full h-full">
          <div class="absolute w-full h-full z-20 bg-gray-200 opacity-75"></div>
          <div class="absolute w-full h-full flex items-center justify-center z-30">
            <div
              class="spinner rounded-full w-10 h-10 spinner--2"
              style="border: 2px solid #7ac5b6; border-top-color: transparent"
            ></div>
          </div>
        </div>

        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          class="max-h-60 rounded-md py-0 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
        >
          <!--
						Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

						Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
					-->
          <li
            v-if="pathfilter"
            @click="deleteFilter"
            class="bg-gray-100 cursor-pointer text-gray-900 select-none relative py-3 pl-3 pr-9 hover:text-white hover:bg-gray-500"
          >
            Go Back
          </li>
          <li
            @click="selectResult(result)"
            v-for="(result, key) in results"
            :key="key"
            role="option"
            class="cursor-pointer text-gray-900 select-none relative py-3 pl-3 pr-9 hover:text-black bg-white hover:bg-gray-100 border-b border-gray-300"
          >
            <div class="flex items-center justify-between">
              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <div>
                <span class="font-bold text-base leading-normal text-flow-blue">
                  {{ result.summaryline }}
                </span>
                <!-- Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" -->
                <span class="block text-flow-blue text-sm leading-normal">
                  {{ result.locationsummary }}
                </span>
              </div>
              <span
                class="block text-gray-200 text-base leading-normal"
                v-if="!isFullAddress(result)"
                >({{ result.count }} addresses)</span
              >
            </div>

            <span class="hidden absolute inset-y-0 right-0 flex items-center pr-4">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="relative" v-if="false">
      <div class="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg">
        <div class="rounded-md bg-white shadow-xs">
          <ul class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <li
              @click="selectResult(result)"
              v-for="(result, key) in results"
              :key="key"
              class="block cursor-pointer px-4 py-3 text-sm leading-5 text-gray-200 hover:bg-gray-100 hover:text-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-200"
              role="menuitem"
            >
              <span class="text-lg">{{ result.summaryline }}</span>
              <span class="ml-2 text-gray-200">{{ result.locationsummary }}</span>
              <span class="block text-gray-200" v-if="!isFullAddress(result)"
                >({{ result.count }} addresses)</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import axios from 'axios';

export default {
  name: 'PostcoderAutocomplete',
  components: {
    ValidationProvider,
  },
  computed: {
    localValue: {
      get() {
        return this.fullAddress;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  data() {
    return {
      api: {
        base: 'https://ws.postcoder.com/pcw/autocomplete',
        key: process.env.VUE_APP_POSTCODER_API_KEY,
      },
      loading: false,
      query: '',
      pathfilter: null,
      pathfilters: [],
      results: [],
      fullAddress: null,
      minQueryLength: 3,
    };
  },
  methods: {
    isFullAddress(result) {
      return result.type === 'ADD';
    },
    apiGetFullAddress(query, id) {
      let queryString = 'apikey=' + this.api.key + '&country=UK&query=' + query + '&id=' + id;
      const endpoint = this.api.base + '/retrieve?' + queryString;

      return axios.get(endpoint, { withCredentials: false }).then((response) => {
        if (response.data.length) {
          return response.data[0];
        }
        return response.data;
      });
    },
    getQuery() {
      return this.query;
    },
    apiGetResults(query, pathfilter) {
      let queryString = 'apikey=' + this.api.key + '&country=UK&enablefacets=true&query=' + query;
      if (pathfilter) {
        queryString += '&pathfilter=' + pathfilter;
      }
      const endpoint = this.api.base + '/find?' + queryString;

      return axios.get(endpoint, { withCredentials: false }).then((response) => {
        return response.data;
      });
    },
    async updateResults() {
      if (this.query.length < this.minQueryLength) {
        return;
      }
      await this.getResults();
    },
    async getResults() {
      this.loading = true;
      this.results = await this.apiGetResults(this.query, this.pathfilter);
      this.loading = false;
    },
    async selectResult(result) {
      if (this.isFullAddress(result)) {
        this.fullAddress = await this.apiGetFullAddress(this.query, result.id);
        this.localValue = this.fullAddress;
        this.$emit('change');
        return;
      }
      if (this.pathfilter) {
        this.pathfilters.push(this.pathfilter);
      }
      this.pathfilter = result.id;
      await this.getResults();
    },
    deleteFilter() {
      this.pathfilter = this.pathfilters.pop();
      this.getResults();
    },
    reset() {
      this.pathfilters = [];
      this.query = '';
      this.results = [];
      this.fullAddress = null;
      this.localValue = this.fullAddress;
      this.$emit('change');
      setTimeout(() => {
        document.getElementById('query').focus();
      }, 150);
    },
  },
};
</script>

<style scoped></style>
