import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import methods from '@/lib/methods';
import axios from 'axios';

// Journey
import Journey from '@/views/Journey';
import PersonalDetails from '@/components/Journey/PersonalDetails';
import PhoneVerification from '@/components/Journey/PhoneVerification';
import AboutYou from '@/components/Journey/AboutYou';
import AddressHistory from '@/components/Journey/AddressHistory';
import EmploymentHistory from '@/components/Journey/EmploymentHistory';
import Vehicle from '@/components/Journey/Vehicle';
import Privacy from '@/components/Journey/Privacy';
import Resume from '@/views/Resume';
import IncompleteJourney from '@/views/IncompleteJourney.vue';

// Dashboard
import Dashboard from '@/views/Dashboard';
import DashboardHome from '@/components/Dashboard/Home';
import Terms from '@/components/Dashboard/Terms';
import Messages from '@/components/Dashboard/Messages';
import Faqs from '@/components/Dashboard/Faqs';
import BankDetails from '@/components/Dashboard/BankDetails';

// Application Management
import ApplicationManagement from '@/views/ApplicationManagement';
import Applications from '@/components/Applications/Applications';
import ReviewPersonalDetails from '@/components/Applications/ReviewPersonalDetails';
import ReviewAboutYou from '@/components/Applications/ReviewAboutYou';
import ReviewAddressHistory from '@/components/Applications/ReviewAddressHistory';
import ReviewEmploymentHistory from '@/components/Applications/ReviewEmploymentHistory';
import ApplicationsReview from '@/components/Applications/ApplicationsReview';
import CreditReport from '@/views/CreditReport';

// Dashboard calculator
import Calculator from '@/views/Calculator';
import CalculatorQuotes from '@/components/Dashboard/Calculator/CalculatorQuotes';
import AddVehicleManually from '@/components/Dashboard/Calculator/AddVehicleManually';
import AddVehicleReg from '@/components/Dashboard/Calculator/AddVehicleReg';
import QuoteFigures from '@/components/Dashboard/Calculator/QuoteFigures';
import QuoteResults from '@/components/Dashboard/Calculator/QuoteResults';
import ViewQuote from '@/components/Dashboard/Calculator/ViewQuote';

// Dashboard vehicle search
import CarSearch from '@/views/CarSearch';
import CarSearchDetails from '@/components/Dashboard/CarSearch/CarSearchDetails';
import CarSearchResults from '@/components/Dashboard/CarSearch/CarSearchResults';
import CarSearchView from '@/components/Dashboard/CarSearch/CarSearchView';

// Login
import Login from '@/views/Login';
import MagicLogin from '@/views/MagicLogin';

// Errors
import Error from '@/views/Error';
import NotFound from '@/views/NotFound';

Vue.use(VueRouter);

const routes = [
  {
    path: '/incomplete/:routeName?',
    name: 'incomplete',
    component: IncompleteJourney,
  },
  {
    path: '/resume',
    name: 'resume',
    component: Resume,
  },
  {
    path: '/magic',
    name: 'magic',
    component: MagicLogin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/d/:dealer/:next',
    name: 'dealer',
  },
  {
    path: '/',
    redirect: '/personal-details',
    name: 'apply',
    component: Journey,
    children: [
      {
        path: 'personal-details',
        name: 'personal-details',
        component: PersonalDetails,
        meta: {
          supportsDealers: true,
          title: 'Personal Details',
          requiresAuth: false,
          requiresDealer: true,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'Personal details', route: { name: 'personal-details' } },
          ],
        },
      },
      {
        path: 'phone-verification',
        name: 'phone-verification',
        component: PhoneVerification,
        meta: {
          supportsDealers: true,
          title: 'Phone Verification',
          requiresAuth: false,
          requiresDealer: true,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'Phone verification', route: { name: 'phone-verification' } },
          ],
        },
      },
      {
        path: 'about-you',
        name: 'about-you',
        component: AboutYou,
        meta: {
          supportsDealers: true,
          title: 'About You',
          requiresAuth: false,
          requiresDealer: true,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'About you', route: { name: 'about-you' } },
          ],
        },
      },
      {
        path: 'address-history',
        name: 'address-history',
        component: AddressHistory,
        meta: {
          supportsDealers: true,
          title: 'Address History',
          requiresAuth: false,
          requiresDealer: true,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'Address history', route: { name: 'address-history' } },
          ],
        },
      },
      {
        path: 'employment-history',
        name: 'employment-history',
        component: EmploymentHistory,
        meta: {
          supportsDealers: true,
          title: 'Employment History',
          requiresAuth: false,
          requiresDealer: true,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'Employment history', route: { name: 'employment-history' } },
          ],
        },
      },
      {
        path: 'vehicle',
        name: 'vehicle',
        component: Vehicle,
        meta: {
          supportsDealers: false,
          title: 'Your vehicle',
          requiresAuth: false,
          requiresDealer: false,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'Your vehicle', route: { name: 'vehicle' } },
          ],
        },
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
          supportsDealers: true,
          title: 'Privacy',
          requiresAuth: false,
          requiresDealer: true,
          breadcrumbs: [
            { title: 'Apply', route: { name: 'personal-details' } },
            { title: 'Privacy', route: { name: 'privacy' } },
          ],
        },
      },
    ],
  },
  {
    path: '/dashboard/:reference/credit-score',
    name: 'credit-score',
    component: CreditReport,
    meta: {
      supportsDealers: true,
      title: 'Dashboard Credit Score',
      bannerTitle: 'Credit Score',
      banner: 'credit',
      requiresAuth: true,
      breadcrumbs: [
        { title: 'Home', route: { name: 'home' } },
        { title: 'Credit report', route: { name: 'credit-score' } },
      ],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: '/applications/manage',
    requiresAuth: true,
  },
  {
    path: '/dashboard/:reference',
    name: 'application-dashboard',
    redirect: '/dashboard/:reference/home',
    component: Dashboard,
    meta: {
      breadcrumb: 'Dashboard',
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: DashboardHome,
        meta: {
          supportsDealers: true,
          title: 'Dashboard - Home',
          bannerTitle: 'Dashboard',
          banner: 'status',
          requiresAuth: true,
          columns: 2,
          breadcrumbs: [{ title: 'Home', route: { name: 'home' } }],
        },
      },
      // {
      // 	path: 'credit-score',
      // 	name: 'credit-score',
      // 	component: CreditScore,
      // 	meta: {
      //         supportsDealers: true,
      //         title: 'Dashboard Credit Score',
      //         bannerTitle: 'Credit Score',
      // 		banner: 'credit',
      // 		requiresAuth: true,
      // 		breadcrumbs: [
      // 			{ title: 'Home', route: { name: 'home'} },
      // 			{ title: 'Credit report', route: { name: 'credit-score'} }
      // 		]
      // 	}
      // },
      {
        path: 'terms',
        name: 'dashboard__terms',
        component: Terms,
        meta: {
          supportsDealers: true,
          title: 'Dashboard - Acceptance Terms',
          bannerTitle: 'Acceptance Terms',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [
            { title: 'Home', route: { name: 'home' } },
            { title: 'Terms', route: { name: 'dashboard__terms' } },
          ],
        },
      },
      {
        path: 'messages',
        name: 'dashboard__messages',
        component: Messages,
        meta: {
          supportsDealers: true,
          title: 'Dashboard - Messages',
          bannerTitle: 'Messages',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [
            { title: 'Home', route: { name: 'home' } },
            { title: 'Messages', route: { name: 'messages' } },
          ],
        },
      },
      {
        path: 'faqs',
        name: 'dashboard__faqs',
        component: Faqs,
        meta: {
          supportsDealers: true,
          title: 'Dashboard - FAQs',
          bannerTitle: 'FAQs',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [
            { title: 'Home', route: { name: 'home' } },
            { title: 'FAQs', route: { name: 'dashboard__faqs' } },
          ],
        },
      },
      {
        path: 'bank-details',
        name: 'dashboard__bank',
        component: BankDetails,
        meta: {
          supportsDealers: true,
          title: 'Dashboard Bank Details',
          bannerTitle: 'Bank Details',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [
            { title: 'Home', route: { name: 'home' } },
            { title: 'Bank Details', route: { name: 'dashboard__bank' } },
          ],
        },
      },
      {
        path: 'calculator',
        name: 'calculator',
        component: Calculator,
        redirect: '/dashboard/:reference/calculator/quotes',
        meta: {
          banner: 'default',
          requiresAuth: true,
          breadcrumb: 'Calculator',
        },
        children: [
          {
            path: 'quotes',
            name: 'dashboard__calculator__quotes',
            component: CalculatorQuotes,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - Quote Calculator',
              bannerTitle: 'Quote Calculator',
              banner: 'default',
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Quotes', route: { name: 'dashboard__calculator__quotes' } },
              ],
            },
          },
          {
            path: 'add-vehicle-manually',
            name: 'dashboard__calculator__add-vehicle-manually',
            component: AddVehicleManually,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - Add Vehicle',
              bannerTitle: 'Add vehicle',
              banner: 'default',
              columns: 2,
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Quotes', route: { name: 'dashboard__calculator__quotes' } },
                {
                  title: 'Add vehicle',
                  route: { name: 'dashboard__calculator__add-vehicle-manually' },
                },
              ],
            },
          },
          {
            path: 'add-vehicle-reg',
            name: 'dashboard__calculator__add-vehicle-reg',
            component: AddVehicleReg,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - Add Reg',
              bannerTitle: 'Add vehicle',
              banner: 'default',
              columns: 2,
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Quotes', route: { name: 'dashboard__calculator__quotes' } },
                { title: 'Add vehicle', route: { name: 'dashboard__calculator__add-vehicle-reg' } },
              ],
            },
          },
          {
            path: 'figures',
            name: 'dashboard__calculator__figures',
            component: QuoteFigures,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - Quote Figures',
              bannerTitle: 'Quote figures',
              banner: 'default',
              columns: 2,
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Quotes', route: { name: 'dashboard__calculator__quotes' } },
                {
                  title: 'Add vehicle',
                  route: {
                    name:
                      store.state.dashboard.calculator.vehicle?.addedManually === true
                        ? 'dashboard__calculator__add-vehicle-manually'
                        : 'dashboard__calculator__add-vehicle-reg',
                  },
                },
                { title: 'Figures', route: { name: 'dashboard__calculator__figures' } },
              ],
            },
          },
          {
            path: 'results',
            name: 'dashboard__calculator__results',
            component: QuoteResults,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - Quote Results',
              bannerTitle: 'Quote results',
              banner: 'default',
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Quotes', route: { name: 'dashboard__calculator__quotes' } },
                {
                  title: 'Add vehicle',
                  route: {
                    name:
                      store.state.dashboard.calculator.vehicle?.addedManually === true
                        ? 'dashboard__calculator__add-vehicle-manually'
                        : 'dashboard__calculator__add-vehicle-reg',
                  },
                },
                { title: 'Figures', route: { name: 'dashboard__calculator__figures' } },
                { title: 'Results', route: { name: 'dashboard__calculator__results' } },
              ],
            },
          },
          {
            path: 'view/:quoteId',
            name: 'dashboard__calculator__view',
            component: ViewQuote,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - View Quote',
              bannerTitle: 'Quote results',
              banner: 'default',
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Quotes', route: { name: 'dashboard__calculator__quotes' } },
              ],
            },
          },
        ],
      },

      {
        path: 'search',
        name: 'search',
        component: CarSearch,
        redirect: '/dashboard/:reference/search/details',
        children: [
          {
            path: 'details',
            name: 'dashboard__search__details',
            component: CarSearchDetails,
            meta: {
              supportsDealers: true,
              title: 'Dashboard Car Search Details',
              bannerTitle: 'Car search',
              banner: 'default',
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Vehicle Search', route: { name: 'dashboard__search__details' } },
              ],
            },
          },
          {
            path: 'results',
            name: 'dashboard__search__results',
            component: CarSearchResults,
            meta: {
              supportsDealers: true,
              title: 'Dashboard Car Search Results',
              bannerTitle: 'Car search',
              banner: 'default',
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Vehicle Search', route: { name: 'search' } },
                { title: 'Vehicle Results', route: { name: 'dashboard__search__results' } },
              ],
            },
          },
          {
            path: 'vehicle/:vehicleId',
            name: 'dashboard__search__view',
            component: CarSearchView,
            meta: {
              supportsDealers: true,
              title: 'Dashboard - Car View',
              bannerTitle: 'Car search',
              banner: 'default',
              requiresAuth: true,
              breadcrumbs: [
                { title: 'Home', route: { name: 'home' } },
                { title: 'Vehicle Search', route: { name: 'search' } },
                { title: 'Vehicle Details', route: { name: 'dashboard__search__view' } },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Dashboard - Login',
      requiresAuth: false,
      breadcrumb: 'Login',
    },
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationManagement,
    redirect: '/applications/manage',
    children: [
      {
        path: 'manage',
        name: 'applications__manage',
        component: Applications,
        meta: {
          supportsDealers: true,
          title: 'Applications',
          bannerTitle: 'Applications',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [{ title: 'Applications' }],
        },
      },
      {
        path: 'review',
        name: 'applications__review',
        component: ApplicationsReview,
        meta: {
          supportsDealers: true,
          title: 'Applications Review',
          bannerTitle: 'Applications Review',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [{ title: 'Applications Review' }],
        },
      },
      {
        path: 'personal-details',
        name: 'applications__review_personal_details',
        component: ReviewPersonalDetails,
        meta: {
          supportsDealers: true,
          title: 'Review Personal Details',
          bannerTitle: 'Review Personal Details',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [{ title: 'Review Personal Details' }],
        },
      },
      {
        path: 'about-you',
        name: 'applications__review_about_you',
        component: ReviewAboutYou,
        meta: {
          supportsDealers: true,
          title: 'Review About You',
          bannerTitle: 'Review About You',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [{ title: 'Review About You' }],
        },
      },
      {
        path: 'address-history',
        name: 'applications__review_address_history',
        component: ReviewAddressHistory,
        meta: {
          supportsDealers: true,
          title: 'Review Address History',
          bannerTitle: 'Review Address History',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [{ title: 'Review Address History' }],
        },
      },
      {
        path: 'employment-history',
        name: 'applications__review_employment_history',
        component: ReviewEmploymentHistory,
        meta: {
          supportsDealers: true,
          title: 'Review Employment History',
          bannerTitle: 'Review Employment History',
          banner: 'default',
          requiresAuth: true,
          breadcrumbs: [{ title: 'Review Employment History' }],
        },
      },
    ],
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: {
      title: 'Error',
      requiresAuth: false,
    },
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: 'Page Not Found - 404',
      requiresAuth: false,
      columns: 1,
      breadcrumbs: [{ title: 'Home', route: { name: 'apply' } }, { title: '404 Page Not Found' }],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_FOLDER,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // If the user's cached dealer is Unity, and they're not visiting the dealer referral page
  if (store.state.dealer.slug === 'unity' && to.name !== 'dealer') {
    window.location.href =
      process.env.NODE_ENV === 'production'
        ? 'https://unityauto.finance/maintenance'
        : 'https://dev.unityauto.finance/maintenance';
    return;
  }

  // Store any GET vars as application properties so they can be sent to the API if they continue.
  if (Object.keys(to.query).length !== 0) {
    await store.dispatch('resetApplicationProperties');
    Object.keys(to.query).forEach((key) => {
      store.dispatch('addApplicationProperty', {
        key: key,
        value: to.query[key],
      });
    });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth ?? false) &&
    !store.state.dashboard.authed &&
    typeof to.params.dealer !== 'undefined'
  ) {
    next({ name: 'login' });
    return;
  }

  if (typeof to.params.dealer !== 'undefined' && store.state.dealer.slug !== to.params.dealer) {
    await store.dispatch('logout');
    await methods.cacheDealer(to.params.dealer);
    next({ name: 'login' });
  }

  if (to.params.dealer && to.params.next) {
    // Get dealer data if they've been referred
    await methods.cacheDealer(to.params.dealer);
    to.query.dealer = 'yes';
    next({ name: to.params.next, query: to.query });
    return;
  }

  // If we have a dealer stored locally, check if we should refresh it
  const now = Math.round(new Date().getTime() / 1000);
  const dealer = store.state.dealer;
  if (
    (dealer.slug !== null && dealer.updatedAt && dealer.updatedAt + 1800 < now) ||
    typeof dealer.updatedAt === 'undefined'
  ) {
    await methods.cacheDealer(store.state.dealer.slug);
  }

  // If a partner referral has been passed in the URL, store it
  if (to.query.partner) {
    let payload = {};

    // Check if a TDUID GET parameter has been passed in the URL and store it
    if (to.query.tduid) {
      payload.tduid = to.query.tduid;
    }

    // Set the partner
    await store.commit('setPartner', {
      id: to.query.partner,
      referredAt: now,
      ...payload,
    });
  }

  // If a partner has been saved locally and is older than 24 hours, reset it
  const partner = store.state.journey.partner;
  if (partner && partner.id && partner.referredAt && now > partner.referredAt + 86400) {
    await store.dispatch('resetJourneyPartner');
  }

  // Redirect to dsgfinance if dealer name is not set
  if (store.state.dealer.name === null) {
    return (window.location = 'https://www.dsgfinance.group/');
  }

  next();
});

router.afterEach(async (to) => {
  // Scroll to the top of the page
  window.scrollTo(0, 0);

  let title = to.meta.title || 'Secure Your Finance First | How It works | Car Finance Broker UK';
  if (store.state.dealer.name && to.meta.supportsDealers) {
    title += ` | ${store.state.dealer.name}`;
  } else {
    title += ` | Magnitude Finance`;
  }
  document.title = title;

  // Get application updates in the background on each dashboard page load
  if (to.path.indexOf('dashboard') !== -1) {
    axios
      .get(methods.getApiBaseUrl() + '/me/applications/' + to.params.reference)
      .then(async (r) => {
        if (
          r.data.success &&
          typeof r.data.data.application !== 'undefined' &&
          typeof r.data.data.applicant !== 'undefined'
        ) {
          await store.commit('setDashboardApplication', r.data.data.application);
        }
      });
  }

  // Track pages and push to Hubspot
  methods.hubspotPush('trackPageView');
});

export default router;
