<template>
  <section
    id="hero"
    class="hero pl-6 pr-8 w-full flex py-10 bg-flow-blue dealer-bg-banner text-white relative"
  >
    <canvas class="confetti" ref="confetti_canvas" />
    <div class="relative w-full flex flex-col mx-auto max-w-7xl">
      <div class="flex flex-col">
        <!-- They haven't applied yet... -->
        <div
          v-if="
            !dashboard.application.completed ||
            (isPrime() && !$store.state.dashboard.application.has_applied_with_real_quote)
          "
          class="mt-8 md:mt-16"
        >
          <!--Loading credit report...-->
          <template v-if="loading.creditReport">
            <div class="text-center">
              <span
                style="
                  border-color: rgba(255, 255, 255, 0.2);
                  border-top-color: white;
                  border-width: 3px;
                "
                class="spinner inline-block relative rounded-full w-16 h-16 spinner--2"
              ></span>
              <p class="mt-5 text-lg text-flow-white">Preparing your updates...</p>
            </div>
          </template>

          <!--Got a credit report...-->
          <div v-else-if="creditReport !== null && display.creditScoreAnimation" class="sm:flex">
            <!-- First column -->
            <div class="sm:mr-12 text-center">
              <vue-ellipse-progress
                v-if="creditReport.uniscore !== null && creditReport.uniscore > 0 && scoreRating"
                emptyColor="#696b8a"
                :size="150"
                :color="scoreRating.color"
                :progress="scorePercentage"
                :legendValue="creditReport.uniscore"
                :thickness="3"
                lineMode="in 3	"
                dash="1 2.98"
                :angle="90"
                :legendClass="
                  'font-bold text-4xl -mt-3 rating-' + scoreRating.caption.toLowerCase()
                "
              >
                <span slot="legend-value" />
                <p slot="legend-caption" class="text-gray-300 text-sm -mt-1 mb-0">
                  out of <strong>{{ maxScore }}</strong>
                </p>
              </vue-ellipse-progress>
              <h3 v-if="creditReport.apr && showApr()" class="text-white">
                {{ creditReport.apr }}%<small class=""> APR</small>
              </h3>
            </div>
            <div v-if="creditStatus !== null" class="pb-8 max-w-7xl sm:w-3/4 mt-5 sm:mt-0">
              <h3 class="text-white font-bold text-3xl">{{ creditStatus.title }}</h3>
              <p class="text-white text-lg my-3">{{ creditStatus.text }}</p>
              <div class="grid grid-cols-1 sm:grid-cols-2 sm:flex mt-6">
                <button
                  v-if="shouldDisplayFeed()"
                  :is-disabled="false"
                  @click="
                    $router.push({
                      name: 'search',
                      params: {
                        reference:
                          $route.params.reference || $store.state.dashboard.application.reference,
                      },
                    })
                  "
                  class="border-flow-white text-flow-white dealer-btn-primary-outer"
                >
                  Vehicle Search
                </button>
                <button
                  v-else
                  :is-disabled="false"
                  @click="
                    $router.push({
                      name: 'calculator',
                      params: {
                        reference:
                          $route.params.reference || $store.state.dashboard.application.reference,
                      },
                    })
                  "
                  class="border-flow-white text-flow-white dealer-btn-primary-outer mb-4 mr-0 sm:mb-0 sm:mr-4"
                >
                  Add a car
                </button>
              </div>
            </div>
          </div>

          <!--Failed to get credit report...-->
          <template v-else>
            <h1 class="text-2xl md:text-4xl leading-9 font-extrabold text-white md:inline-block">
              <span>Something went wrong...</span>
            </h1>
            <p class="text-white text-base my-3">
              We're sorry, it looks like we ran into a technical issue when fetching your credit
              report. If this problem persists, please do not hesitate to get in touch...
            </p>
          </template>
        </div>

        <!-- They've applied, show their latest status -->
        <div v-else>
          <template v-if="currentStatus !== null">
            <h1 class="text-white">{{ currentStatus.title }}</h1>
            <p class="text-white">{{ currentStatus.description }}</p>
          </template>
          <template v-else>
            <template v-if="isPrime() || dashboard.application.has_applied">
              <h1 class="text-white">Reviewing application</h1>
              <p>
                Your application is being looked at by a member of our team. We'll update you and
                your dashboard shortly.
              </p>
            </template>
            <template v-else>
              <template v-if="isAprOverrideSet()">
                <div class="sm:flex" v-if="creditReport !== null">
                  <!-- First column -->
                  <div class="sm:mr-12 text-center">
                    <vue-ellipse-progress
                      v-if="
                        creditReport.uniscore !== null && creditReport.uniscore > 0 && scoreRating
                      "
                      emptyColor="#696b8a"
                      :size="150"
                      :color="scoreRating.color"
                      :progress="scorePercentage"
                      :legendValue="creditReport.uniscore"
                      :thickness="3"
                      lineMode="in 3	"
                      dash="1 2.98"
                      :angle="90"
                      :legendClass="
                        'font-bold text-4xl -mt-3 rating-' + scoreRating.caption.toLowerCase()
                      "
                    >
                      <span slot="legend-value" />
                      <p slot="legend-caption" class="text-gray-300 text-sm -mt-1 mb-0">
                        out of <strong>{{ maxScore }}</strong>
                      </p>
                    </vue-ellipse-progress>
                    <h3 v-if="creditReport.apr && showApr()" class="text-white">
                      {{ creditReport.apr }}%<small class=""> APR</small>
                    </h3>
                  </div>
                  <div>
                    <h1 class="text-white">Application accepted</h1>
                    <p v-if="dealer.name" class="text-white">
                      <template v-if="showApr()">
                        Great news! We have completed a soft search of your credit profile and you
                        are accepted in principle with the APR shown. You can
                        <a
                          href="javascript:void(0)"
                          @click="$router.push({ name: 'dashboard__calculator__quotes' })"
                          class="underline font-bold"
                          >add a car</a
                        >
                        now whilst we do our final checks. We will be in touch shortly.
                      </template>
                      <template v-else>
                        Great news you are accepted in principle. Add a vehicle to create your
                        personalised deal.
                      </template>
                    </p>
                    <p v-else class="text-white">
                      Great news! We've soft searched our whole panel of lenders to find the best
                      APR for you. Just
                      <a
                        href="javascript:void(0)"
                        @click="$router.push({ name: 'dashboard__calculator__quotes' })"
                        class="underline font-bold"
                        >add a car</a
                      >
                      to get started or view your best, personalised rate
                      <a
                        href="javascript:void(0)"
                        @click="$router.push({ name: 'credit-score' })"
                        class="underline font-bold"
                        >here</a
                      >.
                    </p>

                    <div class="pt-8">
                      <a
                        href="javascript:void(0)"
                        @click="$router.push({ name: 'dashboard__calculator__quotes' })"
                        class="hover:bg-white hover:text-flow-blue rounded-full border-white text-white dealer-btn-primary-outer mr-4"
                        >Add a car</a
                      >

                      <a
                        href="javascript:void(0)"
                        @click="$router.push({ name: 'credit-score' })"
                        class="hover:bg-white hover:text-flow-blue rounded-full border-white text-white dealer-btn-primary-outer"
                        >My deal</a
                      >
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <h1 class="text-white">Reviewing application</h1>
                <template v-if="dealer.name">
                  <!--Unity-->
                  <p class="text-white" v-if="dealer.name === 'Unity'">
                    Hold tight! We're still soft searching for the best rate for you. We'll normally
                    do this in under 20 minutes. You'll get a text or a call to let you know when
                    this is done. You can then add the car you've chosen and work out your own
                    personalised deal.
                  </p>
                  <!--Regular dealer-->
                  <p class="text-white" v-else>
                    Hold tight we're on it. We're working on your application and we'll update your
                    dashboard shortly. This normally takes up to 30 minutes during business hours.
                  </p>
                </template>
                <!--Flow-->
                <p v-else class="text-white">
                  Hold tight! We're still soft searching our panel of 20 lenders for the best rate
                  for you. We'll normally do this in 20 minutes or less. You'll get a text or a call
                  to let you know we're done and you can then add a car you've chosen or use our car
                  search to find the best deal for you from over 300,000 cars.
                </p>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex/dist/vuex.esm.browser';
import Api from '@/lib/Api';
import Bugsnag from '@bugsnag/js';
import confetti from 'canvas-confetti';

export default {
  computed: mapState(['dashboard', 'dealer']),

  data() {
    return {
      creditReport: null,
      display: {
        creditScoreAnimation: false,
      },
      loading: {
        creditReport: false,
      },
      creditStatus: null,
      currentStatus: null,
      maxScore: 725,
      scorePercentage: 0,
      scoreRating: null,
      scoreRanges: [
        {
          min: 625,
          color: '#5cbf6d',
          caption: 'Excellent',
          title: 'You are very likely to be accepted by one of our lenders!',
        },
        {
          min: 605,
          color: '#5cbf6d',
          caption: 'Good',
          title: 'You are likely to be accepted by one of our lenders!',
        },
        {
          min: 570,
          color: '#d9d755',
          caption: 'Fair',
          title: "Let's find you a deal!",
        },
        {
          min: 0,
          color: '#d9d755',
          caption: 'Poor',
          title: 'See how to improve your score below.',
        },
      ],
    };
  },

  methods: {
    ...Api,

    async fetchCreditReport() {
      this.loading.creditReport = true;
      try {
        this.creditReport = await this.apiGetCreditReport(this.dashboard.application.reference);
        this.scoreRating = this.calculateScoreRating(this.creditReport.uniscore);
        this.scorePercentage = this.getPercentageOf(this.creditReport.uniscore, this.maxScore);
        this.$nextTick(() => {
          this.display.creditScoreAnimation = true;
        });
      } catch (e) {
        Bugsnag.notify(e);
      }
      this.loading.creditReport = false;
    },

    /**
     * Selects a score range object based on the user's score
     * @param {Number} score The user's score
     * @returns {Object} Score range
     */
    calculateScoreRating(score) {
      let selectedRange = null;

      for (let range of this.scoreRanges) {
        if (score >= range.min) {
          selectedRange = range;
          break;
        }
      }

      // Default to the last score range (should be lowest) if we can't handle the score (because it's too low)
      return selectedRange ?? this.scoreRanges[this.scoreRanges.length - 1];
    },

    calculateStatus() {
      const apr = this.creditReport.apr;
      let firstName = this.$store.state.dashboard.applicant.first_name;
      firstName = firstName[0].toUpperCase() + firstName.substring(1);
      const statusTitle = firstName + ', complete your deal';

      let statuses = {
        PRIME: {
          title: statusTitle,
          text: this.showApr()
            ? `Your credit profile looks good and we have a choice of lenders at ${apr}% APR. Add a vehicle to create your personalised deal.`
            : `Your credit profile looks good and we have a choice of lenders for you. Add a vehicle to create your personalised deal.`,
        },
        NONPRIME: {
          title: statusTitle,
          text: this.showApr()
            ? `Great news! Your credit profile looks OK and matches a choice of lenders from ${apr}% APR. Add a car to create your personalised deal.`
            : `Great news! Your credit profile looks OK and matches a choice of lenders. Add a car to create your personalised deal.`,
        },
        UNITYONLY: {
          title: statusTitle,
          text:
            'Great news! Your credit profile looks good and we have a choice of lenders at ' +
            apr +
            '% APR. Add a vehicle to create your personalised deal.',
        },
        APROVERRIDE: {
          title: this.showApr()
            ? `Great news, you're accepted in principle at ${apr}% APR`
            : `Great news, you're accepted in principle`,
          text: `Your next step is to add a car and choose the best deal for you. We'll always show you the lowest monthly payment from our panel of lenders, but the APR may vary a little.`,
        },
        NOSCORE: {
          title: statusTitle,
          text: "We weren't able to calculate your estimated APR right now but don't worry, you can still complete your application by adding a vehicle below.",
        },
      };

      if (this.creditReport.automation_route && apr !== null && !this.isAprOverrideSet()) {
        return statuses[this.creditReport.automation_route];
      } else if (this.isAprOverrideSet()) {
        return statuses['APROVERRIDE'];
      }

      return statuses.NOSCORE;
    },

    /**
     * Initialises the confetti library
     * @param ref
     */
    initConfetti(ref) {
      this.confetti = confetti.create(ref, {
        resize: true,
        useWorker: true,
      });
    },

    /**
     * Starts fireworks
     */
    fireConfetti() {
      const duration = 2.5 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      let interval = setInterval(function () {
        let timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 150 * (timeLeft / duration);

        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: Math.random() * (0.1 - 0.4) + 0.1, y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: Math.random() * (0.7 - 0.9) + 0.7, y: Math.random() - 0.2 },
          })
        );
      }, 250);
    },
  },

  async mounted() {
    // Fetch their latest credit report
    await this.fetchCreditReport();

    // Now calculate their credit status
    this.creditStatus = this.calculateStatus();

    // Do they have any statuses?
    if (this.dashboard.application.statuses.length) {
      this.currentStatus =
        this.dashboard.application.statuses[this.dashboard.application.statuses.length - 1];
    }

    // Should we celebrate?
    if (!this.dashboard.hasCelebrated && this.isAprOverrideSet()) {
      this.initConfetti(this.$refs.confetti_canvas);
      this.fireConfetti();
      this.$store.commit('setHasCelebrated', true);
    }
  },
};
</script>
