<template>
  <section id="hero" class="hero bg-flow-blue dealer-bg-banner w-full relative">
    <div class="max-w-7xl mx-auto text-white py-16 px-4 sm:px-6 xl:px-0 w-full">
      <h1 class="font-title relative text-3xl text-white">{{ banner_title }}</h1>
      <h4 v-if="banner_subtitle" class="mt-4 font-title relative text-2xl text-white font-light">
        {{ banner_subtitle }}
      </h4>
      <div v-if="banner_button" class="mt-8">
        <span class="flex justify-start">
          <button
            @click="$router.push({ name: 'car__search' })"
            type="submit"
            class="button border-2 border-flow-teal text-flow-blue bg-flow-teal hover:bg-flow-blue hover:text-white"
          >
            {{ banner_button }}
          </button>
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    banner_title: {
      type: String,
      default: null,
    },
    banner_subtitle: {
      type: String,
      default: null,
    },
    banner_button: {
      type: String,
      default: null,
    },
    banner_small_text: {
      type: String,
      default: null,
    },
    banner_image: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASEURL,
    };
  },
};
</script>
