<template>
  <section
    id="hero"
    class="hero pl-6 pr-8 w-full flex pt-10 pb-8 relative bg-flow-blue dealer-bg-banner"
  >
    <div class="relative w-full mx-auto max-w-7xl my-8 md:mt-16 items-center">
      <!--Loading credit report...-->
      <template v-if="loading">
        <div class="text-center">
          <span
            style="
              border-color: rgba(255, 255, 255, 0.2);
              border-top-color: white;
              border-width: 3px;
            "
            class="spinner inline-block relative rounded-full w-16 h-16 spinner--2"
          ></span>
          <p class="mt-5 text-lg text-flow-white">Fetching your latest report...</p>
        </div>
      </template>

      <div
        v-if="!loading && creditReport !== null && display.creditScoreAnimation"
        class="text-center sm:text-left sm:flex items-center"
      >
        <!-- First column -->
        <div class="sm:mr-12">
          <vue-ellipse-progress
            emptyColor="#696b8a"
            :size="180"
            :color="scoreRating.color"
            :progress="scorePercentage"
            :legendValue="creditReport.uniscore"
            :thickness="3"
            lineMode="in 5"
            dash="1 3.85"
            :angle="90"
            :legendClass="'font-bold text-5xl -mt-4 rating-' + scoreRating.caption.toLowerCase()"
          >
            <span slot="legend-value" />
            <p slot="legend-caption" class="text-gray-300 text-lg -mt-1 mb-0">
              out of <strong>{{ maxScore }}</strong>
            </p>
          </vue-ellipse-progress>
        </div>

        <!-- Second column -->
        <div class="mt-4 sm:mt-0 xl:w-1/2">
          <!-- Got an APR -->
          <template v-if="status !== null">
            <h3 class="text-white font-bold text-3xl">{{ status.title }}</h3>
            <p class="text-white text-base my-3">{{ status.text }}</p>

            <div class="pb-8 pt-3 max-w-7xl sm:w-full" v-if="!dashboard.application.completed">
              <button
                v-if="shouldDisplayFeed()"
                :is-disabled="false"
                @click="
                  $router.push({
                    name: 'search',
                    params: {
                      reference:
                        $route.params.reference || $store.state.dashboard.application.reference,
                    },
                  })
                "
                class="border-flow-white text-flow-white w-full dealer-btn-primary-outer sm:w-auto text-xl py-3"
              >
                Vehicle Search
              </button>
              <button
                v-else
                :is-disabled="false"
                @click="
                  $router.push({
                    name: 'calculator',
                    params: {
                      reference:
                        $route.params.reference || $store.state.dashboard.application.reference,
                    },
                  })
                "
                class="border-flow-white text-flow-white w-full dealer-btn-primary-outer sm:w-auto text-xl py-3"
              >
                Add a car
              </button>
            </div>
          </template>
        </div>
      </div>

      <!--Failed to get credit report...-->
      <template v-if="!loading && creditReport === null">
        <h1 class="text-2xl md:text-4xl leading-9 font-extrabold text-white md:inline-block">
          <span>Something went wrong...</span>
        </h1>
        <p class="text-white text-base my-3">
          We're sorry, it looks like we ran into a technical issue when fetching your credit report.
          If this problem persists, please do not hesitate to get in touch...
        </p>
      </template>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/Form/Button';
import Api from '@/lib/Api';

export default {
  components: {
    Button,
  },

  props: {
    creditReport: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: mapState(['dashboard']),

  data() {
    return {
      display: {
        creditScoreAnimation: false,
      },
      status: null,
      maxScore: 725,
      scorePercentage: 0,
      scoreRating: null,
      scoreRanges: [
        {
          min: 625,
          color: '#5cbf6d',
          caption: 'Excellent',
          title: 'You are very likely to be accepted by one of our lenders!',
        },
        {
          min: 605,
          color: '#5cbf6d',
          caption: 'Good',
          title: 'You are likely to be accepted by one of our lenders!',
        },
        {
          min: 570,
          color: '#d9d755',
          caption: 'Fair',
          title: "Let's find you a deal!",
        },
        {
          min: 0,
          color: '#d9d755',
          caption: 'Poor',
          title: 'See how to improve your score below.',
        },
      ],
    };
  },

  watch: {
    // When we receive a credit report, calculate score and generate text
    creditReport: {
      handler(creditReport) {
        this.scoreRating = this.calculateScoreRating(creditReport.uniscore);
        this.scorePercentage = this.getPercentageOf(creditReport.uniscore, this.maxScore);
        this.status = this.calculateStatus();
        this.$nextTick(() => {
          this.display.creditScoreAnimation = true;
        });
      },
    },
  },

  methods: {
    ...Api,

    /**
     * Apply for a vehicle (very old legacy)
     * @returns {string}
     */
    apply() {
      return (window.location = process.env.VUE_APP_MAIN_SITE_URL + '/apply/short/vehicle-details');
    },

    /**
     * Selects a score range object based on the user's score
     * @param {Number} score The user's score
     * @returns {Object} Score range
     */
    calculateScoreRating(score) {
      let selectedRange = null;

      for (let range of this.scoreRanges) {
        if (score >= range.min) {
          selectedRange = range;
          break;
        }
      }

      // Default to the last score range (should be lowest) if we can't handle the score (because it's too low)
      return selectedRange ?? this.scoreRanges[this.scoreRanges.length - 1];
    },

    /**
     * Determines which text should be displayed
     * @returns {{prime: boolean, aprCard: string, tooltip: string, accepted: boolean, text: string, title: string}|{prime: boolean, accepted: boolean, text: string, title: string}|*}
     */
    calculateStatus() {
      const apr = this.creditReport.apr;

      let statuses = {
        PRIME: {
          accepted: true,
          prime: true,
          title: 'Great news!',
          text: this.showApr()
            ? `Your credit profile looks good and we have a choice of lenders at ${apr}% APR. Add a vehicle to create your personalised deal.`
            : `Your credit profile looks good and we have a choice of lenders for you. Add a vehicle to create your personalised deal.`,
          tooltip: null,
          aprCard:
            'You qualify for one of our lowest rates of ' +
            apr +
            '%.<br /><small>We still need to check affordability and your employment status.</small>',
        },

        NONPRIME: {
          accepted: false,
          prime: false,
          title: 'Great news!',
          text: this.showApr()
            ? `Great news! Your credit profile looks OK and matches a choice of lenders from ${apr}% APR. Add a car to create your personalised deal.`
            : `Great news! Your credit profile looks OK and matches a choice of lenders. Add a car to create your personalised deal.`,
          tooltip:
            'We have assessed your credit profile and based on the info you have provided we have estimated what your APR will be. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. However, each lender has different rules and to match you to the very best lender we need a full application with a bit more detail from you. Once we have this we can confirm your exact APR. Our representative APR for customers with this credit profile is 16.6%, which means that over 51% of people get that rate or better.',
          aprCard:
            'You qualify for a rate of around ' +
            apr +
            "% APR.<br /><small>We'll still need to check affordability and your employment status. Add a car and complete the full application to get your exact APR</small>",
        },

        UNITYONLY: {
          accepted: true,
          prime: false,
          title: 'Great news!',
          text:
            'Your credit profile looks good and we have a choice of lenders at ' +
            apr +
            '% APR. Add a car to create your personalised deal.',
          tooltip:
            'We have assessed your credit profile and based on the info you have provided we have estimated what your APR will be. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. However, each lender has different rules and to match you to the very best lender we need a full application with a bit more detail from you. Once we have this we can confirm your exact APR. Our representative APR for customers with this credit profile is 16.6%, which means that over 51% of people get that rate or better.',
          aprCard:
            'You qualify for a rate of around ' +
            apr +
            "% APR.<br /><small>We'll still need to check affordability and your employment status. Add a car and complete the full application to get your exact APR</small>",
        },

        APROVERRIDE: {
          accepted: true,
          prime: false,
          title: this.showApr()
            ? `Great news, you're accepted in principle at ${apr}% APR`
            : `Great news, you're accepted in principle`,
          text: `Your next step is to add a car and choose the best deal for you. We'll always show you the lowest monthly payment from our panel of lenders, but the APR may vary a little.`,
          tooltip:
            'We have assessed your credit profile and based on the info you have provided, we have updated your dashboard with the very best APR that one of our many lenders has to offer. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. To create your personalised deal, simply add a car!',
          aprCard: `Congratulations! Thanks to your good credit score, your profile suggests that you qualify for our representative APR rate of ${apr}%. Your dashboard has been updated with your exact rate. To create your personalised deal, simply add a car!`,
        },

        NOSCORE: {
          accepted: false,
          prime: false,
          title: 'Referred',
          text: "We weren't able to provide you with a credit score, but we could still give you a fair APR. To find out what it could be, complete your application below.",
        },
      };

      if (apr) {
        statuses.NOSCORE.tooltip =
          'We have assessed your credit profile and based on the info you have provided we have estimated what your APR will be. We assess over 100,000 applications a year, so we have a very good idea of what a lender will offer you. However, each lender has different rules and to match you to the very best lender we need a full application with a bit more detail from you. Once we have this we can confirm your exact APR. Our representative APR for customers with this credit profile is 16.6%, which means that over 51% of people get that rate or better.';
        statuses.NOSCORE.aprCard =
          'You qualify for a rate of around ' +
          apr +
          "% APR.<br /><small>We'll still need to check affordability and your employment status. Add a car and complete the full application to get your exact APR</small>";
      } else {
        statuses.NOSCORE.tooltip = null;
        statuses.NOSCORE.aprCard =
          "We weren't able to provide an indicative APR right now, this may be due to a technical issue which we are currently working on or a lack of information from our but if you complete your application we will review it manually.";
      }

      if (this.$route.query.override && process.env.NODE_ENV !== 'production') {
        const override = this.$route.query.override;
        if (statuses[override]) {
          return statuses[override];
        }
      }

      if (!this.isPrime() && this.isAprOverrideSet()) {
        return statuses.APROVERRIDE;
      }

      if (this.creditReport.uniscore) {
        return statuses[this.creditReport.automation_route];
      }

      return statuses['NOSCORE'];
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-excellent,
.rating-good {
  color: #5cbf6d;
}
.rating-fair {
  color: #d9d755;
}
.rating-poor {
  color: #d9d755;
}
</style>
