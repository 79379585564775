var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"quote-figures"},[_c('div',{staticClass:"form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.current_mileage.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"current-mileage"}},[_vm._v("Current mileage "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"The exact mileage on the vehicle you are buying."}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.current_mileage.value),expression:"form.current_mileage.value"}],staticClass:"bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4",class:{ 'border-red-300': errors.length },attrs:{"type":"number","min":"0","id":"current-mileage","placeholder":"Current mileage"},domProps:{"value":(_vm.form.current_mileage.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.current_mileage, "value", $event.target.value)}}}),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.price.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"price"}},[_vm._v("Cash price "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"The total price of the vehicle you are buying"}})],1),_c('div',{staticClass:"flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4",class:{ 'border-red-300': errors.length }},[_c('i',{staticClass:"fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.price.value),expression:"form.price.value"}],staticClass:"shadow-none border-none",attrs:{"type":"number","min":"2000","id":"price","placeholder":"Price"},domProps:{"value":(_vm.form.price.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.price, "value", $event.target.value)}}})]),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.deposit.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"deposit"}},[_vm._v("Deposit "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"The amount of cash deposit (if any) you are putting towards the purchase"}})],1),_c('div',{staticClass:"flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4",class:{ 'border-red-300': errors.length }},[_c('i',{staticClass:"fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.deposit.value),expression:"form.deposit.value"}],staticClass:"bg-none border-none shadow-none",attrs:{"type":"number","min":"0","id":"deposit","placeholder":"Deposit"},domProps:{"value":(_vm.form.deposit.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.deposit, "value", $event.target.value)}}})]),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.part_exchange.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"part-exchange"}},[_vm._v("Part exchange "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"Use your own vehicle as a deposit"}})],1),_c('div',{staticClass:"flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4",class:{ 'border-red-300': errors.length }},[_c('i',{staticClass:"fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.part_exchange.value),expression:"form.part_exchange.value"}],staticClass:"bg-none border-none shadow-none",attrs:{"type":"number","min":"0","id":"part-exchange","placeholder":"Part exchange"},domProps:{"value":(_vm.form.part_exchange.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.part_exchange, "value", $event.target.value)}}})]),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.outstanding_settlement.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"outstanding-settlement"}},[_vm._v("Existing finance "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"The amount of any finance outstanding/left to pay on your current car (if any)"}})],1),_c('div',{staticClass:"flex items-center bg-white shadow-sm rounded-md border-2 border-['#ddd'] px-4",class:{ 'border-red-300': errors.length }},[_c('i',{staticClass:"fas fa-pound-sign text-gray-400 dealer-text-secondary text-lg mt-1"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.outstanding_settlement.value),expression:"form.outstanding_settlement.value"}],staticClass:"bg-none shadow-none border-none",attrs:{"type":"number","min":"0","id":"outstanding-settlement","placeholder":"Existing finance"},domProps:{"value":(_vm.form.outstanding_settlement.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.outstanding_settlement, "value", $event.target.value)}}})]),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.term.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"term"}},[_vm._v("Term "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"Over how many months would you like to pay for your car. Longer terms have lower payments."}})],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.term.value),expression:"form.term.value"}],staticClass:"form-select",attrs:{"id":"term"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.term, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.form.term.options),function(option,i){return _c('option',{key:i,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{staticClass:"quote-figures__row",attrs:{"rules":_vm.form.estimated_mileage.rules,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"estimated-mileage"}},[_vm._v("Estimated annual mileage "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"How many miles you travel per year. You can change this later if you need to."}})],1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.estimated_mileage.value),expression:"form.estimated_mileage.value"}],staticClass:"form-select",attrs:{"id":"estimated-mileage"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form.estimated_mileage, "value", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.form.estimated_mileage.options),function(option,i){return _c('option',{key:i,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),(errors)?_c('div',{staticClass:"text-red-500"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"field"},[_c('div',{staticClass:"quote-figures__row"},[_c('label',[_vm._v("Finance balance "),_c('ToolTip',{staticClass:"ml-1 -mt-1",attrs:{"text":"How much you would like to borrow based on the figures above"}})],1),_c('div',{staticClass:"value"},[_c('h2',{staticClass:"mb-0 text-flow-blue dealer-text-secondary"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.balance)))])])])]),_c('div',{staticClass:"form-actions flex justify-end"},[_c('button',{staticClass:"dealer-btn-primary",class:{ 'opacity-50': invalid },attrs:{"disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" Next ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }