<template>
  <div @mouseleave="active = false" class="tooltip-box relative block cursor-pointer p-2">
    <svg
      @mouseover="active = true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      class="w-7 h-7 dealer-text-primary ml-auto"
    >
      <path
        d="M32 64V448H416V64H32zM0 32H32 416h32V64 448v32H416 32 0V448 64 32zM136 184c0-30.9 25.1-56 56-56h56c35.5 0 64 29 64 64.3c0 24-13.4 46.2-34.9 57.2L240 268.3V272v16H208V272 258.5v-9.8l8.7-4.5 45.8-23.4c10.7-5.4 17.5-16.6 17.5-28.7c0-17.8-14.4-32.3-32-32.3H192c-13.3 0-24 10.7-24 24v8H136v-8zm64 136h48v48H200V320z"
      />
    </svg>

    <div
      :class="{ 'opacity-100': active, 'opacity-0 pointer-events-none': !active }"
      class="dealer-tooltip text-white text-left px-3 py-3 absolute z-10 opacity-0 leading-5 text-sm w-full"
    >
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    showTip() {
      this.active = !this.active;
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  .dealer-tooltip {
    width: 185px;
    bottom: 100%;
    left: 100%;
    margin-left: -85px;
    transition: opacity 0.5s;
  }
}

.text {
  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 37%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: #242424 transparent transparent transparent;
  }
}
</style>
