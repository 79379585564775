<template>
  <section class="step privacy">
    <h2>Almost done</h2>

    <section class="form">
      <ValidationObserver v-slot="{ invalid }" tag="div">
        <!-- Title field -->
        <ValidationProvider :rules="form.email.rules" v-slot="{ errors }" tag="div">
          <div class="field">
            <FormLabel forInput="field-email">What's your email address?</FormLabel>
            <input
              autocomplete="email"
              type="text"
              v-model="form.email.value"
              id="field-email"
              tabindex="10"
            />
            <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
            <div v-if="validationErrors.email" class="error-message">
              {{ validationErrors.email }}
            </div>
            <div class="mt-3">
              Please ensure your email address is correct as we'll update you with your application
              by email and SMS.
            </div>
          </div>
        </ValidationProvider>

        <ValidationProvider :rules="form.allowSoftSearch.rules" v-slot="{ errors }" tag="div">
          <div
            class="items-center dealer-bg-primary text-flow-white px-6 py-8 mb-6 relative pl-16 mt-8"
          >
            <input
              id="allow-soft-search"
              name="allow-soft-search"
              type="checkbox"
              class="absolute left-8 top-8 mt-1 w-6 h-6 dealer-text-primary focus:ring-indigo-500 border-gray-300 rounded mr-4"
              v-model="form.allowSoftSearch.value"
            />
            <FormLabel for-input="allow-soft-search">
              Soft search my credit profile to find me the best quote. This won't affect your credit
              rating.
            </FormLabel>
          </div>
          <div v-if="errors.length" class="text-red-500">{{ errors[0] }}</div>
        </ValidationProvider>

        <!-- Third parties -->
        <div
          v-if="showThirdPartSharingCheckbox()"
          class="items-center bg-gray-200 px-6 py-8 my-6 relative pl-16"
        >
          <input
            id="sharing"
            name="sharing"
            type="checkbox"
            class="absolute left-8 top-8 w-6 h-6 dealer-text-primary focus:ring-indigo-500 border-gray-300 rounded mr-4"
            v-model="form.allowThirdPartySharing.value"
          />
          <FormLabel for-input="sharing">
            <span v-html="journey.partner.branding.sharing_checkbox_label"></span>
          </FormLabel>
        </div>

        <!-- Promotional offers -->
        <div v-if="showNewsletterSignup()" class="items-center mb-6 relative mt-5 pl-8">
          <input
            id="third-parties"
            name="third-parties"
            type="checkbox"
            class="absolute left-0 top-0 mt-1 w-6 h-6 dealer-text-primary focus:ring-indigo-500 border-gray-300 rounded mr-4"
            v-model="form.allowMarketing.value"
          />
          <FormLabel for-input="third-parties">
            Keep me updated on my progress & the latest updates from
            <span v-if="dealer.name">{{ dealer.name }}</span
            ><span v-else>Magnitude</span>
          </FormLabel>
        </div>

        <!-- Short disclaimer -->
        <div class="mt-5">
          <p>
            By submitting my details, I can confirm I have read and accept the
            <span @click="openTermsAndConditions()" class="underline cursor-pointer"
              >terms and conditions</span
            >
            and the
            <a
              v-if="!dealer.privacy_policy"
              href="/files/fcf-privacy-notice-v1-8.pdf"
              target="_blank"
              class="underline cursor-pointer"
            >
              privacy policy
            </a>
            <span v-else class="underline cursor-pointer" @click="openPrivacyPolicy()"
              >privacy policy</span
            >
          </p>
        </div>

        <div class="form-actions mt-5 flex">
          <button
            class="btn-primary dealer-btn-primary hover:dealer-bg-secondary hover:dealer-text-primary ml-auto"
            @click="submit"
            :disabled="invalid"
            :class="{ 'opacity-50': invalid }"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </section>

    <modal
      name="privacyPolicy"
      styles="overflow-y: scroll;"
      classes="rounded-lg p-8 bg-white dealer-text-secondary border-8 border-white w-3/4 text-white leading-5 modal"
      :adaptive="true"
      height="90%"
      :scrollable="true"
      :clickToClose="true"
    >
      <div class="overscroll-contain relative">
        <div class="h-10 block w-full text-right">
          <span
            class="float-right flex items-center cursor-pointer"
            @click="$modal.hide('privacyPolicy')"
          >
            <span class="text-xl -mt-1 text-black w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#000000">
                <path
                  d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                />
              </svg>
            </span>
            <span class="ml-3 uppercase text-base text-black">Close</span>
          </span>
        </div>
        <h3 class="dealer-text-secondary">Privacy Policy</h3>
        <div
          v-if="dealer.privacy_policy"
          v-html="dealer.privacy_policy"
          class="content-section dealer-text-secondary"
        />
        <div v-else>
          <p>TODO: Privacy policy</p>
        </div>
        <button @click="$modal.hide('privacyPolicy')" class="mt-5">Close</button>
      </div>
    </modal>

    <!-- Terms & conditions modal -->
    <modal
      name="terms"
      styles="overflow-y: scroll;"
      classes="rounded-lg p-8 bg-white dealer-text-secondary border-4 border-0 md:border-8 border-white w-3/4 text-white leading-5 modal"
      :adaptive="true"
      height="90%"
      clickToClose="true"
      :maxWidth="600"
      :scrollable="true"
      :clickToClose="true"
    >
      <div class="overscroll-contain">
        <div class="h-10 block w-full text-right">
          <span class="float-right flex items-center cursor-pointer" @click="$modal.hide('terms')">
            <span class="text-xl -mt-1 text-black w-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#000000">
                <path
                  d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
                />
              </svg>
            </span>
            <span class="ml-3 uppercase text-base text-black">Close</span>
          </span>
        </div>
        <h3 class="dealer-text-secondary">Terms & Conditions</h3>
        <div v-if="dealer.terms_conditions" v-html="dealer.terms_conditions" />
        <div v-else>
          <p>TODO: Terms and conditions text</p>
        </div>
        <button @click="$modal.hide('terms')" class="mt-5">Close</button>
      </div>
    </modal>
  </section>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import Api from '@/lib/Api';
import FormLabel from '@/components/Form/FormLabel.vue';

export default {
  components: {
    FormLabel,
    RadioButtons,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      privacyPolicy: null,
      termsAndConditions: null,
      validationErrors: {
        email: null,
      },
      form: {
        email: {
          rules: 'required|email',
          value: '',
          errors: [],
        },
        allowThirdPartySharing: {
          value: false,
        },
        allowMarketing: {
          value: true,
        },
        allowSoftSearch: {
          value: false,
          rules: {
            required: { allowFalse: false },
          },
        },
      },
    };
  },

  computed: {
    ...mapState(['dealer', 'journey']),
  },

  methods: {
    ...Api,

    /**
     * Stores the applicant data in Vuex, saves a new application in the API and sends a
     * verification code to the phone number provided. If all succeeds, proceed to next step.
     */
    async submit() {
      this.$emit('loading', true, 'Logging you in...');
      this.validationErrors.emails = null;

      // Save applicant to Vuex
      await this.$store.commit('setApplicant', {
        email: this.form.email.value,
      });

      // Update the applicant
      const applicantUpdated = await this.updateApplicant(
        this.journey.applicant.id,
        this.journey.application.reference,
        {
          email: this.form.email.value,
        }
      );

      // Update contact preferences
      const payload = {
        // Soft search permission
        soft_search: this.form.allowSoftSearch.value,

        // Marketing
        phone_offers: this.form.allowMarketing.value,
        messaging_offers: this.form.allowMarketing.value,

        // Third party sharing
        partner_sharing: this.form.allowThirdPartySharing.value,
      };

      const contactPrefsUpdated = await this.apiUpdateContactPreferences(
        this.journey.application.reference,
        payload
      );

      // If everything was updated/saved successfully, proceed
      if (applicantUpdated && contactPrefsUpdated) {
        // Pushes the relevant event/goal data to GA
        if (window.dataLayer) {
          window.dataLayer.push({ event: 'dealerJourneyCompleted' });
        }

        let dealerName = this.dealer.name;

        if (this.dealer.name) {
          // Attach dealer name to push event
          await this.apiPushEvent(
            this.journey.application.reference,
            dealerName + '_step_privacy',
            dealerName + '_feed_journey',
            dealerName + '_step',
            dealerName + '_privacy'
          );
        } else {
          // Standard push event
          await this.apiPushEvent(
            this.journey.application.reference,
            'step_privacy',
            'feed_journey',
            'step',
            'privacy'
          );
        }

        // Check if a TDUID property was saved and add it to the payload
        if (this.journey.partner.tduid) {
          await this.pipedreamUpsertContact({
            email: this.journey.applicant.email,
            tduid: this.journey.partner.tduid,
          });
        }

        // Identify user with Hubspot contact
        this.hubspotPush('identify', { email: this.journey.applicant.email });

        // Get a login token so we can automatically login
        const autoLogin = await this.autoLogin(this.journey.application.reference);

        if (autoLogin === true) {
          // Reset their journey data
          const routeParams = {
            reference: this.journey.application.reference,
          };

          // Check if they're allowed to login
          const validatedApplication = await this.apiValidateApplicationLogin();

          if (!validatedApplication) {
            this.toast('Error verifying your data - please get in touch.');
            return await this.$router.push({ name: 'login' });
          }

          if (!validatedApplication.valid) {
            // Log them out and resume
            return await axios.post(this.getApiBaseUrl() + '/auth/logout').then(async (r) => {
              await this.$store.dispatch('logout');
              await this.$store.dispatch('resetJourney');
              await this.$store.commit('mapApplicationJourney', validatedApplication.application);
              this.$emit('loading', false);
              return await this.$router.push({
                name: 'incomplete',
                params: {
                  routeName: validatedApplication.route_name,
                },
              });
            });
          }

          await this.$store.dispatch('resetJourney');
          this.$emit('loading', false);
          return await this.$router.push({ name: 'application-dashboard', params: routeParams });
        }
      }

      this.$emit('loading', false);
    },

    /**
     * Generates a personal authentication token (PAT) for the user
     * @param {Text} applicationReference
     * @returns {Text} Returns the generated token or null if it fails
     */
    autoLogin(applicationReference) {
      return axios
        .get(this.getApiBaseUrl() + '/auth/token/generate/' + applicationReference)
        .then((r) => {
          if (r.data.success && r.data.data.autoLoginToken.plainTextToken) {
            const token = r.data.data.autoLoginToken.plainTextToken;

            // Login
            return axios
              .post(this.getApiBaseUrl() + '/auth/autologin', { token: token })
              .then(() => {
                return axios
                  .get(this.getApiBaseUrl() + '/me', {
                    withCredentials: true,
                  })
                  .then(async (userResponse) => {
                    this.$store.commit('setAuthed', true);
                    this.$store.commit('setUser', userResponse.data.data.user);
                    return true;
                  })
                  .catch(() => {
                    this.$store.commit('setAuthed', false);
                    this.$store.commit('setUser', null);
                    return null;
                  });
              })
              .catch(() => {
                return null;
              });
          }

          return null;
        })
        .catch((e) => {
          console.error(e);
          return null;
        });
    },

    /**
     * Update applicant data
     * @param applicantId
     * @param applicationReference
     * @param applicantData
     * @returns {Promise<AxiosResponse<any> | boolean>}
     */
    updateApplicant(applicantId, applicationReference, applicantData) {
      return axios
        .patch(
          this.getApiBaseUrl() +
            '/applications/' +
            applicationReference +
            '/applicants/' +
            applicantId,
          {
            applicant: applicantData,
            _method: 'PATCH',
          }
        )
        .then((r) => {
          if (r.data.success) {
            return true;
          }
          throw new Error(r.data.errors ?? r.data);
        })
        .catch((e) => {
          console.error(e);

          if (e.response.data.errors['applicant.email'].length) {
            // Reset the email field in Vuex or else updating applicant data on other steps will fail too (as all appl. data is sent)
            let currentApplicantData = this.journey.applicant;
            // Delete the email
            delete currentApplicantData.email;
            // Overwrite the existing applicant data
            this.$store.commit('overrideApplicant', currentApplicantData);

            this.validationErrors.email = e.response.data.errors['applicant.email'][0];
          }

          return false;
        });
    },

    /**
     * Updates an applicant's contact preferences based on their application reference
     * @param {String} applicationRef Internal application reference
     * @param {Object} payload Object of contact preferences
     */
    async apiUpdateContactPreferences(applicationRef, payload) {
      return axios
        .post(
          this.getApiBaseUrl() + '/applications/' + applicationRef + '/contact_preferences',
          payload
        )
        .then((r) => {
          if (r.data.success) {
            this.$store.commit('setApplicant', { privacy: payload });
            return true;
          }
          throw new Error(r.data.errors);
        });
    },

    /**
     * Restore this step's data from
     */
    restore() {
      const applicant = this.journey.applicant;

      // Email
      if (applicant.email) {
        this.form.email.value = applicant.email;
      }

      // Contact type
      if (applicant.privacy) {
        if (applicant.privacy.phone_updates) {
          this.form.contactType.value = 'phone';
        }

        if (applicant.privacy.messaging_updates) {
          this.form.contactType.value = 'message';
        }

        if (
          !applicant.privacy.messaging_updates &&
          !applicant.privacy.phone_updates &&
          applicant.email
        ) {
          this.form.contactType.value = 'online';
        }
      }
    },

    /**
     * Open privacy policy modal
     */
    openPrivacyPolicy() {
      this.$modal.show('privacyPolicy');
    },

    /**
     * Open terms & conditions modal
     */
    openTermsAndConditions() {
      this.$modal.show('terms');
    },

    showNewsletterSignup() {
      if (this.journey.partner === null || (this.dealer && this.dealer.id)) {
        return true;
      }
      return (
        this.journey.partner.branding && this.journey.partner.branding.enable_newsletter_signup
      );
    },

    showThirdPartSharingCheckbox() {
      return (
        this.journey.partner &&
        this.journey.partner.branding &&
        this.journey.partner.branding.sharing_checkbox_label
      );
    },
  },

  mounted() {
    this.$emit('set-step', 6);

    // Restore values from Vuex if we have them
    this.restore();
  },
};
</script>
