<template>
  <div class="dashboard-banner-outer">
    <div class="dashboard-banner bg-flow-blue dealer-bg-banner text-white py-10 px-6">
      <div class="mx-auto max-w-3/4 md:max-w-7xl lg:max-w-7xl w-full">
        <h1 class="text-white" :class="{ 'mb-0': subTitle === null }">{{ title }}</h1>
        <h2 v-if="subTitle" class="text-white">{{ subTitle }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: null,
    },
  },
};
</script>
