<template>
  <section>
    <ul role="list" class="divide-y divide-gray-200">
      <!-- Description -->
      <li class="py-4">
        <div class="flex items-center">
          <h5 class="mb-0">Description</h5>
        </div>
        <div class="content mt-3 mb-4">
          <p>
            <strong>{{ vehicle.heading }}</strong>
          </p>
          <p>{{ vehicle.seller_comments }}</p>
        </div>
      </li>

      <!--Todo - revised dealer location-->
      <!-- Dealership info -->
      <!--<li class="py-4" v-if="vehicle.dealer.postcode">-->
      <!--    <div class="flex items-center">-->
      <!--        <i class="fad fa-map-marker-alt mr-3 text-lg"></i> <h5 class="mb-0">Location</h5>-->
      <!--    </div>-->
      <!--    <div class="content mt-3 mb-4">-->
      <!--        <address class="mb-2">-->
      <!--            <strong>{{ vehicle.dealer.name }}</strong><br />-->
      <!--            {{ vehicle.dealer.street }}<br />-->
      <!--            {{ vehicle.dealer.city }}<br />-->
      <!--            {{ vehicle.dealer.county }}<br />-->
      <!--            {{ vehicle.dealer.postcode }}-->
      <!--        </address>-->
      <!--        <p>Phone: <strong>{{ vehicle.dealer.phone }}</strong></p>-->
      <!--    </div>-->
      <!--</li>-->
      <!-- Other information -->
      <li v-if="exists(vehicle.options)" class="py-4">
        <div class="flex items-center">
          <h5 class="mb-0">Other information</h5>
        </div>

        <div class="content mt-3 mb-4">
          <ul class="list-disc list-inside grid grid-cols-1 md:grid-cols-2">
            <li v-for="spec in convertToList(vehicle.options)">
              {{ spec }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    exists(property) {
      return typeof property !== undefined && property !== null && property.trim() !== '';
    },

    convertToList(item) {
      return item !== null ? item.split('|') : '';
    },
  },

  props: {
    vehicle: {
      type: [Object, null],
      default: null,
    },
  },

  mounted() {},
};
</script>
