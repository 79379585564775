<template>
  <section class="applications">
    <vue-headful
      description="At Flow car Finance we think car finance should be fair, simple & sincere. With over 30 years of knowledge, expertise and listening to you, we came up with an effortless car, van or motorcycle buying experience."
      url="https://flowcarfinance.co.uk/applications"
    />

    <Banner
      banner_title="Review Personal details"
      banner_subtitle="We would like to confirm a few details before creating a new application, this will not take long."
    />

    <main class="bg-gray-100">
      <div class="max-w-lg mx-auto w-full px-6 lg:px-8 py-12">
        <section class="form">
          <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
            <!-- Title field -->
            <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
              <div class="field">
                <FormLabel forInput="">What is your title?</FormLabel>
                <RadioButtons
                  :max-options="6"
                  :rules="form.title.rules"
                  :options="form.title.options"
                  v-model="form.title.value"
                  cols="2"
                />
              </div>
            </ValidationProvider>

            <!-- First name field -->
            <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
              <div class="field">
                <FormLabel for-input="field-first-name" :tooltip="form.first_name.tooltip">
                  First name
                </FormLabel>
                <input
                  type="text"
                  v-model="form.first_name.value"
                  id="field-first-name"
                  tabindex="20"
                  class="capitalize"
                />
                <span v-if="errors.length" class="text-red-500">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <!-- Surname field -->
            <ValidationProvider rules="required" v-slot="{ errors }" tag="div">
              <div class="field">
                <FormLabel for-input="field-surname"> Surname </FormLabel>
                <input
                  type="text"
                  v-model="form.surname.value"
                  id="field-surname"
                  tabindex="30"
                  class="capitalize"
                />
                <span v-if="errors.length" class="text-red-500">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>

            <!-- Email field -->
            <ValidationProvider :rules="form.email.rules" v-slot="{ errors }" tag="div">
              <div class="field">
                <label for="field-email">What's your email address?</label>
                <input
                  autocomplete="email"
                  type="text"
                  v-model="form.email.value"
                  id="field-email"
                  tabindex="10"
                />
                <div v-if="errors.length" class="text-red-500">{{ errors[0] }}</div>
                <div v-if="validationErrors.email" class="text-red-500">
                  {{ validationErrors.email }}
                </div>
                <div class="text-sm mt-3">
                  Please ensure your email address is correct as we'll update you with your
                  application by email and SMS.
                </div>
              </div>
            </ValidationProvider>

            <p class="bg-gray-300 px-5 text-sm py-4 flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                />
              </svg>
              Please confirm if your personal details are correct
            </p>

            <div v-if="error" class="bg-red-500 text-white px-5 py-4 mb-5">
              {{ error }}
            </div>

            <div class="form-actions flex items-center">
              <div
                class="flex cursor-pointer space-x-2 text-flow-blue hover:text-flow-teal"
                @click="$router.push({ name: 'applications__review' })"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
                <span>Previous</span>
              </div>
              <button
                @click="save"
                :disabled="invalid"
                class="dealer-btn-primary ml-auto hover:dealer-text-primary"
                :class="{ 'opacity-50': invalid }"
              >
                Save
              </button>
            </div>
          </ValidationObserver>
        </section>
      </div>
    </main>
  </section>
</template>

<script>
import Banner from '@/components/Ui/Applications/Banner';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import RadioButtons from '@/components/Form/RadioButtons';
import FormLabel from '@/components/Form/FormLabel';
import Api from '@/lib/Api';
import methods from '@/lib/methods';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';

export default {
  name: 'ReviewPersonalDetails',
  components: {
    Banner,
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    FormLabel,
  },
  data() {
    return {
      error: null,
      validationErrors: {
        email: null,
      },
      form: {
        title: {
          options: [
            {
              label: 'Mr',
              value: 'Mr',
            },
            {
              label: 'Mrs',
              value: 'Mrs',
            },
            {
              label: 'Miss',
              value: 'Miss',
            },
            {
              label: 'Ms',
              value: 'Ms',
            },
            {
              label: 'Dr',
              value: 'Dr',
            },
            {
              label: 'Prof',
              value: 'Prof',
            },
          ],
          value: null,
          rules: 'required',
        },
        first_name: {
          value: '',
          tooltip: {
            title: 'Your do you need your first name?',
            body: 'Your first name as it appears on your driving license',
          },
        },
        surname: {
          value: '',
        },
        email: {
          rules: 'required|email',
          value: '',
          errors: [],
        },
      },
    };
  },

  computed: mapState(['dealer', 'journey', 'dashboard']),

  methods: {
    ...Api,

    restore() {
      const applicant = this.dashboard.applicant;

      // Title
      if (applicant.title) {
        this.form.title.value = applicant.title;
      }

      // First name
      if (applicant.first_name) {
        this.form.first_name.value = applicant.first_name;
      }

      // Surname
      if (applicant.surname) {
        this.form.surname.value = applicant.surname;
      }

      // Email
      if (applicant.email) {
        this.form.email.value = applicant.email;
      }
    },

    /**
     * Stores the applicant data in Vuex, saves a new application in the API and sends a
     * verification code to the phone number provided. If all succeeds, proceed to next step.
     */
    async save() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.$emit('loading', true, 'Saving');
        this.error = null;

        axios
          .patch(
            `${methods.getApiBaseUrl()}/applications/${
              this.dashboard.application.reference
            }/applicants/${this.dashboard.applicant.id}`,
            {
              applicant: {
                id: this.dashboard.applicant.id,
                title: this.form.title.value,
                first_name: this.form.first_name.value,
                surname: this.form.surname.value,
                email: this.form.email.value,
              },
            }
          )
          .then(async (r) => {
            if (r.data.success) {
              this.$emit('loading', false);
              this.$store.commit('setDashboardApplicant', r.data.data.applicant);
              return await this.$router.push({ name: 'applications__review' });
            }
            throw new Error(
              `API failed to update an applicant (${this.dashboard.applicant.id}) on the personal details confirmation step.`
            );
          })
          .catch((e) => {
            Bugsnag.notify(e);
            this.error =
              'There was a problem updating your details. If this problem continues, please get in touch.';
            this.$emit('loading', false);
          });
      }
    },
  },
  async mounted() {
    // Restore values from Vuex if we have them
    this.restore();
  },
};
</script>

<style scoped></style>
