import axios from 'axios';
import methods from '@/lib/methods';

export default class VehicleFeed {
  constructor() {
    this.apiBase = methods.getApiBaseUrl() + '/v1/vehicle-feed';
  }

  /**
   * Returns an array of vehicle makes from the API.
   * @returns
   */
  getMakes() {
    return axios
      .get(`${this.apiBase}/makes.json`)
      .then((r) => this.processResponse(r, 'items'))
      .catch((e) => this.handleError(e));
  }

  getFilters() {
    return axios
      .get(`${this.apiBase}/vehicles/filters.json`)
      .then((r) => this.processResponse(r))
      .catch((e) => this.handleError(e));
  }

  /**
   * Returns an array of models from the API. Optionally, models can be filtered by make.
   * @param {*} make
   * @returns
   */
  getModels(make = null) {
    const payload = {
      params: {
        make: make,
      },
    };

    return axios
      .get(`${this.apiBase}/models.json`, payload)
      .then((r) => this.processResponse(r, 'items'))
      .catch((e) => this.handleError(e));
  }

  /**
   * Returns an array of vehicle body types from the API.
   * @returns
   */
  getBodyTypes() {
    return axios
      .get(`${this.apiBase}/body-types.json`)
      .then((r) => this.processResponse(r, 'items'))
      .catch((e) => this.handleError(e));
  }

  /**
   * Searches for a paginated collection of vehicles by using the optional filters listed below.
   * @param {String} make
   * @param {String} model
   * @param {Number} min_price
   * @param {Number} max_price
   * @param {Number} page The current page number
   * @returns
   */
  searchVehicles(page = 1, params = {}) {
    const payload = {
      params: {
        ...params,
        page: page,
      },
    };

    return axios
      .get(`${this.apiBase}/vehicles/search.json`, payload)
      .then((r) => this.processResponse(r))
      .catch((e) => this.handleError(e));
  }

  /**
   * Returns a vehicle and dealership data
   * @param {Number} vehicleId
   * @returns
   */
  getVehicle(vehicleId) {
    return axios
      .get(`${this.apiBase}/vehicle/${vehicleId}.json`)
      .then((r) => this.processResponse(r))
      .catch((e) => this.handleError(e));
  }

  /**
   * Returns response data if it was successful. A child object key can optionally be provided
   * to drill down further into the response's object.
   * @param {Object} response Axios response object.
   * @param {String|null} childObjectKey Child object key name to return from the response object.
   * @returns
   */
  processResponse(response, childObjectKey = null) {
    if (response.data && response.data.success === true && response.data.data) {
      // Do they want to drill down further into the response?
      if (childObjectKey !== null && typeof response.data[childObjectKey] !== undefined) {
        if (typeof response.data[childObjectKey] !== undefined) {
          return response.data.data[childObjectKey];
        }

        throw new Error(
          `Error processing response: cannot find child object with key "${objectKeyToReturn}".`
        );
      }

      return response.data.data;
    }

    throw new Error(response.data.message ?? 'Unknown error - no message provided.');
  }

  /**
   * Handles all errors caught be Axios.
   * @param {Error} e
   * @returns
   */
  handleError(e) {
    // TODO: Bugsnag
    console.error(e);
    return null;
  }
}
