<template>
  <section class="step your-vehicle flex-1">
    <h2>Your vehicle</h2>

    <section class="form mt-8">
      <ValidationObserver v-slot="{ invalid }" tag="div">
        <!-- Type of vehicle -->
        <div class="field">
          <FormLabel>What type of vehicle are you buying?</FormLabel>
          <RadioButtons
            :max-options="5"
            :rules="form.vehicle_type.rules"
            :options="form.vehicle_type.options"
            v-model="form.vehicle_type.value"
            cols="2"
          />
        </div>

        <!-- Budget -->
        <ValidationProvider tag="div" :rules="form.budget.rules" v-slot="{ errors }" name="Budget">
          <FormLabel forInput="budget">What is your monthly budget?</FormLabel>
          <TextInputIcon
            id="budget"
            icon="fas fa-pound-sign"
            name="budget"
            v-model="form.budget.value"
            :is-number="true"
            @change="form.budget.dirty = true"
          />
          <div v-if="errors.length" class="error-message">{{ errors[0] }}</div>
        </ValidationProvider>

        <!-- Have you found a vehicle yet? -->
        <div class="field">
          <FormLabel>Have you found a {{ vehicleType }} yet?</FormLabel>
          <RadioButtons
            :max-options="2"
            :rules="form.vehicle_found.rules"
            :options="form.vehicle_found.options"
            v-model="form.vehicle_found.value"
            cols="2"
          />
        </div>

        <!-- Have you found a vehicle yet? -->
        <transition name="fade">
          <ValidationProvider tag="div" :rules="form.vrm.rules" v-slot="{ errors }" name="VRM">
            <div v-if="form.vehicle_found.value === true" class="field vrm">
              <FormLabel>What is the vehicle's reg?</FormLabel>
              <TextInput id="vrm" name="vrm" v-model="form.vrm.value" />
              <div v-if="errors.length" class="text-red-500">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </transition>

        <!-- Navigation -->
        <div class="form-actions flex">
          <button
            class="dealer-btn-primary ml-auto"
            @click="submit"
            :disabled="invalid"
            :class="{ 'opacity-50': invalid }"
          >
            Next
          </button>
        </div>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
import FormLabel from '@/components/Form/FormLabel';
import RadioButtons from '@/components/Form/RadioButtons';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TextInputIcon from '@/components/Form/TextInputIcon';
import Bugsnag from '@bugsnag/js';
import { mapState } from 'vuex/dist/vuex.esm.browser';
import TextInput from '@/components/Form/TextInput';

export default {
  components: {
    TextInput,
    FormLabel,
    RadioButtons,
    ValidationObserver,
    ValidationProvider,
    TextInputIcon,
  },

  computed: {
    ...mapState(['journey']),
    vehicleType() {
      let type = this.form.vehicle_type.value;
      return type !== null && type !== 'other' ? type : 'vehicle';
    },
  },

  data() {
    return {
      form: {
        vehicle_type: {
          options: [
            { label: 'Car', value: 'car' },
            { label: 'Van', value: 'van' },
            { label: 'Pickup', value: 'pickup' },
            { label: 'Bike', value: 'bike' },
            { label: 'Other', value: 'other' },
          ],
          value: null,
          rules: 'required',
        },
        budget: {
          value: null,
          rules: 'required|numeric',
          dirty: false,
        },
        vehicle_found: {
          options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
          value: null,
          rules: 'required',
        },
        vrm: {
          value: null,
          rules: 'max:10',
        },
      },
      error: null,
    };
  },

  methods: {
    /**
     * Submit the data to the API.
     * @returns {Promise<Route>}
     */
    async submit() {
      this.$emit('loading', true, 'Saving...');

      // Save the data
      try {
        const payload = {
          vehicle_type: this.form.vehicle_type.value,
          budget: this.form.budget.value,
          found_vehicle: this.form.vehicle_found.value,
          vrm: this.form.vrm.value,
        };
        await axios.post(
          this.getApiBaseUrl() +
            '/applications/' +
            this.journey.application.reference +
            '/application-question',
          payload
        );

        // Save to store
        this.$store.commit('setApplicationQuestion', payload);
      } catch (e) {
        Bugsnag.notify(
          'Failed to create application questions for ' + this.journey.application.reference
        );
      }

      // Done, proceed to next step
      this.$emit('loading', false);
      return await this.$router.push({ name: 'privacy' });
    },

    /**
     * Populates the form based on what's saved locally
     */
    populateFields() {
      const questions = this.journey.applicationQuestion;
      if (questions?.vehicle_type) {
        this.form.vehicle_type.value = questions.vehicle_type;
      }

      if (questions?.budget) {
        this.form.budget.value = questions.budget;
      }

      if (questions?.found_vehicle) {
        this.form.vehicle_found.value = questions.found_vehicle;
      } else if (questions?.found_vehicle === false) {
        this.form.vehicle_found.value = false;
      }

      if (questions?.vrm) {
        this.form.vrm.value = questions.vrm;
      }
    },
  },

  mounted() {
    this.populateFields();
  },
};
</script>

<style>
#vrm {
  text-transform: uppercase;
}
</style>
