<template>
  <!-- Recently viewed (on public site) -->
  <div v-if="recentVehicle !== null">
    <h3 class="mb-2 mt-12 text-flow-blue dealer-text-secondary">Recently viewed</h3>
    <div class="previous-quotes mt-5">
      <a href="javascript:void(0)" title="View vehicle" @click="viewRecentlyViewedVehicle()">
        <div class="vehicle bg-white rounded-md p-6 hover:shadow-lg transition-shadow ease-in-out">
          <div class="grid grid-cols-1 xs:grid-cols-2">
            <div>
              <div class="font-bold text-2xl text-flow-blue-dark">
                {{ recentVehicle.make }} {{ recentVehicle.model }} {{ recentVehicle.year }}
              </div>
              <div class="text-flow-blue">{{ recentVehicle.variant }}</div>
            </div>
            <div class="flex justify-end">
              <button class="w-full mt-3 xs:w-auto xs:mt-0 btn-primary self-center bg-flow-blue">
                View
              </button>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      recentVehicle: null,
    };
  },

  computed: mapState(['dashboard']),

  methods: {
    async viewRecentlyViewedVehicle() {
      if (this.recentVehicle?.quick_code) {
        this.$store.commit('setRecentlyViewedPublicVehicle', null);
        return await this.$router.push({
          name: 'dashboard__search__view',
          params: { vehicleId: this.recentVehicle.quick_code },
        });
      }
    },
  },

  mounted() {
    // Get their recently viewed vehicle if applicable
    this.recentVehicle =
      this.$store.state.web.vehicles.recentlyViewed !== null
        ? this.$store.state.web.vehicles.recentlyViewed
        : null;
  },
};
</script>
