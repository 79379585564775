<template>
  <div class="page" />
</template>

<script>
import Api from '@/lib/Api';
import Bugsnag from '@bugsnag/js';

export default {
  methods: {
    ...Api,

    /**
     * Handles automatic login errors (disables loading screen and redirects to login page).
     * @param e
     * @returns {Promise<Route>}
     */
    error(e = null) {
      if (e !== null) {
        Bugsnag.notify(e);
      }

      this.$emit('loading', false);
      return this.$router.push({ name: 'login' });
    },
  },

  async mounted() {
    // Start loading and get the user's ID and login bypass token
    this.$emit('loading', true, 'One moment...');
    const token = this.$route.query.token;
    const user = this.$route.query.user;
    const wasReferred = this.$route.query.dealer ?? 'no';

    // If they weren't referred, reset the dealer and partner
    if (wasReferred !== 'yes') {
      await this.$store.dispatch('resetPartner');
      await this.$store.dispatch('resetDealer');
    }

    // No token or user ID provided
    if (
      token === null ||
      typeof token === 'undefined' ||
      user === null ||
      typeof user === 'undefined'
    ) {
      return this.error('User tried to bypass login without a token or user ID');
    }

    // Validate the token against the user
    const valid = await this.apiValidateLoginToken(token, user);

    // Invalid or failed
    if (valid === false) {
      return this.error();
    }

    // Success, log them in.
    // 'valid' var should contain an object with a 'user' and 'application' property
    this.$store.commit('setAuthed', true);
    this.$store.commit('setUser', valid.user);

    // Stop loading and redirect to dashboard
    this.$emit('loading', false);
    return this.$router.push({ name: 'applications__manage' });
  },
};
</script>
