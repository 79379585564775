<template>
  <div class="">
    <!-- Filter button -->
    <!-- <button type="button" @click="openFilter()" class="flex w-full justify-center items-center dealer-bg-secondary dealer-text-primary rounded-md py-3 text-lg uppercase"> -->
    <!-- <button type="button" @click="$router.push({ name: 'dashboard__search__details' })" class="flex w-full justify-center items-center dealer-bg-secondary dealer-text-primary rounded-md py-3 text-lg uppercase">
            <i class="fas fa-sliders-h mr-3"></i>
           <span> Filter</span>
        </button> -->

    <!-- Sort dropdown -->
    <div class="flex justify-center items-center ml-auto">
      <label for="location" class="w-1/2 block text-sm font-medium text-gray-700 text-right mr-4"
        >Sort by</label
      >
      <select
        v-model="form.sort.value"
        id="location"
        name="location"
        class="w-full mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-md rounded-md"
      >
        <option :value="{ column: 'price', order: 'asc' }">Price (Lowest)</option>
        <option :value="{ column: 'price', order: 'desc' }">Price (Highest)</option>
        <option :value="{ column: 'miles', order: 'asc' }">Mileage</option>
        <option :value="{ column: 'year', order: 'desc' }">Age (Newest first)</option>
        <option :value="{ column: 'year', order: 'asc' }">Age (Oldest first)</option>
      </select>
    </div>

    <!-- <button type="button" @click="toggleSortMenu" class="flex w-full justify-center items-center bg-white dealer-text-primary rounded-md py-2 text-sm uppercase relative">
            <i class="fas fa-sort mr-3"></i>
            <span>Sort</span>

            <transition name="fade">
                <div v-show="sortMenuOpen" class="absolute left-0 top-12 w-full">
                    <div class="pt-1 rounded-b-md dealer-bg-secondary text-white shadow-xs font-bold relative z-50 text-left" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <div id="traingle"></div>
                        <p @click="$emit('sort', 'price', 'asc')" class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Price (Lowest)</p>
                        <p @click="$emit('sort', 'price', 'desc')" class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Price (Highest)</p>
                        <p @click="$emit('sort', 'miles', 'asc')" class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Mileage</p>
                        <p @click="$emit('sort', 'year', 'desc')" class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Age (Newest first)</p>
                        <p @click="$emit('sort', 'year', 'asc')" class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Age (Oldest first)</p>
                         <p class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Most recent</p> -->
    <!-- <p class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Relevance</p> -->
    <!-- <p class="dealer-text-primary px-4 py-2 text-sm leading-none font-bold">Distance</p>

                    </div>
                </div>
            </transition>
        </button> -->

    <transition name="fade">
      <div
        v-show="showFilter"
        class="top-0 left-0 z-auto p-8 text-white leading-5 fixed w-full h-full dealer-bg-primary"
      >
        <div class="h-10 block w-full text-right">
          <span class="float-right flex items-center cursor-pointer" @click="closeFilter()">
            <i class="fas fa-times fa-xs"></i> <span class="ml-3 uppercase text-base">Close</span>
          </span>
        </div>

        <div>Filter settings here</div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showFilter: false,
      sortMenuOpen: false,
      form: {
        sort: {
          value: { column: 'price', order: 'asc' },
        },
      },
    };
  },

  watch: {
    'form.sort.value': function (newVal, oldVal) {
      this.sortBy(newVal);
    },
  },

  methods: {
    sortBy(properties) {
      this.$emit('sort', properties.column, properties.order);
    },

    toggleSortMenu() {
      this.sortMenuOpen = !this.sortMenuOpen;
    },

    hideSortMenu() {
      if (!this.sortMenuOpen) {
        return;
      }
      this.sortMenuOpen = false;
    },

    openFilter() {
      this.showFilter = true;
    },

    closeFilter() {
      this.showFilter = false;
    },
  },

  mounted() {
    this.sortBy(this.form.sort.value);
  },
};
</script>
